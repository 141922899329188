const initialState = {

    doctorFeedback: [
        {
            gaitR: "0",
            gaitL: "0",
            anatomicR: 0,
            anatomicL: 0,
            anat: 0,
            anatt: 0,
            angleLr: 0,
            angleLrr: 0,
            langR: 0,
            langL: 0,
            fixeddR: 0,
            fixeddL:0,
            LfixeddR:0,
            LfixedlL:0,
            lagsR: 0,
            lagR: 0,
            lagLR: 0,
            lagLL: 0,
            anteriorR: "0",
            anteriorL: "0",
            anterdropR: "0",
            anterdropL: "0",
            lachmanR: "0",
            lachmanL: "0",
            lachmandropR: "0",
            lachmandropL: "0",
            pivotR: "0",
            pivotL: "0",
            pivotdropR: "0",
            pivotdropL: "0",
            sagR: "0",
            sagL: "0",
            drawerR: "0",
            drawerL: "0",
            drawdropR: "0",
            drawdropL: "0",
            activeR: "0",
            activeL: "0",
            reverseR: "0",
            reverseL: "0",
            recurvatumR: 0,
            recurvatumL: 0,
            postR: "0",
            postL: "0",
            rotatR: "0",
            rotatL: "0",
            drawersR: "0",
            drawersL: "0",
            stressR: "0",
            stressL: "0",
            stressdropR: "0",
            stressdropL: "0",
            stressflR: "0",
            stressflL: "0",
            stressdropflR: "0",
            stressdropflL: "0",
            valgusR: "0",
            valgusL: "0",
            valgusdropR: "0",
            valgusdropL: "0",
            valgusflR: "0",
            valgusflL: "0",
            valgusdropflR: "0",
            valgusdropflL: "0",
            murraryR: "0",
            murraryL: "",
            egeR: "0",
            egeL: "0",
            jointR: "0",
            tendernessR: "0",
            tendernessL: "0",
            jointL: "0",
            apleyR: "0",
            apleyL: "0",
            apprR: "0",
            apprL: "0",
            patellarR: "0",
            patellarL: "0",
            patellaR: "0",
            patellaL: "0",
            signR: "0",
            signL: "0",
            legR: "0",
            legL: "0",
            strengthR: 0,
            strengthL: 0,
            KTR: "0",
            KTL: "0",
            dialR: "0",
            dialL: "0",
            text1: "",
            text2: "",
            text3: "",
            textL1: "",
            textL2: "",
            textL3: "",
            text30R: "0",
            text90R: "0",
            text30L: "0",
            text90L: "0",
            gaitLText: "",
            gaitRText: "",
            radiovalueR: "",
            radiovalueL: "",
            radioAngleR: "",
            radioAngleL: "",
            DegreeFixedR: "",
            DegreeFixedL: "",
            ExtensionR: "",
            ExtensionL: "",
            lFlexionR: 0,
            lFlexionL: 0,
            rFlexionR: 0,
            rFlexionL: 0,
            lExtensionL: 0,
            lExtensionR: 0,
            rExtensionL: 0,
            rExtensionR: 0,
            murraryRdrop: "1",
            murraryLdrop: "1",
            egeRdrop: "1",
            egeLdrop: "1",
            apleyRdrop: "1",
            apleyLdrop: "1",
            apprRdrop: "1",
            apprLdrop: "1",
            ThessalyRdrop: "1",
            ThessalyLdrop: "1",
            ThessalyR: "0",
            ThessalyL: "0",
            GaitRadioR: "",
            GaitRadioL: "",
            DeformityRText: "",
            DeformityLText: "",
        },
    ],
}

const KneeFeedbackReduce = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_KNEE_FEEDBACK_FIELD_DATA':
            const satf = action.data.value;
            const satK = action.data.key;
            const satNewarr = state.doctorFeedback.map((object) => {
                return { ...object, [satK]: satf };
            });
            return { ...state, doctorFeedback: satNewarr };

        default:
            return state;
    }
}

export default KneeFeedbackReduce;