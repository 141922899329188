export default{
  "patient_shoulder_pain":"痛み",
  "patient_shoulder_pain_q1":"肩に痛みはありますか？",
  "patient_shoulder_pain_q1_a1":"いいえ",
  "patient_shoulder_pain_q1_a2":"はい",
  "patient_shoulder_pain_q2":"過去24時間以内に、普段の活動中に肩に感じた痛みの最高レベルは何ですか？(0は痛みなし、15は耐えられない痛みを意味します。）",
  "patient_shoulder_pain_q2_a1":"耐え難い痛み",
  "patient_shoulder_pain_q2_a2":"痛みなし",
  "patient_shoulder_pain_q3":"肩のために睡眠が妨げられていますか？",
  "patient_shoulder_pain_q3_a1":"眠に支障はない",
  "patient_shoulder_pain_q3_a2":"時々障害あり",
  "patient_shoulder_pain_q3_a3":"毎晩の障害",
  "patient_shoulder_pain_q4":"今日の痛みはどれほど深刻ですか？",
  "patient_shoulder_pain_q5":"現在、喫煙者ですか？",
  "patient_shoulder_pain_q5_a1":"いいえ",
  "patient_shoulder_pain_q5_a2":"はい",
  "patient_shoulder_pain_q5_a3":"元喫煙者",
  "patient_shoulder_pain_q5_a2_q1":"1日に何本吸いますか？",
  "patient_shoulder_pain_q5_a3_q1":"どのくらい前に辞めたのですか？ (何年前ですか？)",
  "patient_shoulder_pain_q5_a3_q2":"何年間、タバコを吸いましたか？",
  "patient_shoulder_pain_q6":"鎮痛剤を服用していますか（例：パラセタモールなど）？",
  "patient_shoulder_pain_q6_a1":"いいえ",
  "patient_shoulder_pain_q6_a2":"はい",
  "patient_shoulder_pain_q6_a2_q1":"強い鎮痛剤（コデイン、トラマドール、モルヒネなど）を服用していますか？",
  "patient_shoulder_pain_q6_a2_q1_a1":"いいえ",
  "patient_shoulder_pain_q6_a2_q1_a2":"はい",
  "patient_shoulder_pain_q6_a2_q2":"1日平均何錠の薬を飲んでいますか？",
  "patient_shoulder_pain_q7":"次のうち、あなたの痛みを最もよく表しているのはどれですか？",
  "patient_shoulder_pain_q7_a1":"なし",
  "patient_shoulder_pain_q7_a2":"時折、わずか",
  "patient_shoulder_pain_q7_a3":"極度にな活動または特定の活動に痛みがあり、アスピリンが時々使用された。",
  "patient_shoulder_pain_q7_a4":"軽い活動中に痛みがあり、休息中にはほとんどまたは全くない、アスピリンをよく使う。",
  "patient_shoulder_pain_q7_a5":"痛みは常に存在し、耐えることができ、時には強力な薬が必要",
  "patient_shoulder_pain_q7_a6":"痛みは常に存在し、耐えられず、しばしば強力な薬が必要",
  "patient_shoulder_pain_q8":"腕にピンや針、またはチクチクするような感覚がありますか？",
  "patient_shoulder_pain_q8_a1":"いいえ",
  "patient_shoulder_pain_q8_a2":"はい",
  "patient_shoulder_pain_q9":"痛みのある場所を表示してください",
  "patient_shoulder_pain_q9_a1":"前面",
  "patient_shoulder_pain_q9_a2":"後方",
  "patient_shoulder_function":"機能",
  "patient_shoulder_function_q1":"次のうち、患側の肩の機能を最もよく表しているのはどれですか？",
  "patient_shoulder_function_q1_a1":"普通",
  "patient_shoulder_function_q1_a2":"肩の高さで作業することができますが、いくつかの制限があります。",
  "patient_shoulder_function_q1_a3":"ほとんどの家事、買い物、運転、ヘアケア、服の着脱が可能",
  "patient_shoulder_function_q1_a4":"軽い家事とほとんどの日常的な生活活動が可能",
  "patient_shoulder_function_q1_a5":"軽い活動のみ可能",
  "patient_shoulder_function_q1_a6":"手足を使用できない",
  "patient_shoulder_function_instruction":"次のアクティビティを実行する機能を示す番号を選択します。",
  "patient_shoulder_function_instruction_0":"不可能",
  "patient_shoulder_function_instruction_1":"極度に難しい",
  "patient_shoulder_function_instruction_2":"やや難しい",
  "patient_shoulder_function_instruction_3":"難しくない",
  "patient_shoulder_function_note":"注：肩の機能に関連する次の質問に答えてください。",
  "patient_shoulder_function_q2":"コート・シャツを着る",
  "patient_shoulder_function_q2_a0":"不可能",
  "patient_shoulder_function_q2_a1":"極度に難しい",
  "patient_shoulder_function_q2_a2":"やや難しい",
  "patient_shoulder_function_q2_a3":"難しくない",
  "patient_shoulder_function_q3":"痛む側や影響を受けた側で寝る",
  "patient_shoulder_function_q3_a0":"不可能",
  "patient_shoulder_function_q3_a1":"極度に難しい",
  "patient_shoulder_function_q3_a2":"やや難しい",
  "patient_shoulder_function_q3_a3":"難しくない",
  "patient_shoulder_function_q4":"背中を洗う",
  "patient_shoulder_function_q4_a0":"不可能",
  "patient_shoulder_function_q4_a1":"極度に難しい",
  "patient_shoulder_function_q4_a2":"やや難しい",
  "patient_shoulder_function_q4_a3":"難しくない",
  "patient_shoulder_function_q5":"トイレでお尻を拭く",
  "patient_shoulder_function_q5_a0":"不可能",
  "patient_shoulder_function_q5_a1":"極度に難しい",
  "patient_shoulder_function_q5_a2":"やや難しい",
  "patient_shoulder_function_q5_a3":"難しくない",
  "patient_shoulder_function_q6":"髪の毛をとかす",
  "patient_shoulder_function_q6_a0":"不可能",
  "patient_shoulder_function_q6_a1":"極度に難しい",
  "patient_shoulder_function_q6_a2":"やや難しい",
  "patient_shoulder_function_q6_a3":"難しくない",
  "patient_shoulder_function_q7":"髭剃り・化粧をする",
  "patient_shoulder_function_q7_a0":"不可能",
  "patient_shoulder_function_q7_a1":"極度に難しい",
  "patient_shoulder_function_q7_a2":"やや難しい",
  "patient_shoulder_function_q7_a3":"難しくない",
  "patient_shoulder_function_q8":"歯磨き",
  "patient_shoulder_function_q8_a0":"不可能",
  "patient_shoulder_function_q8_a1":"極度に難しい",
  "patient_shoulder_function_q8_a2":"やや難しい",
  "patient_shoulder_function_q8_a3":"難しくない",
  "patient_shoulder_function_q9":"満タンのボトルでコップを満たす",
  "patient_shoulder_function_q9_a0":"不可能",
  "patient_shoulder_function_q9_a1":"極度に難しい",
  "patient_shoulder_function_q9_a2":"やや難しい",
  "patient_shoulder_function_q9_a3":"難しくない",
  "patient_shoulder_function_q10":"飲む（満杯のコップを口に持ってくる）",
  "patient_shoulder_function_q10_a0":"不可能",
  "patient_shoulder_function_q10_a1":"極度に難しい",
  "patient_shoulder_function_q10_a2":"やや難しい",
  "patient_shoulder_function_q10_a3":"難しくない",
  "patient_shoulder_function_q11":"スープを飲む（スプーン一杯のスープを口に持ってくる）",
  "patient_shoulder_function_q11_a0":"不可能",
  "patient_shoulder_function_q11_a1":"極度に難しい",
  "patient_shoulder_function_q11_a2":"やや難しい",
  "patient_shoulder_function_q11_a3":"難しくない",
  "patient_shoulder_function_q12":"握手する／ドアを開ける",
  "patient_shoulder_function_q12_a0":"不可能",
  "patient_shoulder_function_q12_a1":"極度に難しい",
  "patient_shoulder_function_q12_a2":"やや難しい",
  "patient_shoulder_function_q12_a3":"難しくない",
  "patient_shoulder_function_q13":"電話（耳の高さで）の使用",
  "patient_shoulder_function_q13_a0":"不可能",
  "patient_shoulder_function_q13_a1":"極度に難しい",
  "patient_shoulder_function_q13_a2":"やや難しい",
  "patient_shoulder_function_q13_a3":"難しくない",
  "patient_shoulder_function_q14":"手紙を書く / 紙にサインをする / キーボードを使用する",
  "patient_shoulder_function_q14_a0":"不可能",
  "patient_shoulder_function_q14_a1":"極度に難しい",
  "patient_shoulder_function_q14_a2":"やや難しい",
  "patient_shoulder_function_q14_a3":"難しくない",
  "patient_shoulder_function_q15":"高い棚(肩の上)に手が届く",
  "patient_shoulder_function_q15_a0":"不可能",
  "patient_shoulder_function_q15_a1":"極度に難しい",
  "patient_shoulder_function_q15_a2":"やや難しい",
  "patient_shoulder_function_q15_a3":"難しくない",
  "patient_shoulder_function_q16":"肩の上に0.5kg（1ポンド）を持ち上げる",
  "patient_shoulder_function_q16_a0":"不可能",
  "patient_shoulder_function_q16_a1":"極度に難しい",
  "patient_shoulder_function_q16_a2":"やや難しい",
  "patient_shoulder_function_q16_a3":"難しくない",
  "patient_shoulder_function_q17":"肩の上に4.5kg（10ポンド）を持ち上げる",
  "patient_shoulder_function_q17_a0":"不可能",
  "patient_shoulder_function_q17_a1":"極度に難しい",
  "patient_shoulder_function_q17_a2":"やや難しい",
  "patient_shoulder_function_q17_a3":"難しくない",
  "patient_shoulder_function_q18":"患部の肩で10kg（22ポンド）を運ぶ",
  "patient_shoulder_function_q18_a0":"不可能",
  "patient_shoulder_function_q18_a1":"極度に難しい",
  "patient_shoulder_function_q18_a2":"やや難しい",
  "patient_shoulder_function_q18_a3":"難しくない",
  "patient_shoulder_function_q19":"肩より下の動作でボールを投げる（少なくとも3メートル）",
  "patient_shoulder_function_q19_a0":"不可能",
  "patient_shoulder_function_q19_a1":"極度に難しい",
  "patient_shoulder_function_q19_a2":"やや難しい",
  "patient_shoulder_function_q19_a3":"難しくない",
  "patient_shoulder_function_q20":"肩より上の動作でボールを投げる（少なくとも6メートル）",
  "patient_shoulder_function_q20_a0":"不可能",
  "patient_shoulder_function_q20_a1":"極度に難しい",
  "patient_shoulder_function_q20_a2":"やや難しい",
  "patient_shoulder_function_q20_a3":"難しくない",
  "patient_shoulder_function_q21":"通常のように完全な仕事を行う",
  "patient_shoulder_function_q21_a0":"不可能",
  "patient_shoulder_function_q21_a1":"極度に難しい",
  "patient_shoulder_function_q21_a2":"やや難しい",
  "patient_shoulder_function_q21_a3":"難しくない",
  "patient_shoulder_function_q22":"肩はどのくらいまで通常の仕事ができますか？",
  "patient_shoulder_function_q22_a0":"なし",
  "patient_shoulder_function_q22_a1":"全部",
  "patient_shoulder_function_q23":"いつものように趣味やスポーツを全て行う",
  "patient_shoulder_function_q23_a0":"不可能",
  "patient_shoulder_function_q23_a1":"極度に難しい",
  "patient_shoulder_function_q23_a2":"やや難しい",
  "patient_shoulder_function_q23_a3":"難しくない",
  "patient_shoulder_function_q24":"肩はどのぐらいまで通常のレクリエーション活動ができますか？",
  "patient_shoulder_function_q24_a0":"なし",
  "patient_shoulder_function_q24_a1":"全部",
  "patient_shoulder_function_q25":"作業活動をリストしてください。",
  "patient_shoulder_function_q26":"趣味 /スポーツ活動をリストしてください。",
  "patient_shoulder_feeling_instability":"不安定感",
  "patient_shoulder_feeling_instability_q1":"肩が脱臼するような不安定な感じがしますか？",
  "patient_shoulder_feeling_instability_q1_a1":"いいえ",
  "patient_shoulder_feeling_instability_q1_a2":"はい",
  "patient_shoulder_feeling_instability_q1_a2_q1":"肩がどれほど不安定であるかを示してください",
  "patient_shoulder_wosi":"西オンタリオ肩不安定性指数",
  "patient_shoulder_wosi_instruction":"指示：アンケートのこの部分では、問題のある肩に関連して過去1週間に経験した症状の程度を示すよう求められます。スライダーを動かして、症状と正確に一致するようにしてください。",
  "patient_shoulder_wosi_note":"スライダーを右に動かすほど、その症状をより多く経験していることを示します。スライダーを左に移動するほど、その症状をあまり経験していないことを示します。 特定の質問の意図についてご不明な点があれば、お気軽にご質問ください。",
  "patient_shoulder_wosi_sectionA":"セクションA：身体的症状",
  "patient_shoulder_wosi_q1":"頭の上で行う活動中に肩の痛みをどの程度感じていますか？",
  "patient_shoulder_wosi_q1_a1":"痛みなし",
  "patient_shoulder_wosi_q1_a2":"極度の痛み",
  "patient_shoulder_wosi_q2":"肩の痛みやズキズキ感はどの程度ですか？",
  "patient_shoulder_wosi_q2_a1":"傷つけない /ズキズキしない",
  "patient_shoulder_wosi_q2_a2":"極度の痛み /ズキズキ",
  "patient_shoulder_wosi_q3":"肩の弱さや力不足をどの程度感じていますか？",
  "patient_shoulder_wosi_q3_a1":"弱さなし",
  "patient_shoulder_wosi_q3_a2":"極度の弱さ",
  "patient_shoulder_wosi_q4":"肩の疲労感や力不足をどの程度感じていますか？",
  "patient_shoulder_wosi_q4_a1":"疲労なし",
  "patient_shoulder_wosi_q4_a2":"極度の疲労",
  "patient_shoulder_wosi_q5":"肩に何回のカチッ、プチッ、パチンと音を立てますか？",
  "patient_shoulder_wosi_q5_a1":"ない",
  "patient_shoulder_wosi_q5_a2":"極度に深刻",
  "patient_shoulder_wosi_q6":"肩こりをどの程度感じていますか？",
  "patient_shoulder_wosi_q6_a1":"肩こりはない",
  "patient_shoulder_wosi_q6_a2":"極度の肩こり",
  "patient_shoulder_wosi_q7":"肩のために首の筋肉にどれほど不快感を感じますか？",
  "patient_shoulder_wosi_q7_a1":"不快感はない",
  "patient_shoulder_wosi_q7_a2":"極度の不快感",
  "patient_shoulder_wosi_q8":"肩でどれだけの不安定性やゆるみを経験していますか？",
  "patient_shoulder_wosi_q8_a1":"不安定性はない",
  "patient_shoulder_wosi_q8_a2":"極度の不安定性",
  "patient_shoulder_wosi_q9":"他の筋肉で肩をいくら補償的な動作をしますか？",
  "patient_shoulder_wosi_q9_a1":"全くない",
  "patient_shoulder_wosi_q9_a2":"極度に",
  "patient_shoulder_wosi_q10":"肩の動きの範囲はどの程度制限されていますか？",
  "patient_shoulder_wosi_q10_a1":"制限なし",
  "patient_shoulder_wosi_q10_a2":"極度の制限",
  "patient_shoulder_wosi_sectionB":"セクションB：スポーツ /レジャー /仕事",
  "patient_shoulder_wosi_q11":"肩の問題により、スポーツやレジャーに参加できる範囲がどの程度制限されましたか？",
  "patient_shoulder_wosi_q11_a1":"制限なし",
  "patient_shoulder_wosi_q11_a2":"極度の制限",
  "patient_shoulder_wosi_q12":"肩はスポーツや仕事に必要な特定のスキルを実行する能力にどの程度影響しましたか？",
  "patient_shoulder_wosi_q12_note":"肩がスポーツと仕事の両方に影響を与えるなら、最も影響を受けたほうを考えてみましょう。",
  "patient_shoulder_wosi_q12_a1":"影響なし",
  "patient_shoulder_wosi_q12_a2":"極度に影響を受けた",
  "patient_shoulder_wosi_q13":"活動中に腕を保護する必要性をどれだけ感じますか？",
  "patient_shoulder_wosi_q13_a1":"全くない",
  "patient_shoulder_wosi_q13_a2":"極度に",
  "patient_shoulder_wosi_q14":"肩の高さより下の重い物を持ち上げるのはどれくらいの困難を経験していますか？",
  "patient_shoulder_wosi_q14_a1":"困難なし",
  "patient_shoulder_wosi_q14_a2":"極度の困難",
  "patient_shoulder_wosi_sectionC":"セクションC：ライフスタイル",
  "patient_shoulder_wosi_q15":"肩が落ちることについてどれほど恐れていますか？",
  "patient_shoulder_wosi_q15_a1":"恐れはない",
  "patient_shoulder_wosi_q15_a2":"極度の恐れ",
  "patient_shoulder_wosi_q16":"希望するレベルのフィットネスを維持するのはどれくらい難しいですか？",
  "patient_shoulder_wosi_q16_a1":"困難なし",
  "patient_shoulder_wosi_q16_a2":"極度の困難",
  "patient_shoulder_wosi_q17":"家族や友人と\"乱暴な遊びや馬の遊び \"をするのはどれほど難しいですか？",
  "patient_shoulder_wosi_q17_a1":"困難なし",
  "patient_shoulder_wosi_q17_a2":"極度の困難",
  "patient_shoulder_wosi_q18":"肩が原因で睡眠にどの程度支障がありますか？",
  "patient_shoulder_wosi_q18_a1":"困難なし",
  "patient_shoulder_wosi_q18_a2":"極度の困難",
  "patient_shoulder_wosi_sectionD":"セクションD：感情",
  "patient_shoulder_wosi_q19":"どれほど肩を意識していますか？",
  "patient_shoulder_wosi_q19_a1":"無意識",
  "patient_shoulder_wosi_q19_a2":"極度に意識的",
  "patient_shoulder_wosi_q20":"肩の悪化についてどれほど心配していますか？",
  "patient_shoulder_wosi_q20_a1":"心配ない",
  "patient_shoulder_wosi_q20_a2":"極度に心配",
  "patient_shoulder_wosi_q21":"肩のせいでどれほどの挫折を感じていますか？",
  "patient_shoulder_wosi_q21_a1":"挫折はない",
  "patient_shoulder_wosi_q21_a2":"極度に挫折",
  "patient_shoulder_ac_joint":"AC関節",
  "patient_shoulder_ac_joint_q1":"AC関節は不安定ですか？",
  "patient_shoulder_ac_joint_q1_note":"注：AC関節は、鎖骨と肩甲骨の前端を接続する肩の関節です。",
  "patient_shoulder_ac_joint_q1_a1":"いいえ",
  "patient_shoulder_ac_joint_q1_a2":"はい",
  "patient_shoulder_sacs":"特定のACスコア（SACS）",
  "patient_shoulder_sacs_explanation":"説明：各質問は、痛みの程度またはさまざまな作業を行うことの困難さのレベルを評価するよう求められます。評価尺度では、0は痛みや困難がないことを示し、10は最も激しい痛みまたは困難を示します。",
  "patient_shoulder_sacs_note":"注：2の評価は、最小限のレベルの痛みを表します。特定の質問の意図に関して質問がある場合は、お気軽に質問してください。",
  "patient_shoulder_sacs_sectionA":"セクションA：痛み",
  "patient_shoulder_sacs_a1":"肩の痛みが最も深刻な場合、その程度（最も深刻なレベル）を評価してください。",
  "patient_shoulder_sacs_a1_a1":"痛みなし",
  "patient_shoulder_sacs_a1_a2":"最も激しい痛み",
  "patient_shoulder_sacs_a2":"肩が最も良い場合、その痛みの程度を評価してください。",
  "patient_shoulder_sacs_a2_a1":"痛みなし",
  "patient_shoulder_sacs_a2_a2":"最も激しい痛み",
  "patient_shoulder_sacs_a3":"押したり、触れたり、圧力をかけたりするときに感じる痛みの程度を評価してください。",
  "patient_shoulder_sacs_a3_note":"例：ノートパソコンやハンドバッグの紐など",
  "patient_shoulder_sacs_a3_a1":"痛みなし",
  "patient_shoulder_sacs_a3_a2":"最も激しい痛み",
  "patient_shoulder_sacs_a4":"首や罹患した肩甲骨領域に感じる痛み、緊張、または引っ張る感覚のレベルを評価してください。",
  "patient_shoulder_sacs_a4_a1":"痛みなし",
  "patient_shoulder_sacs_a4_a2":"最も激しい痛み",
  "patient_shoulder_sacs_sectionB":"セクションB：機能",
  "patient_shoulder_sacs_b1":"肩のカチカチ音、ガリガリ音、カクカク音の大きさを評価してください。",
  "patient_shoulder_sacs_b1_a1":"音はない",
  "patient_shoulder_sacs_b1_a2":"音は極度に深刻",
  "patient_shoulder_sacs_b2":"歩行中に腕のサポートが必要かどうかを示してください。",
  "patient_shoulder_sacs_b2_note":"例：腕をポケットに入れたり、スリングを使用したりする",
  "patient_shoulder_sacs_b2_a1":"サポートの必要はない",
  "patient_shoulder_sacs_b2_a2":"サポートが極端に必要",
  "patient_shoulder_sacs_b3":"腕で体重を支えたり、傾けたりする難易度を評価してください。",
  "patient_shoulder_sacs_b3_note":"例：腕立て伏せ、サイクリング、ヨガ",
  "patient_shoulder_sacs_b3_a1":"困難なし",
  "patient_shoulder_sacs_b3_a2":"極度に困難または実行できない",
  "patient_shoulder_sacs_b4":"重いものを5分以上横で持ち運ぶことの難易度を評価してください。",
  "patient_shoulder_sacs_b4_note":"例：買い物の袋",
  "patient_shoulder_sacs_b4_a1":"困難なし",
  "patient_shoulder_sacs_b4_a2":"極度に困難または実行できない",
  "patient_shoulder_sacs_b5":"患側の腕で体全体に手を伸ばすことの困難さを評価してください。",
  "patient_shoulder_sacs_b5_note":"例：横から手を伸ばし、患部のない肩に触れる。",
  "patient_shoulder_sacs_b5_a1":"困難なし",
  "patient_shoulder_sacs_b5_a2":"極度に困難または実行できない",
  "patient_shoulder_sacs_b6":"重い物や重りを頭上に押すことの難易度を評価してください。",
  "patient_shoulder_sacs_b6_note":"例：5キログラム以上",
  "patient_shoulder_sacs_b6_a1":"困難なし",
  "patient_shoulder_sacs_b6_a2":"極度に困難または実行できない",
  "patient_shoulder_sacs_b7":"患側の腕を前に長時間かざすことの困難さを評価してください",
  "patient_shoulder_sacs_b7_note":"例：運転中にハンドルを保持する",
  "patient_shoulder_sacs_b7_a1":"困難なし",
  "patient_shoulder_sacs_b7_a2":"極度に困難または実行できない",
  "patient_shoulder_sacs_b8":"日常の仕事を遂行する上で、経験する困難さを評価してください。",
  "patient_shoulder_sacs_b8_a1":"困難なし",
  "patient_shoulder_sacs_b8_a2":"極度に困難または実行できない",
  "patient_shoulder_sacs_b8_a3":"該当なし/適用されない",
  "patient_shoulder_sacs_b9":"肩の問題を補うために、仕事を変更/修正する必要がある程度を評価してください",
  "patient_shoulder_sacs_b9_a1":"困難なし",
  "patient_shoulder_sacs_b9_a2":"極度に困難または実行できない",
  "patient_shoulder_sacs_b9_a3":"該当なし/適用されない",
  "patient_shoulder_sacs_b10":"主要なスポーツ活動を行う難易度を評価してください。",
  "patient_shoulder_sacs_b10_a1":"困難なし",
  "patient_shoulder_sacs_b10_a2":"極度に困難または実行できない",
  "patient_shoulder_sacs_b10_a3":"該当なし/適用されない",
  "patient_shoulder_sacs_b11":"肩の問題を補うために、主要な運動活動を変更する必要がある程度を評価してください",
  "patient_shoulder_sacs_b11_a1":"困難なし",
  "patient_shoulder_sacs_b11_a2":"極度に困難または実行できない",
  "patient_shoulder_sacs_b11_a3":"該当なし/適用されない",
  "patient_shoulder_sacs_sectionC":"セクションC：生活の質と満足度",
  "patient_shoulder_sacs_c1":"正常な肩と比較して、肩甲骨の見た目の違いの程度を評価してください",
  "patient_shoulder_sacs_c1_note":"しこりの存在を含む",
  "patient_shoulder_sacs_c1_a1":"違いはない",
  "patient_shoulder_sacs_c1_a2":"極度に違う",
  "patient_shoulder_sacs_c2":"肩の問題のために感じる不安やストレスの全体的なレベルを評価してください",
  "patient_shoulder_sacs_c2_a1":"不安 /ストレスなし",
  "patient_shoulder_sacs_c2_a2":"深刻な不安 /ストレス",
  "patient_shoulder_sacs_c3":"肩の問題を意識する頻度は？",
  "patient_shoulder_sacs_c3_a1":"一度もない",
  "patient_shoulder_sacs_c3_a2":"常に",
  "patient_shoulder_sacs_c4":"肩の問題を避けるためにライフスタイルを変えましたか？",
  "patient_shoulder_sacs_c4_a1":"全くない",
  "patient_shoulder_sacs_c4_a2":"完全に",
  "patient_shoulder_sacs_c5":"自分の肩にどの程度不幸/不満ですか？",
  "patient_shoulder_sacs_c5_a1":"めちゃハッピー",
  "patient_shoulder_sacs_c5_a2":"極度に残念",
  "patient_shoulder_sacs_c6":"肩の状態を、期待する \"普通の \"肩の状態と比べて、総合的に何パーセントで評価しますか？（0％が悪く、100％が非常に良い）",
  "patient_shoulder_satisfaction":"満足度",
  "patient_shoulder_satisfaction_q1":"患部の肩について、現在の状況は？",
  "patient_shoulder_satisfaction_q1_a1":"満足/より良い",
  "patient_shoulder_satisfaction_q1_a2":"不満/より悪い",
  "patient_shoulder_complete":"アンケート終了",
  "patient_shoulder_complete_text1":"時間をかけてこの調査をご記入いただきありがとうございました。",
  "patient_shoulder_complete_text2":"[送信]をクリックして完了し、スコアをご確認ください。",
  "patient_shoulder_complete_text3":"回答は機密に扱われ、レポートは身分を識別されない情報のみを使用します。",
  "patient_shoulder_results":"結果",
  "patient_shoulder_results_text1":"以下は、アンケートから計算された痛みと肩の機能スコアです。",
  "patient_shoulder_results_pain":"痛み",
  "patient_shoulder_results_function":"機能",
  "patient_shoulder_results_instability":"不安定性",
  "patient_shoulder_results_selfevaluation":"肩スコア自己評価",
  "patient_shoulder_results_text2":"まもなく担当医が参ります",
  "patient_hip_pain":"痛み",
  "patient_hip_pain_q1":"全体として、過去1か月間の股関節の痛みをどのように説明しますか？",
  "patient_hip_pain_q1_a1":"なし",
  "patient_hip_pain_q1_a2":"とても軽い",
  "patient_hip_pain_q1_a3":"少し",
  "patient_hip_pain_q1_a4":"中程度",
  "patient_hip_pain_q1_a5":"深刻",
  "patient_hip_pain_q2":"現時点での股関節の痛みはどの程度ですか（0は痛みなし、10は想像できる最も激しい痛み）",
  "patient_hip_pain_q2_a1":"痛みなし",
  "patient_hip_pain_q2_a2":"最も激しい痛み",
  "patient_hip_pain_q3":"次のうち、痛みを最もよく表しているのはどれですか？",
  "patient_hip_pain_q3_a1":"なし/無視",
  "patient_hip_pain_q3_a2":"わずかで、時折、活動に影響を与えない",
  "patient_hip_pain_q3_a3":"軽度の痛み、一般的な活動への影響なし、日常活動後痛み、アスピリンを使用",
  "patient_hip_pain_q3_a4":"普通程度、我慢できる、活動の制限があり、時々コデイン服用",
  "patient_hip_pain_q3_a5":"顕著な痛み、深刻な制限",
  "patient_hip_pain_q3_a6":"完全に活動不能",
  "patient_hip_pain_q4":"股関節が痛む頻度は？",
  "patient_hip_pain_q4_a1":"一度もない",
  "patient_hip_pain_q4_a2":"毎月",
  "patient_hip_pain_q4_a3":"毎週",
  "patient_hip_pain_q4_a4":"毎日",
  "patient_hip_pain_q4_a5":"いつも",
  "patient_hip_pain_q5":"股関節の痛みが、普段の仕事（家事を含む）にどの程度支障をもたらしていますか？",
  "patient_hip_pain_q5_a1":"全くない",
  "patient_hip_pain_q5_a2":"少し",
  "patient_hip_pain_q5_a3":"中程度",
  "patient_hip_pain_q5_a4":"とても",
  "patient_hip_pain_q5_a5":"完全に",
  "patient_hip_pain_instruction":"次の活動中にどのくらいの股関節痛を経験しましたか：",
  "patient_hip_pain_instruction_0":"なし",
  "patient_hip_pain_instruction_1":"少し",
  "patient_hip_pain_instruction_2":"中程度",
  "patient_hip_pain_instruction_3":"深刻",
  "patient_hip_pain_instruction_4":"極度に",
  "patient_hip_pain_note":"注：股関節の活動に関する次の質問に答えてください。",
  "patient_hip_pain_q6":"股関節を完全にまっすぐにする",
  "patient_hip_pain_q6_a1":"なし",
  "patient_hip_pain_q6_a2":"少し",
  "patient_hip_pain_q6_a3":"中程度",
  "patient_hip_pain_q6_a4":"深刻",
  "patient_hip_pain_q6_a5":"極度に",
  "patient_hip_pain_q7":"股関節を完全に曲げる",
  "patient_hip_pain_q7_a1":"なし",
  "patient_hip_pain_q7_a2":"少し",
  "patient_hip_pain_q7_a3":"中程度",
  "patient_hip_pain_q7_a4":"深刻",
  "patient_hip_pain_q7_a5":"極度に",
  "patient_hip_pain_q8":"平らな表面を歩く",
  "patient_hip_pain_q8_a1":"なし",
  "patient_hip_pain_q8_a2":"少し",
  "patient_hip_pain_q8_a3":"中程度",
  "patient_hip_pain_q8_a4":"深刻",
  "patient_hip_pain_q8_a5":"極度に",
  "patient_hip_pain_q9":"腰の痛みが悪化するまでどのくらい歩くことができますか？ （杖を使用するかどうかにかかわらず）",
  "patient_hip_pain_q9_a1":"痛みなし/30分以上/時間の制限なし",
  "patient_hip_pain_q9_a2":"16-30分/6ブロック",
  "patient_hip_pain_q9_a3":"5-15分/2-3ブロック",
  "patient_hip_pain_q9_a4":"家の周り/屋内のみ",
  "patient_hip_pain_q9_a5":"全く歩けない／歩行時の痛みが強い／ベッドと椅子の上のみ",
  "patient_hip_pain_q10":"硬い表面を歩く（アスファルト、コンクリートなど）",
  "patient_hip_pain_q10_a1":"なし",
  "patient_hip_pain_q10_a2":"少し",
  "patient_hip_pain_q10_a3":"中程度",
  "patient_hip_pain_q10_a4":"深刻",
  "patient_hip_pain_q10_a5":"極度に",
  "patient_hip_pain_q11":"不規則な表面を歩く",
  "patient_hip_pain_q11_a1":"なし",
  "patient_hip_pain_q11_a2":"少し",
  "patient_hip_pain_q11_a3":"中程度",
  "patient_hip_pain_q11_a4":"深刻",
  "patient_hip_pain_q11_a5":"極度に",
  "patient_hip_pain_q12":"階段を上下する",
  "patient_hip_pain_q12_a1":"なし",
  "patient_hip_pain_q12_a2":"少し",
  "patient_hip_pain_q12_a3":"中程度",
  "patient_hip_pain_q12_a4":"深刻",
  "patient_hip_pain_q12_a5":"極度に",
  "patient_hip_pain_q13":"夜のベッドで",
  "patient_hip_pain_q13_a1":"なし",
  "patient_hip_pain_q13_a2":"少し",
  "patient_hip_pain_q13_a3":"中程度",
  "patient_hip_pain_q13_a4":"深刻",
  "patient_hip_pain_q13_a5":"極度に",
  "patient_hip_pain_q14":"夜のベッドで股関節の痛みに悩まされている頻度はどれくらいですか？",
  "patient_hip_pain_q14_a1":"なし",
  "patient_hip_pain_q14_a2":"一夜か二夜だけ",
  "patient_hip_pain_q14_a3":"いくつかの夜",
  "patient_hip_pain_q14_a4":"ほとんどの夜",
  "patient_hip_pain_q14_a5":"毎晩",
  "patient_hip_pain_q15":"座っている、または寝ている",
  "patient_hip_pain_q15_a1":"なし",
  "patient_hip_pain_q15_a2":"少し",
  "patient_hip_pain_q15_a3":"中程度",
  "patient_hip_pain_q15_a4":"深刻",
  "patient_hip_pain_q15_a5":"極度に",
  "patient_hip_pain_q16":"まっすぐ立つ",
  "patient_hip_pain_q16_a1":"なし",
  "patient_hip_pain_q16_a2":"少し",
  "patient_hip_pain_q16_a3":"中程度",
  "patient_hip_pain_q16_a4":"深刻",
  "patient_hip_pain_q16_a5":"極度に",
  "patient_hip_pain_q17":"椅子から立ち上がっているとき",
  "patient_hip_pain_q17_a1":"なし",
  "patient_hip_pain_q17_a2":"少し",
  "patient_hip_pain_q17_a3":"中程度",
  "patient_hip_pain_q17_a4":"深刻",
  "patient_hip_pain_q17_a5":"極度に",
  "patient_hip_pain_q18":"活動後",
  "patient_hip_pain_q18_a1":"なし",
  "patient_hip_pain_q18_a2":"少し",
  "patient_hip_pain_q18_a3":"中程度",
  "patient_hip_pain_q18_a4":"深刻",
  "patient_hip_pain_q18_a5":"極度に",
  "patient_hip_other_symptoms":"その他の症状",
  "patient_hip_other_symptoms_instruction1":"股関節の症状に最もよく対応する答えを選択してください。",
  "patient_hip_other_symptoms_instruction2":"質問に答える方法がわからない場合は、できる限り最良の答えをお願いします。",
  "patient_hip_other_symptoms_instruction3":"次のことはありますか？",
  "patient_hip_other_symptoms_instruction_0":"なし",
  "patient_hip_other_symptoms_instruction_1":"少し",
  "patient_hip_other_symptoms_instruction_2":"中程度",
  "patient_hip_other_symptoms_instruction_3":"深刻",
  "patient_hip_other_symptoms_instruction_4":"極度に",
  "patient_hip_other_symptoms_q1":"股関節からガリガリ音、カチカチ音やその他の異音がする",
  "patient_hip_other_symptoms_q1_a1":"一度もない",
  "patient_hip_other_symptoms_q1_a2":"めったに",
  "patient_hip_other_symptoms_q1_a3":"時々",
  "patient_hip_other_symptoms_q1_a4":"頻繁",
  "patient_hip_other_symptoms_q1_a5":"いつも",
  "patient_hip_other_symptoms_q2":"股関節が引っかかっているか動けない感じがする",
  "patient_hip_other_symptoms_q2_a1":"なし",
  "patient_hip_other_symptoms_q2_a2":"少し",
  "patient_hip_other_symptoms_q2_a3":"中程度",
  "patient_hip_other_symptoms_q2_a4":"深刻",
  "patient_hip_other_symptoms_q2_a5":"極度に",
  "patient_hip_other_symptoms_q3":"朝目覚め後のこり",
  "patient_hip_other_symptoms_q3_a1":"なし",
  "patient_hip_other_symptoms_q3_a2":"少し",
  "patient_hip_other_symptoms_q3_a3":"中程度",
  "patient_hip_other_symptoms_q3_a4":"深刻",
  "patient_hip_other_symptoms_q3_a5":"極度に",
  "patient_hip_other_symptoms_q4":"座った後、横になった後、またはその日後半に休んだ後のこり",
  "patient_hip_other_symptoms_q4_a1":"なし",
  "patient_hip_other_symptoms_q4_a2":"少し",
  "patient_hip_other_symptoms_q4_a3":"中程度",
  "patient_hip_other_symptoms_q4_a4":"深刻",
  "patient_hip_other_symptoms_q4_a5":"極度に",
  "patient_hip_other_symptoms_q5":"腰がだるい",
  "patient_hip_other_symptoms_q5_a1":"めったに/まったくない",
  "patient_hip_other_symptoms_q5_a2":"少し、時々、または最初に",
  "patient_hip_other_symptoms_q5_a3":"中程度、多くの場合、最初だけではない",
  "patient_hip_other_symptoms_q5_a4":"深刻、ほとんどの時間",
  "patient_hip_other_symptoms_q5_a5":"常に、または歩くことができない",
  "patient_hip_other_symptoms_q6":"突然の激痛 - 股関節からの突然の鋭い痛み、突き刺すような痛み、痙攣",
  "patient_hip_other_symptoms_q6_a1":"存在しない",
  "patient_hip_other_symptoms_q6_a2":"たった1日か2日",
  "patient_hip_other_symptoms_q6_a3":"時々",
  "patient_hip_other_symptoms_q6_a4":"ほとんどの日",
  "patient_hip_other_symptoms_q6_a5":"毎日",
  "patient_hip_other_symptoms_q7":"腰の力が抜ける感じ",
  "patient_hip_other_symptoms_q7_a1":"なし",
  "patient_hip_other_symptoms_q7_a2":"少し",
  "patient_hip_other_symptoms_q7_a3":"中程度",
  "patient_hip_other_symptoms_q7_a4":"深刻",
  "patient_hip_other_symptoms_q7_a5":"極度に",
  "patient_hip_other_symptoms_q8":"動きの減少",
  "patient_hip_other_symptoms_q8_a1":"なし",
  "patient_hip_other_symptoms_q8_a2":"少し",
  "patient_hip_other_symptoms_q8_a3":"中程度",
  "patient_hip_other_symptoms_q8_a4":"深刻",
  "patient_hip_other_symptoms_q8_a5":"極度に",
  "patient_hip_other_symptoms_q9":"歩くときに何かサポートを使用していますか？",
  "patient_hip_other_symptoms_q9_a1":"なし",
  "patient_hip_other_symptoms_q9_a2":"長い距離を歩く場合、杖やステッキを使用",
  "patient_hip_other_symptoms_q9_a3":"ほとんどの時間で杖やステッキを使用",
  "patient_hip_other_symptoms_q9_a4":"1つの松葉杖",
  "patient_hip_other_symptoms_q9_a5":"2つの松葉杖/ステッキ",
  "patient_hip_other_symptoms_q9_a6":"松葉杖を2本使うか、歩けない",
  "patient_hip_function":"機能",
  "patient_hip_function_instruction":"最近1週間で股関節のため、次の活動で経験した困難の程度を示してください。",
  "patient_hip_function_instruction_0":"なし",
  "patient_hip_function_instruction_1":"少し",
  "patient_hip_function_instruction_2":"中程度",
  "patient_hip_function_instruction_3":"深刻",
  "patient_hip_function_instruction_4":"極度に/できない",
  "patient_hip_function_note":"注：特定の活動をしない場合、もしそれをしなければならなくなったら自分の腰がどう感じるかを想像してください",
  "patient_hip_function_q1":"階段を下る",
  "patient_hip_function_q1_a1":"なし",
  "patient_hip_function_q1_a2":"少し",
  "patient_hip_function_q1_a3":"中程度",
  "patient_hip_function_q1_a4":"深刻",
  "patient_hip_function_q1_a5":"極度に/できない",
  "patient_hip_function_q2":"階段を上る",
  "patient_hip_function_q2_a1":"なし",
  "patient_hip_function_q2_a2":"少し",
  "patient_hip_function_q2_a3":"中程度",
  "patient_hip_function_q2_a4":"深刻",
  "patient_hip_function_q2_a5":"極度に/できない",
  "patient_hip_function_q3":"座っている",
  "patient_hip_function_q3_a1":"なし",
  "patient_hip_function_q3_a2":"少し",
  "patient_hip_function_q3_a3":"中程度",
  "patient_hip_function_q3_a4":"深刻",
  "patient_hip_function_q3_a5":"極度に/できない",
  "patient_hip_function_q4":"座っている状態から立ち上がる",
  "patient_hip_function_q4_a1":"なし",
  "patient_hip_function_q4_a2":"少し",
  "patient_hip_function_q4_a3":"中程度",
  "patient_hip_function_q4_a4":"深刻",
  "patient_hip_function_q4_a5":"極度に/できない",
  "patient_hip_function_q5":"床/地面からの立ち上がりと降り",
  "patient_hip_function_q5_a1":"なし",
  "patient_hip_function_q5_a2":"少し",
  "patient_hip_function_q5_a3":"中程度",
  "patient_hip_function_q5_a4":"深刻",
  "patient_hip_function_q5_a5":"極度に/できない",
  "patient_hip_function_q6":"立っている",
  "patient_hip_function_q6_a1":"なし",
  "patient_hip_function_q6_a2":"少し",
  "patient_hip_function_q6_a3":"中程度",
  "patient_hip_function_q6_a4":"深刻",
  "patient_hip_function_q6_a5":"極度に/できない",
  "patient_hip_function_q7":"15分間立ってる",
  "patient_hip_function_q7_a1":"なし",
  "patient_hip_function_q7_a2":"少し",
  "patient_hip_function_q7_a3":"中程度",
  "patient_hip_function_q7_a4":"深刻",
  "patient_hip_function_q7_a5":"極度に/できない",
  "patient_hip_function_q8":"足を大きく広げる",
  "patient_hip_function_q8_a1":"なし",
  "patient_hip_function_q8_a2":"少し",
  "patient_hip_function_q8_a3":"中程度",
  "patient_hip_function_q8_a4":"深刻",
  "patient_hip_function_q8_a5":"極度に/できない",
  "patient_hip_function_q9":"歩くときに大股で歩く",
  "patient_hip_function_q9_a1":"なし",
  "patient_hip_function_q9_a2":"少し",
  "patient_hip_function_q9_a3":"中程度",
  "patient_hip_function_q9_a4":"深刻",
  "patient_hip_function_q9_a5":"極度に/できない",
  "patient_hip_function_q10":"床に屈む／物を拾う",
  "patient_hip_function_q10_a1":"なし",
  "patient_hip_function_q10_a2":"少し",
  "patient_hip_function_q10_a3":"中程度",
  "patient_hip_function_q10_a4":"深刻",
  "patient_hip_function_q10_a5":"極度に/できない",
  "patient_hip_function_q11":"平らな表面を歩く",
  "patient_hip_function_q11_a1":"なし",
  "patient_hip_function_q11_a2":"少し",
  "patient_hip_function_q11_a3":"中程度",
  "patient_hip_function_q11_a4":"深刻",
  "patient_hip_function_q11_a5":"極度に/できない",
  "patient_hip_function_q12":"起伏のある路面を歩く",
  "patient_hip_function_q12_a1":"なし",
  "patient_hip_function_q12_a2":"少し",
  "patient_hip_function_q12_a3":"中程度",
  "patient_hip_function_q12_a4":"深刻",
  "patient_hip_function_q12_a5":"極度に/できない",
  "patient_hip_function_q13":"長距離歩く",
  "patient_hip_function_q13_a1":"なし",
  "patient_hip_function_q13_a2":"少し",
  "patient_hip_function_q13_a3":"中程度",
  "patient_hip_function_q13_a4":"深刻",
  "patient_hip_function_q13_a5":"極度に/できない",
  "patient_hip_function_q14":"運動のために歩く",
  "patient_hip_function_q14_a1":"なし",
  "patient_hip_function_q14_a2":"少し",
  "patient_hip_function_q14_a3":"中程度",
  "patient_hip_function_q14_a4":"深刻",
  "patient_hip_function_q14_a5":"極度に/できない",
  "patient_hip_function_q15":"急な坂を上る",
  "patient_hip_function_q15_a1":"なし",
  "patient_hip_function_q15_a2":"少し",
  "patient_hip_function_q15_a3":"中程度",
  "patient_hip_function_q15_a4":"深刻",
  "patient_hip_function_q15_a5":"極度に/できない",
  "patient_hip_function_q16":"急な坂を下る",
  "patient_hip_function_q16_a1":"なし",
  "patient_hip_function_q16_a2":"少し",
  "patient_hip_function_q16_a3":"中程度",
  "patient_hip_function_q16_a4":"深刻",
  "patient_hip_function_q16_a5":"極度に/できない",
  "patient_hip_function_q17":"最初の歩く",
  "patient_hip_function_q17_a1":"なし",
  "patient_hip_function_q17_a2":"少し",
  "patient_hip_function_q17_a3":"中程度",
  "patient_hip_function_q17_a4":"深刻",
  "patient_hip_function_q17_a5":"極度に/できない",
  "patient_hip_function_q18":"約10分歩く",
  "patient_hip_function_q18_a1":"なし",
  "patient_hip_function_q18_a2":"少し",
  "patient_hip_function_q18_a3":"中程度",
  "patient_hip_function_q18_a4":"深刻",
  "patient_hip_function_q18_a5":"極度に/できない",
  "patient_hip_function_q19":"15分以上歩く",
  "patient_hip_function_q19_a1":"なし",
  "patient_hip_function_q19_a2":"少し",
  "patient_hip_function_q19_a3":"中程度",
  "patient_hip_function_q19_a4":"深刻",
  "patient_hip_function_q19_a5":"極度に/できない",
  "patient_hip_function_q20":"縁石の上り下り",
  "patient_hip_function_q20_a1":"なし",
  "patient_hip_function_q20_a2":"少し",
  "patient_hip_function_q20_a3":"中程度",
  "patient_hip_function_q20_a4":"深刻",
  "patient_hip_function_q20_a5":"極度に/できない",
  "patient_hip_function_q21":"利用することが多い車や公共交通機関（バス）を乗り降りする",
  "patient_hip_function_q21_a1":"なし",
  "patient_hip_function_q21_a2":"少し",
  "patient_hip_function_q21_a3":"中程度",
  "patient_hip_function_q21_a4":"深刻",
  "patient_hip_function_q21_a5":"極度に/できない",
  "patient_hip_function_q22":"買い物",
  "patient_hip_function_q22_a1":"なし",
  "patient_hip_function_q22_a2":"少し",
  "patient_hip_function_q22_a3":"中程度",
  "patient_hip_function_q22_a4":"深刻",
  "patient_hip_function_q22_a5":"極度に/できない",
  "patient_hip_function_q23":"靴下/ストッキングを着用",
  "patient_hip_function_q23_a1":"なし",
  "patient_hip_function_q23_a2":"少し",
  "patient_hip_function_q23_a3":"中程度",
  "patient_hip_function_q23_a4":"深刻",
  "patient_hip_function_q23_a5":"極度に/できない",
  "patient_hip_function_q24":"靴下/ストッキングを脱ぐ",
  "patient_hip_function_q24_a1":"なし",
  "patient_hip_function_q24_a2":"少し",
  "patient_hip_function_q24_a3":"中程度",
  "patient_hip_function_q24_a4":"深刻",
  "patient_hip_function_q24_a5":"極度に/できない",
  "patient_hip_function_q25":"ベッドに横たわっている（ひっくり返り、腰の姿勢を維持する）",
  "patient_hip_function_q25_a1":"なし",
  "patient_hip_function_q25_a2":"少し",
  "patient_hip_function_q25_a3":"中程度",
  "patient_hip_function_q25_a4":"深刻",
  "patient_hip_function_q25_a5":"極度に/できない",
  "patient_hip_function_q26":"ベッドでの寝返り",
  "patient_hip_function_q26_a1":"なし",
  "patient_hip_function_q26_a2":"少し",
  "patient_hip_function_q26_a3":"中程度",
  "patient_hip_function_q26_a4":"深刻",
  "patient_hip_function_q26_a5":"極度に/できない",
  "patient_hip_function_q27":"ベッドから立ち上がる",
  "patient_hip_function_q27_a1":"なし",
  "patient_hip_function_q27_a2":"少し",
  "patient_hip_function_q27_a3":"中程度",
  "patient_hip_function_q27_a4":"深刻",
  "patient_hip_function_q27_a5":"極度に/できない",
  "patient_hip_function_q28":"お風呂に出入りする",
  "patient_hip_function_q28_a1":"なし",
  "patient_hip_function_q28_a2":"少し",
  "patient_hip_function_q28_a3":"中程度",
  "patient_hip_function_q28_a4":"深刻",
  "patient_hip_function_q28_a5":"極度に/できない",
  "patient_hip_function_q29":"トイレの乗り降り",
  "patient_hip_function_q29_a1":"なし",
  "patient_hip_function_q29_a2":"少し",
  "patient_hip_function_q29_a3":"中程度",
  "patient_hip_function_q29_a4":"深刻",
  "patient_hip_function_q29_a5":"極度に/できない",
  "patient_hip_function_q30":"自分の全身を洗って乾かす",
  "patient_hip_function_q30_a1":"なし",
  "patient_hip_function_q30_a2":"少し",
  "patient_hip_function_q30_a3":"中程度",
  "patient_hip_function_q30_a4":"深刻",
  "patient_hip_function_q30_a5":"極度に/できない",
  "patient_hip_function_q31":"軽～中程度の作業（立ち仕事、歩行）",
  "patient_hip_function_q31_a1":"なし",
  "patient_hip_function_q31_a2":"少し",
  "patient_hip_function_q31_a3":"中程度",
  "patient_hip_function_q31_a4":"深刻",
  "patient_hip_function_q31_a5":"極度に/できない",
  "patient_hip_function_q32":"重い家事労働（重い箱の移動、床拭きなど）",
  "patient_hip_function_q32_a1":"なし",
  "patient_hip_function_q32_a2":"少し",
  "patient_hip_function_q32_a3":"中程度",
  "patient_hip_function_q32_a4":"深刻",
  "patient_hip_function_q32_a5":"極度に/できない",
  "patient_hip_function_q33":"軽い家事（料理、掃除など）",
  "patient_hip_function_q33_a1":"なし",
  "patient_hip_function_q33_a2":"少し",
  "patient_hip_function_q33_a3":"中程度",
  "patient_hip_function_q33_a4":"深刻",
  "patient_hip_function_q33_a5":"極度に/できない",
  "patient_hip_function_q34":"子供を抱き上げたり、運んだりする",
  "patient_hip_function_q34_a1":"なし",
  "patient_hip_function_q34_a2":"少し",
  "patient_hip_function_q34_a3":"中程度",
  "patient_hip_function_q34_a4":"深刻",
  "patient_hip_function_q34_a5":"極度に/できない",
  "patient_hip_function_q35":"しゃがむ",
  "patient_hip_function_q35_a1":"なし",
  "patient_hip_function_q35_a2":"少し",
  "patient_hip_function_q35_a3":"中程度",
  "patient_hip_function_q35_a4":"深刻",
  "patient_hip_function_q35_a5":"極度に/できない",
  "patient_hip_function_q36":"運動のためのジョギング",
  "patient_hip_function_q36_a1":"なし",
  "patient_hip_function_q36_a2":"少し",
  "patient_hip_function_q36_a3":"中程度",
  "patient_hip_function_q36_a4":"深刻",
  "patient_hip_function_q36_a5":"極度に/できない",
  "patient_hip_function_q37":"走る",
  "patient_hip_function_q37_a1":"なし",
  "patient_hip_function_q37_a2":"少し",
  "patient_hip_function_q37_a3":"中程度",
  "patient_hip_function_q37_a4":"深刻",
  "patient_hip_function_q37_a5":"極度に/できない",
  "patient_hip_function_q38":"負荷のかかった脚をひねる/回転させる",
  "patient_hip_function_q38_a1":"なし",
  "patient_hip_function_q38_a2":"少し",
  "patient_hip_function_q38_a3":"中程度",
  "patient_hip_function_q38_a4":"深刻",
  "patient_hip_function_q38_a5":"極度に/できない",
  "patient_hip_function_q39":"性行為",
  "patient_hip_function_q39_a1":"なし",
  "patient_hip_function_q39_a2":"少し",
  "patient_hip_function_q39_a3":"中程度",
  "patient_hip_function_q39_a4":"深刻",
  "patient_hip_function_q39_a5":"極度に/できない",
  "patient_hip_function_q39_not_relevant":"私とは関係ない",
  "patient_hip_function_q40":"スプリントやカッティングを伴う負荷の高いスポーツ（サッカー、バスケットボール、テニス、エアロビクスなど）",
  "patient_hip_function_q40_a1":"なし",
  "patient_hip_function_q40_a2":"少し",
  "patient_hip_function_q40_a3":"中程度",
  "patient_hip_function_q40_a4":"深刻",
  "patient_hip_function_q40_a5":"極度に/できない",
  "patient_hip_function_q41":"負荷の低いスポーツ（ゴルフ、ボーリングなど）",
  "patient_hip_function_q41_a1":"なし",
  "patient_hip_function_q41_a2":"少し",
  "patient_hip_function_q41_a3":"中程度",
  "patient_hip_function_q41_a4":"深刻",
  "patient_hip_function_q41_a5":"極度に/できない",
  "patient_hip_function_q42":"レジャー活動",
  "patient_hip_function_q42_a1":"なし",
  "patient_hip_function_q42_a2":"少し",
  "patient_hip_function_q42_a3":"中程度",
  "patient_hip_function_q42_a4":"深刻",
  "patient_hip_function_q42_a5":"極度に/できない",
  "patient_hip_function_q43":"通常の割合として今日の腰をどのように評価しますか？ （0％から100％まで、100％は正常）",
  "patient_hip_function_q44":"参加できる最高レベルの活動を指定してください",
  "patient_hip_function_q44_q1":"股関節損傷/股関節の問題の前",
  "patient_hip_function_q44_q1_a1":"競争的な活動",
  "patient_hip_function_q44_q1_a2":"レジャー、フィットネス",
  "patient_hip_function_q44_q1_a3":"スポーツなしで働くのみ",
  "patient_hip_function_q44_q1_a4":"日常生活の活動のみ",
  "patient_hip_function_q44_q1_a5":"健康問題のために病気休暇または障害年金をとる",
  "patient_hip_function_q44_q1_a1_o1":"世界レベル、オリンピックレベル、またはプロとしてプレーする競技スポーツ",
  "patient_hip_function_q44_q1_a1_o2":"陸上競技、ラケット／ボールスポーツ、体操、ボート、スキー、武道など、国内または国際レベルの競技スポーツ",
  "patient_hip_function_q44_q1_a1_o3":"ウォータースポーツ、サイクリング、ホッケー、カーリング、乗馬／ロデオなど、国内または国際レベルの競技スポーツ",
  "patient_hip_function_q44_q1_a2_o1":"ランニング、ボール／ラケットスポーツ、ウェイトトレーニング、カーリング、ボート、ゴルフ、ヨガ、体操などの趣味的なスポーツを週5回以上行う。",
  "patient_hip_function_q44_q1_a2_o2":"水泳、ウォータースポーツ、スキー、ホッケー、ローラーブレード、自転車、乗馬またはハイキング、週5回以上",
  "patient_hip_function_q44_q1_a2_o3":"趣味的なスポーツ、週2回以上",
  "patient_hip_function_q44_q1_a2_o4":"趣味的なスポーツ、週1回以下",
  "patient_hip_function_q44_q1_a2_o5":"趣味的なスポーツ、月1回未満",
  "patient_hip_function_q44_q1_a3_o1":"建築のような重労働",
  "patient_hip_function_q44_q1_a3_o2":"トラック運転などの中度の重労働",
  "patient_hip_function_q44_q1_a3_o3":"看護などの軽い労働",
  "patient_hip_function_q44_q1_a3_o4":"秘書業務やコンピューター作業など、座りっぱなしの仕事",
  "patient_hip_function_q44_q1_a4_o1":"ガーデニング、複数の階段の昇り降り、荷物の運搬、荷物の押し引きなどの日常活動、遅刻した場合のランニング能力",
  "patient_hip_function_q44_q1_a4_o2":"家の掃除、1～2続きの階段の昇り降り、軽い荷物を持っての歩行などの日常活動",
  "patient_hip_function_q44_q1_a4_o3":"日常生活が制限される（階段を使わない、荷物を運べないなど）。",
  "patient_hip_function_q44_q2":"現在の状況",
  "patient_hip_function_q44_q2_a1":"競争的な活動",
  "patient_hip_function_q44_q2_a2":"レジャー、フィットネス",
  "patient_hip_function_q44_q2_a3":"スポーツなしで働くのみ",
  "patient_hip_function_q44_q2_a4":"日常生活の活動のみ",
  "patient_hip_function_q44_q2_a5":"健康問題のために病気休暇または障害年金をとる",
  "patient_hip_function_q44_q2_a1_o1":"世界レベル、オリンピックレベル、またはプロとしてプレーする競技スポーツ",
  "patient_hip_function_q44_q2_a1_o2":"陸上競技、ラケット／ボールスポーツ、体操、ボート、スキー、武道など、国内または国際レベルの競技スポーツ",
  "patient_hip_function_q44_q2_a1_o3":"ウォータースポーツ、サイクリング、ホッケー、カーリング、乗馬／ロデオなど、国内または国際レベルの競技スポーツ",
  "patient_hip_function_q44_q2_a2_o1":"ランニング、ボール／ラケットスポーツ、ウェイトトレーニング、カーリング、ボート、ゴルフ、ヨガ、体操などの趣味的なスポーツを週5回以上行う。",
  "patient_hip_function_q44_q2_a2_o2":"水泳、ウォータースポーツ、スキー、ホッケー、ローラーブレード、自転車、乗馬またはハイキング、週5回以上",
  "patient_hip_function_q44_q2_a2_o3":"趣味的なスポーツ、週2回以上",
  "patient_hip_function_q44_q2_a2_o4":"趣味的なスポーツ、週1回以下",
  "patient_hip_function_q44_q2_a2_o5":"趣味的なスポーツ、月1回未満",
  "patient_hip_function_q44_q2_a3_o1":"建築のような重労働",
  "patient_hip_function_q44_q2_a3_o2":"トラック運転などの中度の重労働",
  "patient_hip_function_q44_q2_a3_o3":"看護などの軽い労働",
  "patient_hip_function_q44_q2_a3_o4":"秘書業務やコンピューター作業など、座りっぱなしの仕事",
  "patient_hip_function_q44_q2_a4_o1":"ガーデニング、複数の階段の昇り降り、荷物の運搬、荷物の押し引きなどの日常活動、遅刻した場合のランニング能力",
  "patient_hip_function_q44_q2_a4_o2":"家の掃除、1～2続きの階段の昇り降り、軽い荷物を持っての歩行などの日常活動",
  "patient_hip_function_q44_q2_a4_o3":"日常生活が制限される（階段を使わない、荷物を運べないなど）。",
  "patient_hip_quality_of_life":"生活の質",
  "patient_hip_quality_of_life_instruction":"以下は、股関節の問題に感じる社会的、感情的、ライフスタイルに関する質問です。股関節について通常感じていることを考えてください。",
  "patient_hip_quality_of_life_q1":"股関節の問題を意識する頻度は？",
  "patient_hip_quality_of_life_q1_a1":"一度もない",
  "patient_hip_quality_of_life_q1_a2":"毎月",
  "patient_hip_quality_of_life_q1_a3":"毎週",
  "patient_hip_quality_of_life_q1_a4":"毎日",
  "patient_hip_quality_of_life_q1_a5":"常に",
  "patient_hip_quality_of_life_q2":"腰の問題を避けるためにライフスタイルを変えましたか？",
  "patient_hip_quality_of_life_q2_a1":"全くない",
  "patient_hip_quality_of_life_q2_a2":"少し",
  "patient_hip_quality_of_life_q2_a3":"中程度",
  "patient_hip_quality_of_life_q2_a4":"ひどく",
  "patient_hip_quality_of_life_q2_a5":"完全に",
  "patient_hip_quality_of_life_q3":"希望するフィットネス・レベルを維持できるかについて、どの程度心配ですか？",
  "patient_hip_quality_of_life_q3_a1":"まったく心配しない",
  "patient_hip_quality_of_life_q3_a2":"極度に心配",
  "patient_hip_quality_of_life_q4":"腰に自信がないことにどれだけ悩まされていますか？",
  "patient_hip_quality_of_life_q4_a1":"全くない",
  "patient_hip_quality_of_life_q4_a2":"少し",
  "patient_hip_quality_of_life_q4_a3":"中程度",
  "patient_hip_quality_of_life_q4_a4":"ひどく",
  "patient_hip_quality_of_life_q4_a5":"極度に",
  "patient_hip_quality_of_life_q5":"股関節の問題でどの程度気が散っていますか？",
  "patient_hip_quality_of_life_q5_a1":"気がまったく散っていない",
  "patient_hip_quality_of_life_q5_a2":"極度に気を散らす",
  "patient_hip_quality_of_life_q6":"一般的に、股関節にどの程度支障がありますか？",
  "patient_hip_quality_of_life_q6_a1":"なし",
  "patient_hip_quality_of_life_q6_a2":"少し",
  "patient_hip_quality_of_life_q6_a3":"中程度",
  "patient_hip_quality_of_life_q6_a4":"深刻",
  "patient_hip_quality_of_life_q6_a5":"極度に",
  "patient_hip_quality_of_life_q7":"今日はどれほど不安/落ち込んでいますか？",
  "patient_hip_quality_of_life_q7_a1":"全くない",
  "patient_hip_quality_of_life_q7_a2":"少し",
  "patient_hip_quality_of_life_q7_a3":"中程度",
  "patient_hip_quality_of_life_q7_a4":"ひどく",
  "patient_hip_quality_of_life_q7_a5":"極度に",
  "patient_hip_quality_of_life_q8":"今日の健康はどれくらい良いですか？ （100は最高の健康であり、0は想像できる最悪の健康を意味します）",
  "patient_hip_quality_of_life_q8_a1":"最悪の健康",
  "patient_hip_quality_of_life_q8_a2":"最高の健康",
  "patient_hip_satisfaction":"満足度",
  "patient_hip_satisfaction_instructions":"股関節手術/治療の結果にどの程度満足していますか？",
  "patient_hip_satisfaction_instructions_1":"非常に不満",
  "patient_hip_satisfaction_instructions_2":"ある程度の不満",
  "patient_hip_satisfaction_instructions_3":"やや満足",
  "patient_hip_satisfaction_instructions_4":"非常に満足",
  "patient_hip_satisfaction_q1":"全体",
  "patient_hip_satisfaction_q1_not_applicable":"適用できない",
  "patient_hip_satisfaction_q1_a1":"非常に不満",
  "patient_hip_satisfaction_q1_a2":"ある程度の不満",
  "patient_hip_satisfaction_q1_a3":"やや満足",
  "patient_hip_satisfaction_q1_a4":"非常に満足",
  "patient_hip_satisfaction_q2":"痛みの改善については？",
  "patient_hip_satisfaction_q2_not_applicable":"適用できない",
  "patient_hip_satisfaction_q2_a1":"非常に不満",
  "patient_hip_satisfaction_q2_a2":"ある程度の不満",
  "patient_hip_satisfaction_q2_a3":"やや満足",
  "patient_hip_satisfaction_q2_a4":"非常に満足",
  "patient_hip_satisfaction_q3":"家事や庭仕事の能力改善について？",
  "patient_hip_satisfaction_q3_not_applicable":"適用できない",
  "patient_hip_satisfaction_q3_a1":"非常に不満",
  "patient_hip_satisfaction_q3_a2":"ある程度の不満",
  "patient_hip_satisfaction_q3_a3":"やや満足",
  "patient_hip_satisfaction_q3_a4":"非常に満足",
  "patient_hip_satisfaction_q4":"レジャー活動の能力改善について？",
  "patient_hip_satisfaction_q4_not_applicable":"適用できない",
  "patient_hip_satisfaction_q4_a1":"非常に不満",
  "patient_hip_satisfaction_q4_a2":"ある程度の不満",
  "patient_hip_satisfaction_q4_a3":"やや満足",
  "patient_hip_satisfaction_q4_a4":"非常に満足",
  "patient_hip_complete":"アンケート終了",
  "patient_hip_complete_text1":"時間をかけてこの調査をご記入いただきありがとうございました。",
  "patient_hip_complete_text2":"[送信]をクリックして完了し、スコアをご確認ください。",
  "patient_hip_complete_text3":"回答は機密に扱われ、レポートは身分を識別されない情報のみを使用します。",
  "patient_hip_results":"結果",
  "patient_hip_results_text1":"以下は、アンケートから計算された痛みと股関節の機能スコアです。",
  "patient_hip_results_pain":"痛み",
  "patient_hip_results_function":"機能",
  "patient_hip_results_health_perception":"全体的な健康認識",
  "patient_hip_results_self_evaluation":"股関節スコア自己評価",
  "patient_hip_results_text2":"まもなく担当医が参ります。",
  "":"",
  "patient_adult_knee_pain":"痛み",
  "patient_adult_knee_pain_q1":"膝に強い痛みを感じることなく行える最高レベルの活動は？",
  "patient_adult_knee_pain_q1_a1":"バスケットボールやサッカーでのジャンプや回転など、非常に激しい運動",
  "patient_adult_knee_pain_q1_a2":"重い肉体労働、スキー、テニスなどの激しい運動",
  "patient_adult_knee_pain_q1_a3":"中程度の肉体労働、ランニング、ジョギングなどの適度な活動",
  "patient_adult_knee_pain_q1_a4":"散歩、家事、庭仕事などの軽い活動",
  "patient_adult_knee_pain_q1_a5":"膝の痛みのため、上記の活動を実行できない",
  "patient_adult_knee_pain_q2":"どのくらいの頻度で痛みを感じましたか？",
  "patient_adult_knee_pain_q2_a1":"一度もない",
  "patient_adult_knee_pain_q2_a2":"毎月",
  "patient_adult_knee_pain_q2_a3":"毎週",
  "patient_adult_knee_pain_q2_a4":"毎日",
  "patient_adult_knee_pain_q2_a5":"絶え間ない",
  "patient_adult_knee_pain_q3":"次のうち、あなたの痛みを最もよく表しているのはどれですか？",
  "patient_adult_knee_pain_q3_a1":"痛みもなく、膝も正常、100％の機能を発揮",
  "patient_adult_knee_pain_q3_a2":"激しいスポーツと重い仕事で時折痛み、膝は完全に正常ではなく、いくつかの制限がるが、軽微で耐えられる",
  "patient_adult_knee_pain_q3_a3":"痛みは通常、スポーツ、軽い趣味活動、中程度の労働で生じる。時折、2km以上の歩行や軽作業、立っているときにも起こる。",
  "patient_adult_knee_pain_q3_a4":"軽い趣味スポーツや中程度の仕事、ランニングや重労働、激しいスポーツで痛みが生じることがある。",
  "patient_adult_knee_pain_q3_a5":"2km未満の歩行など簡単な活動で痛みが顕著に現れ、安静にしていれば痛みが和らぐが、スポーツはできない。",
  "patient_adult_knee_pain_q3_a6":"絶え間ない",
  "patient_adult_knee_pain_q4":"痛みがある場合、それはどれほど深刻ですか？",
  "patient_adult_knee_pain_q4_a1":"なし",
  "patient_adult_knee_pain_q4_a2":"とても軽い",
  "patient_adult_knee_pain_q4_a3":"少し",
  "patient_adult_knee_pain_q4_a4":"中程度",
  "patient_adult_knee_pain_q4_a5":"深刻",
  "patient_adult_knee_pain_q5":"膝の痛みは日常の活動にいくら干渉しましたか？",
  "patient_adult_knee_pain_q5_a1":"全くない",
  "patient_adult_knee_pain_q5_a2":"少し",
  "patient_adult_knee_pain_q5_a3":"中程度",
  "patient_adult_knee_pain_q5_a4":"とても",
  "patient_adult_knee_pain_q5_a5":"完全に",
  "patient_adult_knee_pain_instruction":"次の活動中にどのくらいの膝の痛みを経験しましたか：",
  "patient_adult_knee_pain_instruction_0":"なし",
  "patient_adult_knee_pain_instruction_1":"少し",
  "patient_adult_knee_pain_instruction_2":"中程度",
  "patient_adult_knee_pain_instruction_3":"深刻",
  "patient_adult_knee_pain_instruction_4":"極度に",
  "patient_adult_knee_pain_q6":"膝のひねり／回転",
  "patient_adult_knee_pain_q6_a1":"なし",
  "patient_adult_knee_pain_q6_a2":"少し",
  "patient_adult_knee_pain_q6_a3":"中程度",
  "patient_adult_knee_pain_q6_a4":"深刻",
  "patient_adult_knee_pain_q6_a5":"極度に",
  "patient_adult_knee_pain_q7":"膝を完全に伸ばす",
  "patient_adult_knee_pain_q7_a1":"なし",
  "patient_adult_knee_pain_q7_a2":"少し",
  "patient_adult_knee_pain_q7_a3":"中程度",
  "patient_adult_knee_pain_q7_a4":"深刻",
  "patient_adult_knee_pain_q7_a5":"極度に",
  "patient_adult_knee_pain_q8":"膝を完全に曲げる",
  "patient_adult_knee_pain_q8_a1":"なし",
  "patient_adult_knee_pain_q8_a2":"少し",
  "patient_adult_knee_pain_q8_a3":"中程度",
  "patient_adult_knee_pain_q8_a4":"深刻",
  "patient_adult_knee_pain_q8_a5":"極度に",
  "patient_adult_knee_pain_q9":"平らな表面を歩く",
  "patient_adult_knee_pain_q9_a1":"なし",
  "patient_adult_knee_pain_q9_a2":"少し",
  "patient_adult_knee_pain_q9_a3":"中程度",
  "patient_adult_knee_pain_q9_a4":"深刻",
  "patient_adult_knee_pain_q9_a5":"極度に",
  "patient_adult_knee_pain_q10":"膝の痛みが悪化するまでどのくらい歩くことができますか？ (杖を使用するかどうかにかかわらず）",
  "patient_adult_knee_pain_q10_a1":"30分以上",
  "patient_adult_knee_pain_q10_a2":"16〜30分",
  "patient_adult_knee_pain_q10_a3":"5〜15分",
  "patient_adult_knee_pain_q10_a4":"家の周りだけ",
  "patient_adult_knee_pain_q10_a5":"全く歩けない／歩行時の痛みが強い",
  "patient_adult_knee_pain_q11":"階段を上下する",
  "patient_adult_knee_pain_q11_a1":"なし",
  "patient_adult_knee_pain_q11_a2":"少し",
  "patient_adult_knee_pain_q11_a3":"中程度",
  "patient_adult_knee_pain_q11_a4":"深刻",
  "patient_adult_knee_pain_q11_a5":"極度に",
  "patient_adult_knee_pain_q12":"夜のベッドで",
  "patient_adult_knee_pain_q12_a1":"なし",
  "patient_adult_knee_pain_q12_a2":"少し",
  "patient_adult_knee_pain_q12_a3":"中程度",
  "patient_adult_knee_pain_q12_a4":"深刻",
  "patient_adult_knee_pain_q12_a5":"極度に",
  "patient_adult_knee_pain_q13":"夜のベッドで膝の痛みに悩まされている頻度はどれくらいですか？",
  "patient_adult_knee_pain_q13_a1":"なし",
  "patient_adult_knee_pain_q13_a2":"一夜か二夜",
  "patient_adult_knee_pain_q13_a3":"いくつかの夜",
  "patient_adult_knee_pain_q13_a4":"ほとんどの夜",
  "patient_adult_knee_pain_q13_a5":"毎晩",
  "patient_adult_knee_pain_q14":"座っている、または寝ている",
  "patient_adult_knee_pain_q14_a1":"なし",
  "patient_adult_knee_pain_q14_a2":"少し",
  "patient_adult_knee_pain_q14_a3":"中程度",
  "patient_adult_knee_pain_q14_a4":"深刻",
  "patient_adult_knee_pain_q14_a5":"極度に",
  "patient_adult_knee_pain_q15":"まっすぐ立つ",
  "patient_adult_knee_pain_q15_a1":"なし",
  "patient_adult_knee_pain_q15_a2":"少し",
  "patient_adult_knee_pain_q15_a3":"中程度",
  "patient_adult_knee_pain_q15_a4":"深刻",
  "patient_adult_knee_pain_q15_a5":"極度に",
  "patient_adult_knee_pain_q16":"椅子から立ち上がっているとき",
  "patient_adult_knee_pain_q16_a1":"なし",
  "patient_adult_knee_pain_q16_a2":"少し",
  "patient_adult_knee_pain_q16_a3":"中程度",
  "patient_adult_knee_pain_q16_a4":"深刻",
  "patient_adult_knee_pain_q16_a5":"極度に",
  "patient_adult_knee_other_symptoms":"その他の症状",
  "patient_adult_knee_other_symptoms_instruction1":"膝の症状に対する最良の答えを選択してください。",
  "patient_adult_knee_other_symptoms_instruction2":"質問に答える方法がわからない場合は、できる限り最良の答えをお願いします。",
  "patient_adult_knee_other_symptoms_instruction3":"次のことはありますか？",
  "patient_adult_knee_other_symptoms_instruction_0":"なし",
  "patient_adult_knee_other_symptoms_instruction_1":"少し",
  "patient_adult_knee_other_symptoms_instruction_2":"中程度",
  "patient_adult_knee_other_symptoms_instruction_3":"深刻",
  "patient_adult_knee_other_symptoms_instruction_4":"極度に",
  "patient_adult_knee_other_symptoms_q1":"腫れ",
  "patient_adult_knee_other_symptoms_q1_a1":"一度もない",
  "patient_adult_knee_other_symptoms_q1_a2":"めったに",
  "patient_adult_knee_other_symptoms_q1_a3":"時々",
  "patient_adult_knee_other_symptoms_q1_a4":"頻繁",
  "patient_adult_knee_other_symptoms_q1_a5":"いつも",
  "patient_adult_knee_other_symptoms_q2":"膝が動くとガリガリ音、カチカチ音やその他の異音がする",
  "patient_adult_knee_other_symptoms_q2_a1":"一度もない",
  "patient_adult_knee_other_symptoms_q2_a2":"めったに",
  "patient_adult_knee_other_symptoms_q2_a3":"時々",
  "patient_adult_knee_other_symptoms_q2_a4":"頻繁",
  "patient_adult_knee_other_symptoms_q2_a5":"いつも",
  "patient_adult_knee_other_symptoms_q3":"膝関節が引っかかっているか動かない",
  "patient_adult_knee_other_symptoms_q3_a1":"一度もない",
  "patient_adult_knee_other_symptoms_q3_a2":"めったに",
  "patient_adult_knee_other_symptoms_q3_a3":"時々",
  "patient_adult_knee_other_symptoms_q3_a4":"頻繁",
  "patient_adult_knee_other_symptoms_q3_a5":"いつも",
  "patient_adult_knee_other_symptoms_q4":"膝がだるい",
  "patient_adult_knee_other_symptoms_q4_a1":"めったに/まったくない",
  "patient_adult_knee_other_symptoms_q4_a2":"時々、または最初は",
  "patient_adult_knee_other_symptoms_q4_a3":"多くの場合、最初だけではない",
  "patient_adult_knee_other_symptoms_q4_a4":"ほとんどの場合",
  "patient_adult_knee_other_symptoms_q4_a5":"いつも",
  "patient_adult_knee_other_symptoms_q5":"過去4週間、または膝を負傷して以来、膝の硬さや腫れはどうでしたか？",
  "patient_adult_knee_other_symptoms_q5_a1":"全くない",
  "patient_adult_knee_other_symptoms_q5_a2":"少し",
  "patient_adult_knee_other_symptoms_q5_a3":"中程度",
  "patient_adult_knee_other_symptoms_q5_a4":"とても",
  "patient_adult_knee_other_symptoms_q5_a5":"極度に",
  "patient_adult_knee_other_symptoms_q6":"膝に大きな腫れが生じることなく行える最高レベルの活動は？",
  "patient_adult_knee_other_symptoms_q6_a1":"バスケットボールやサッカーでのジャンプや回転など、非常に激しい運動",
  "patient_adult_knee_other_symptoms_q6_a2":"重い肉体労働、スキー、テニスなどの激しい運動",
  "patient_adult_knee_other_symptoms_q6_a3":"中程度の肉体労働、ランニング、ジョギングなどの適度な活動",
  "patient_adult_knee_other_symptoms_q6_a4":"散歩、家事、庭仕事などの軽い活動",
  "patient_adult_knee_other_symptoms_q6_a5":"膝の腫れのため、上記の活動を実行できない",
  "patient_adult_knee_other_symptoms_q7":"あなたの関節のこりはどれほど深刻ですか：",
  "patient_adult_knee_other_symptoms_q7_0":"なし",
  "patient_adult_knee_other_symptoms_q7_1":"少し",
  "patient_adult_knee_other_symptoms_q7_2":"中程度",
  "patient_adult_knee_other_symptoms_q7_3":"深刻",
  "patient_adult_knee_other_symptoms_q7_4":"極度に",
  "patient_adult_knee_other_symptoms_q7_q1":"朝最初に目覚めた直後は？",
  "patient_adult_knee_other_symptoms_q7_q1_a1":"なし",
  "patient_adult_knee_other_symptoms_q7_q1_a2":"少し",
  "patient_adult_knee_other_symptoms_q7_q1_a3":"中程度",
  "patient_adult_knee_other_symptoms_q7_q1_a4":"深刻",
  "patient_adult_knee_other_symptoms_q7_q1_a5":"極度に",
  "patient_adult_knee_other_symptoms_q7_q2":"午後/夕方に座ったり横になったりした後は？",
  "patient_adult_knee_other_symptoms_q7_q2_a1":"なし",
  "patient_adult_knee_other_symptoms_q7_q2_a2":"少し",
  "patient_adult_knee_other_symptoms_q7_q2_a3":"中程度",
  "patient_adult_knee_other_symptoms_q7_q2_a4":"深刻",
  "patient_adult_knee_other_symptoms_q7_q2_a5":"極度に",
  "patient_adult_knee_other_symptoms_q8":"膝が脱力することなく行える最高レベルの運動は？",
  "patient_adult_knee_other_symptoms_q8_a1":"脱力はしない",
  "patient_adult_knee_other_symptoms_q8_a2":"激しい運動や重い仕事のため脱力することもあり、すべてのスポーツに参加可能。でも若干の保護や制限がある。陸上競技やその他の激しい運動でまれに起こる。",
  "patient_adult_knee_other_symptoms_q8_a3":"陸上競技やその他の激しい運動中に頻繁に起こる。軽いスポーツや中程度の仕事では、時折脱力する。他の身体部位を使った代替動作ができるが、激しい運動、スポーツ、重労働は制限される。 急にカットしたり、ひねったりすることができない。",
  "patient_adult_knee_other_symptoms_q8_a4":"スポーツや中程度の仕事は脱力で制限されるが、歩行や軽作業で起こることはまれである（年に3回程度）。日常生活では時々起こる",
  "patient_adult_knee_other_symptoms_q8_a5":"簡単な歩行活動や軽作業でも脱力する。月1回程度で発生し、保護が必要。日常生活ではしばしば発生する。",
  "patient_adult_knee_other_symptoms_q8_a6":"簡単な歩行動作にも大きな問題があり、一歩一歩の動作で脱力する。脱力せずに歩いたり、体をひねったりすることができない。",
  "patient_adult_knee_other_symptoms_q9":"歩くときに何かサポートを使用していますか？",
  "patient_adult_knee_other_symptoms_q9_a1":"なし",
  "patient_adult_knee_other_symptoms_q9_a2":"ステッキまたは松葉杖",
  "patient_adult_knee_other_symptoms_q9_a3":"体重を支えることは不可能",
  "patient_adult_knee_function":"機能",
  "patient_adult_knee_function_instruction":"最近1週間で膝のため、次の活動で経験した困難の程度を示してください。",
  "patient_adult_knee_function_instruction_0":"困難なし",
  "patient_adult_knee_function_instruction_1":"困難が少しある",
  "patient_adult_knee_function_instruction_2":"いくつかの困難がある",
  "patient_adult_knee_function_instruction_3":"極度の困難",
  "patient_adult_knee_function_instruction_4":"できない",
  "patient_adult_knee_function_note":"注：特定の活動をしない場合、もしそれをしなければならなくなったら自分の膝がどう感じるかを想像してください",
  "patient_adult_knee_function_q1":"階段を上る",
  "patient_adult_knee_function_q1_a1":"困難なし",
  "patient_adult_knee_function_q1_a2":"困難が少しある",
  "patient_adult_knee_function_q1_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q1_a4":"極度の困難",
  "patient_adult_knee_function_q1_a5":"できない",
  "patient_adult_knee_function_q2":"階段を下る",
  "patient_adult_knee_function_q2_a1":"困難なし",
  "patient_adult_knee_function_q2_a2":"困難が少しある",
  "patient_adult_knee_function_q2_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q2_a4":"極度の困難",
  "patient_adult_knee_function_q2_a5":"できない",
  "patient_adult_knee_function_q3":"膝の前で膝をつく",
  "patient_adult_knee_function_q3_a1":"困難なし",
  "patient_adult_knee_function_q3_a2":"困難が少しある",
  "patient_adult_knee_function_q3_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q3_a4":"極度の困難",
  "patient_adult_knee_function_q3_a5":"できない",
  "patient_adult_knee_function_q4":"膝を伸ばす",
  "patient_adult_knee_function_q4_a1":"困難なし",
  "patient_adult_knee_function_q4_a2":"困難が少しある",
  "patient_adult_knee_function_q4_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q4_a4":"極度の困難",
  "patient_adult_knee_function_q4_a5":"できない",
  "patient_adult_knee_function_q5":"膝を曲げる",
  "patient_adult_knee_function_q5_a1":"困難なし",
  "patient_adult_knee_function_q5_a2":"困難が少しある",
  "patient_adult_knee_function_q5_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q5_a4":"極度の困難",
  "patient_adult_knee_function_q5_a5":"できない",
  "patient_adult_knee_function_q6":"しゃがむ",
  "patient_adult_knee_function_q6_a1":"困難なし",
  "patient_adult_knee_function_q6_a2":"困難が少しある",
  "patient_adult_knee_function_q6_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q6_a4":"極度の困難",
  "patient_adult_knee_function_q6_a5":"できない",
  "patient_adult_knee_function_q7":"膝を曲げて座っている",
  "patient_adult_knee_function_q7_a1":"困難なし",
  "patient_adult_knee_function_q7_a2":"困難が少しある",
  "patient_adult_knee_function_q7_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q7_a4":"極度の困難",
  "patient_adult_knee_function_q7_a5":"できない",
  "patient_adult_knee_function_q8":"椅子から立ち上がる",
  "patient_adult_knee_function_q8_a1":"困難なし",
  "patient_adult_knee_function_q8_a2":"困難が少しある",
  "patient_adult_knee_function_q8_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q8_a4":"極度の困難",
  "patient_adult_knee_function_q8_a5":"できない",
  "patient_adult_knee_function_q9":"ベッドから立ち上がる",
  "patient_adult_knee_function_q9_a1":"困難なし",
  "patient_adult_knee_function_q9_a2":"困難が少しある",
  "patient_adult_knee_function_q9_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q9_a4":"極度の困難",
  "patient_adult_knee_function_q9_a5":"できない",
  "patient_adult_knee_function_q10":"ベッドに横たわっている（ひっくり返り、膝の姿勢を維持する）",
  "patient_adult_knee_function_q10_a1":"困難なし",
  "patient_adult_knee_function_q10_a2":"困難が少しある",
  "patient_adult_knee_function_q10_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q10_a4":"極度の困難",
  "patient_adult_knee_function_q10_a5":"できない",
  "patient_adult_knee_function_q11":"床に屈む／物を拾う",
  "patient_adult_knee_function_q11_a1":"困難なし",
  "patient_adult_knee_function_q11_a2":"困難が少しある",
  "patient_adult_knee_function_q11_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q11_a4":"極度の困難",
  "patient_adult_knee_function_q11_a5":"できない",
  "patient_adult_knee_function_q12":"靴下/ストッキングを着用",
  "patient_adult_knee_function_q12_a1":"困難なし",
  "patient_adult_knee_function_q12_a2":"困難が少しある",
  "patient_adult_knee_function_q12_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q12_a4":"極度の困難",
  "patient_adult_knee_function_q12_a5":"できない",
  "patient_adult_knee_function_q13":"靴下/ストッキングを脱ぐ",
  "patient_adult_knee_function_q13_a1":"困難なし",
  "patient_adult_knee_function_q13_a2":"困難が少しある",
  "patient_adult_knee_function_q13_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q13_a4":"極度の困難",
  "patient_adult_knee_function_q13_a5":"できない",
  "patient_adult_knee_function_q14":"立っている",
  "patient_adult_knee_function_q14_a1":"困難なし",
  "patient_adult_knee_function_q14_a2":"困難が少しある",
  "patient_adult_knee_function_q14_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q14_a4":"極度の困難",
  "patient_adult_knee_function_q14_a5":"できない",
  "patient_adult_knee_function_q15":"歩く",
  "patient_adult_knee_function_q15_a1":"困難なし",
  "patient_adult_knee_function_q15_a2":"困難が少しある",
  "patient_adult_knee_function_q15_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q15_a4":"極度の困難",
  "patient_adult_knee_function_q15_a5":"できない",
  "patient_adult_knee_function_q16":"まっすぐに走る",
  "patient_adult_knee_function_q16_a1":"困難なし",
  "patient_adult_knee_function_q16_a2":"困難が少しある",
  "patient_adult_knee_function_q16_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q16_a4":"極度の困難",
  "patient_adult_knee_function_q16_a5":"できない",
  "patient_adult_knee_function_q17":"患部の脚でジャンプして着陸する",
  "patient_adult_knee_function_q17_a1":"困難なし",
  "patient_adult_knee_function_q17_a2":"困難が少しある",
  "patient_adult_knee_function_q17_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q17_a4":"極度の困難",
  "patient_adult_knee_function_q17_a5":"できない",
  "patient_adult_knee_function_q18":"迅速な停止と開始",
  "patient_adult_knee_function_q18_a1":"困難なし",
  "patient_adult_knee_function_q18_a2":"困難が少しある",
  "patient_adult_knee_function_q18_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q18_a4":"極度の困難",
  "patient_adult_knee_function_q18_a5":"できない",
  "patient_adult_knee_function_q19":"負傷した膝をひねる／回す",
  "patient_adult_knee_function_q19_a1":"困難なし",
  "patient_adult_knee_function_q19_a2":"困難が少しある",
  "patient_adult_knee_function_q19_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q19_a4":"極度の困難",
  "patient_adult_knee_function_q19_a5":"できない",
  "patient_adult_knee_function_q20":"車の乗り降りや公共交通機関の利用",
  "patient_adult_knee_function_q20_a1":"困難なし",
  "patient_adult_knee_function_q20_a2":"困難が少しある",
  "patient_adult_knee_function_q20_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q20_a4":"極度の困難",
  "patient_adult_knee_function_q20_a5":"できない",
  "patient_adult_knee_function_q21":"お風呂に出入りする",
  "patient_adult_knee_function_q21_a1":"困難なし",
  "patient_adult_knee_function_q21_a2":"困難が少しある",
  "patient_adult_knee_function_q21_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q21_a4":"極度の困難",
  "patient_adult_knee_function_q21_a5":"できない",
  "patient_adult_knee_function_q22":"トイレの乗り降り",
  "patient_adult_knee_function_q22_a1":"困難なし",
  "patient_adult_knee_function_q22_a2":"困難が少しある",
  "patient_adult_knee_function_q22_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q22_a4":"極度の困難",
  "patient_adult_knee_function_q22_a5":"できない",
  "patient_adult_knee_function_q23":"自分を洗って乾かす",
  "patient_adult_knee_function_q23_a1":"困難なし",
  "patient_adult_knee_function_q23_a2":"困難が少しある",
  "patient_adult_knee_function_q23_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q23_a4":"極度の困難",
  "patient_adult_knee_function_q23_a5":"できない",
  "patient_adult_knee_function_q24":"家庭用品の買い物",
  "patient_adult_knee_function_q24_a1":"困難なし",
  "patient_adult_knee_function_q24_a2":"困難が少しある",
  "patient_adult_knee_function_q24_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q24_a4":"極度の困難",
  "patient_adult_knee_function_q24_a5":"できない",
  "patient_adult_knee_function_q25":"重い家事労働（重い箱の移動、床拭きなど）",
  "patient_adult_knee_function_q25_a1":"困難なし",
  "patient_adult_knee_function_q25_a2":"困難が少しある",
  "patient_adult_knee_function_q25_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q25_a4":"極度の困難",
  "patient_adult_knee_function_q25_a5":"できない",
  "patient_adult_knee_function_q26":"軽い家事（料理、掃除など）",
  "patient_adult_knee_function_q26_a1":"困難なし",
  "patient_adult_knee_function_q26_a2":"困難が少しある",
  "patient_adult_knee_function_q26_a3":"いくつかの困難がある",
  "patient_adult_knee_function_q26_a4":"極度の困難",
  "patient_adult_knee_function_q26_a5":"できない",
  "patient_adult_knee_function_q27":"膝の現在の機能を0〜10のスケールでどのように評価しますか？",
  "patient_adult_knee_function_q28":"10は正常を意味し、0はスポーツを含む日常の活動を実行できないことを意味します。",
  "patient_adult_knee_function_q28_a1":"最悪",
  "patient_adult_knee_function_q28_a2":"最高",
  "patient_adult_knee_function_q29":"定期的に参加できる最高レベルの活動は何ですか？",
  "patient_adult_knee_function_q29_a1":"バスケットボールやサッカーでのジャンプや回転など、非常に激しい運動",
  "patient_adult_knee_function_q29_a2":"重い肉体労働、スキー、テニスなどの激しい運動",
  "patient_adult_knee_function_q29_a3":"中程度の肉体労働、ランニング、ジョギングなどの適度な活動",
  "patient_adult_knee_function_q29_a4":"散歩、家事、庭仕事などの軽い活動",
  "patient_adult_knee_function_q29_a5":"膝のため、上記の活動を実行できない",
  "patient_adult_knee_function_q30":"参加できる最高レベルの活動を指定してください",
  "patient_adult_knee_function_q30_q1":"膝の怪我/問題の前",
  "patient_adult_knee_function_q30_q1_a1":"競争的な活動",
  "patient_adult_knee_function_q30_q1_a2":"レジャー、フィットネス",
  "patient_adult_knee_function_q30_q1_a3":"スポーツなしで働くのみ",
  "patient_adult_knee_function_q30_q1_a4":"日常生活の活動のみ",
  "patient_adult_knee_function_q30_q1_a5":"健康問題のために病気休暇または障害年金をとる",
  "patient_adult_knee_function_q30_q1_a1_o1":"世界レベル、オリンピックレベル、またはプロとしてプレーする競技スポーツ",
  "patient_adult_knee_function_q30_q1_a1_o2":"陸上競技、ラケット／ボールスポーツ、体操、ボート、スキー、武道など、国内または国際レベルの競技スポーツ",
  "patient_adult_knee_function_q30_q1_a1_o3":"ウォータースポーツ、サイクリング、ホッケー、カーリング、乗馬／ロデオなど、国内または国際レベルの競技スポーツ",
  "patient_adult_knee_function_q30_q1_a2_o1":"ランニング、ボール／ラケットスポーツ、ウェイトトレーニング、カーリング、ボート、ゴルフ、ヨガ、体操などの趣味的なスポーツを週5回以上行う。",
  "patient_adult_knee_function_q30_q1_a2_o2":"水泳、ウォータースポーツ、スキー、ホッケー、ローラーブレード、自転車、乗馬またはハイキング、週5回以上",
  "patient_adult_knee_function_q30_q1_a2_o3":"趣味的なスポーツ、週2回以上",
  "patient_adult_knee_function_q30_q1_a2_o4":"趣味的なスポーツ、週1回以下",
  "patient_adult_knee_function_q30_q1_a2_o5":"趣味的なスポーツ、月1回未満",
  "patient_adult_knee_function_q30_q1_a3_o1":"建築のような重労働",
  "patient_adult_knee_function_q30_q1_a3_o2":"トラック運転などの中度の重労働",
  "patient_adult_knee_function_q30_q1_a3_o3":"看護などの軽い労働",
  "patient_adult_knee_function_q30_q1_a3_o4":"秘書業務やコンピューター作業など、座りっぱなしの仕事",
  "patient_adult_knee_function_q30_q1_a4_o1":"ガーデニング、複数の階段の昇り降り、荷物の運搬、荷物の押し引きなどの日常活動、遅刻した場合のランニング能力",
  "patient_adult_knee_function_q30_q1_a4_o2":"家の掃除、1～2続きの階段の昇り降り、軽い荷物を持っての歩行などの日常活動",
  "patient_adult_knee_function_q30_q1_a4_o3":"日常生活が制限される（階段を使わない、荷物を運べないなど）。",
  "patient_adult_knee_function_q30_q2":"現在の状況",
  "patient_adult_knee_function_q30_q2_a1":"競争的な活動",
  "patient_adult_knee_function_q30_q2_a2":"レジャー、フィットネス",
  "patient_adult_knee_function_q30_q2_a3":"スポーツなしで働くのみ",
  "patient_adult_knee_function_q30_q2_a4":"日常生活の活動のみ",
  "patient_adult_knee_function_q30_q2_a5":"健康問題のために病気休暇または障害年金をとる",
  "patient_adult_knee_function_q30_q2_a1_o1":"世界レベル、オリンピックレベル、またはプロとしてプレーする競技スポーツ",
  "patient_adult_knee_function_q30_q2_a1_o2":"陸上競技、ラケット／ボールスポーツ、体操、ボート、スキー、武道など、国内または国際レベルの競技スポーツ",
  "patient_adult_knee_function_q30_q2_a1_o3":"ウォータースポーツ、サイクリング、ホッケー、カーリング、乗馬／ロデオなど、国内または国際レベルの競技スポーツ",
  "patient_adult_knee_function_q30_q2_a2_o1":"ランニング、ボール／ラケットスポーツ、ウェイトトレーニング、カーリング、ボート、ゴルフ、ヨガ、体操などの趣味的なスポーツを週5回以上行う。",
  "patient_adult_knee_function_q30_q2_a2_o2":"水泳、ウォータースポーツ、スキー、ホッケー、ローラーブレード、自転車、乗馬またはハイキング、週5回以上",
  "patient_adult_knee_function_q30_q2_a2_o3":"趣味的なスポーツ、週2回以上",
  "patient_adult_knee_function_q30_q2_a2_o4":"趣味的なスポーツ、週1回以下",
  "patient_adult_knee_function_q30_q2_a2_o5":"趣味的なスポーツ、月1回未満",
  "patient_adult_knee_function_q30_q2_a3_o1":"建築のような重労働",
  "patient_adult_knee_function_q30_q2_a3_o2":"トラック運転などの中度の重労働",
  "patient_adult_knee_function_q30_q2_a3_o3":"看護などの軽い労働",
  "patient_adult_knee_function_q30_q2_a3_o4":"秘書業務やコンピューター作業など、座りっぱなしの仕事",
  "patient_adult_knee_function_q30_q2_a4_o1":"ガーデニング、複数の階段の昇り降り、荷物の運搬、荷物の押し引きなどの日常活動、遅刻した場合のランニング能力",
  "patient_adult_knee_function_q30_q2_a4_o2":"家の掃除、1～2続きの階段の昇り降り、軽い荷物を持っての歩行などの日常活動",
  "patient_adult_knee_function_q30_q2_a4_o3":"日常生活が制限される（階段を使わない、荷物を運べないなど）。",
  "patient_adult_knee_function_q31":"膝頭がずれたり、ずれたように感じたりすることはありますか？",
  "patient_adult_knee_function_q31_a1":"いいえ",
  "patient_adult_knee_function_q31_a2":"はい",
  "patient_adult_knee_patellofemoral_instability":"膝蓋大腿部の不安定性",
  "patient_adult_knee_patellofemoral_instability_instruction":"以下の各活動をしているときに、膝頭が関節から「飛び出る」ように感じたり、不安定に感じたりする頻度について、最も適切なものを選んでください。",
  "patient_adult_knee_patellofemoral_instability_instruction_0":"一度もない",
  "patient_adult_knee_patellofemoral_instability_instruction_1":"めったに",
  "patient_adult_knee_patellofemoral_instability_instruction_2":"時々",
  "patient_adult_knee_patellofemoral_instability_instruction_3":"頻繁",
  "patient_adult_knee_patellofemoral_instability_instruction_4":"いつも",
  "patient_adult_knee_patellofemoral_instability_q1":"スポーツ/ゲーム中に体をひねる/方向を変える",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q1_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q1_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q1_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q1_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q1_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q2":"走るときの方向転換",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q2_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q2_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q2_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q2_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q2_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q3":"不規則な路面での直線走行",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q3_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q3_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q3_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q3_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q3_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q4":"滑りやすい、濡れた、または凍った地面を歩く",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q4_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q4_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q4_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q4_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q4_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q5":"横に走る",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q5_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q5_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q5_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q5_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q5_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q6":"飛び跳ねる",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q6_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q6_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q6_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q6_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q6_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q7":"ジャンプ",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q7_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q7_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q7_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q7_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q7_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q8":"平坦な路面での直線走行",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q8_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q8_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q8_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q8_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q8_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q9":"階段を下る",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q9_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q9_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q9_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q9_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q9_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q10":"しゃがむ",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q10_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q10_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q10_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q10_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q10_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q11":"膝をつく",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q11_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q11_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q11_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q11_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q11_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q12":"不規則な路面を直線的に歩く",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q12_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q12_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q12_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q12_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q12_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q13":"階段を上る",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q13_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q13_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q13_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q13_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q13_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q14":"高い段差を上ったり、乗り越えたりする",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q14_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q14_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q14_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q14_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q14_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q15":"座っている",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q15_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q15_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q15_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q15_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q15_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q16":"平坦な路面を直線で歩く",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q16_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q16_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q16_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q16_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q16_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q17":"車に出入りする",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q17_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q17_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q17_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q17_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q17_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q18":"スーパーマーケットの通路で重いカートを回す",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q18_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q18_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q18_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q18_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q18_a5":"いつも",
  "patient_adult_knee_patellofemoral_instability_q19":"肩越しに目を向ける",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do":"こんな活動はしない",
  "patient_adult_knee_patellofemoral_instability_q19_a1":"一度もない",
  "patient_adult_knee_patellofemoral_instability_q19_a2":"めったに",
  "patient_adult_knee_patellofemoral_instability_q19_a3":"時々",
  "patient_adult_knee_patellofemoral_instability_q19_a4":"頻繁",
  "patient_adult_knee_patellofemoral_instability_q19_a5":"いつも",
  "patient_adult_knee_quality_of_life":"生活の質",
  "patient_adult_knee_quality_of_life_instruction":"以下は、膝の問題に感じる社会的、感情的、ライフスタイルに関する質問です。膝について通常感じていることを考えてください。",
  "patient_adult_knee_quality_of_life_q1":"膝の問題を意識する頻度は？",
  "patient_adult_knee_quality_of_life_q1_a1":"一度もない",
  "patient_adult_knee_quality_of_life_q1_a2":"毎月",
  "patient_adult_knee_quality_of_life_q1_a3":"毎週",
  "patient_adult_knee_quality_of_life_q1_a4":"毎日",
  "patient_adult_knee_quality_of_life_q1_a5":"常に",
  "patient_adult_knee_quality_of_life_q2":"膝の問題を避けるためにライフスタイルを変えましたか？",
  "patient_adult_knee_quality_of_life_q2_a1":"全くない",
  "patient_adult_knee_quality_of_life_q2_a2":"少し",
  "patient_adult_knee_quality_of_life_q2_a3":"中程度",
  "patient_adult_knee_quality_of_life_q2_a4":"ひどく",
  "patient_adult_knee_quality_of_life_q2_a5":"完全に",
  "patient_adult_knee_quality_of_life_q3":"膝に自信がないため、どれだけ心配していますか？",
  "patient_adult_knee_quality_of_life_q3_a1":"全くない",
  "patient_adult_knee_quality_of_life_q3_a2":"少し",
  "patient_adult_knee_quality_of_life_q3_a3":"中程度",
  "patient_adult_knee_quality_of_life_q3_a4":"ひどく",
  "patient_adult_knee_quality_of_life_q3_a5":"極度に",
  "patient_adult_knee_quality_of_life_q4":"一般的に、膝にどの程度支障がありますか？",
  "patient_adult_knee_quality_of_life_q4_a1":"なし",
  "patient_adult_knee_quality_of_life_q4_a2":"少し",
  "patient_adult_knee_quality_of_life_q4_a3":"中程度",
  "patient_adult_knee_quality_of_life_q4_a4":"深刻",
  "patient_adult_knee_quality_of_life_q4_a5":"極度に",
  "patient_adult_knee_quality_of_life_q5":"今日は不安/落ち込んでいますか？",
  "patient_adult_knee_quality_of_life_q5_a1":"不安/落ち込んでいない",
  "patient_adult_knee_quality_of_life_q5_a2":"少し",
  "patient_adult_knee_quality_of_life_q5_a3":"中程度",
  "patient_adult_knee_quality_of_life_q5_a4":"ひどく",
  "patient_adult_knee_quality_of_life_q5_a5":"極度に",
  "patient_adult_knee_quality_of_life_q6":"今日の健康はどれくらい良いですか？",
  "patient_adult_knee_quality_of_life_q6_instruction":"100は最高の健康を意味し、0はあなたが想像できる最悪の健康です。",
  "patient_adult_knee_quality_of_life_q6_instruction_a1":"最悪",
  "patient_adult_knee_quality_of_life_q6_instruction_a2":"最高",
  "patient_adult_knee_satisfaction":"満足度",
  "patient_adult_knee_satisfaction_instructions":"膝の手術/治療の結果にどの程度満足していますか？",
  "patient_adult_knee_satisfaction_instructions_1":"非常に不満",
  "patient_adult_knee_satisfaction_instructions_2":"ある程度の不満",
  "patient_adult_knee_satisfaction_instructions_3":"やや満足",
  "patient_adult_knee_satisfaction_instructions_4":"非常に満足",
  "patient_adult_knee_satisfaction_q1":"全体",
  "patient_adult_knee_satisfaction_q1_not_applicable":"適用できない",
  "patient_adult_knee_satisfaction_q1_a1":"非常に不満",
  "patient_adult_knee_satisfaction_q1_a2":"ある程度の不満",
  "patient_adult_knee_satisfaction_q1_a3":"やや満足",
  "patient_adult_knee_satisfaction_q1_a4":"非常に満足",
  "patient_adult_knee_satisfaction_q2":"痛みの改善については？",
  "patient_adult_knee_satisfaction_q2_not_applicable":"適用できない",
  "patient_adult_knee_satisfaction_q2_a1":"非常に不満",
  "patient_adult_knee_satisfaction_q2_a2":"ある程度の不満",
  "patient_adult_knee_satisfaction_q2_a3":"やや満足",
  "patient_adult_knee_satisfaction_q2_a4":"非常に満足",
  "patient_adult_knee_satisfaction_q3":"家事や庭仕事の能力改善について？",
  "patient_adult_knee_satisfaction_q3_not_applicable":"適用できない",
  "patient_adult_knee_satisfaction_q3_a1":"非常に不満",
  "patient_adult_knee_satisfaction_q3_a2":"ある程度の不満",
  "patient_adult_knee_satisfaction_q3_a3":"やや満足",
  "patient_adult_knee_satisfaction_q3_a4":"非常に満足",
  "patient_adult_knee_satisfaction_q4":"レジャー活動の能力改善について？",
  "patient_adult_knee_satisfaction_q4_not_applicable":"適用できない",
  "patient_adult_knee_satisfaction_q4_a1":"非常に不満",
  "patient_adult_knee_satisfaction_q4_a2":"ある程度の不満",
  "patient_adult_knee_satisfaction_q4_a3":"やや満足",
  "patient_adult_knee_satisfaction_q4_a4":"非常に満足",
  "patient_adult_knee_complete":"アンケート終了",
  "patient_adult_knee_complete_text1":"時間をかけてこの調査をご記入いただきありがとうございました。",
  "patient_adult_knee_complete_text2":"[送信]をクリックして完了し、スコアをご確認ください。",
  "patient_adult_knee_complete_text3":"回答は機密に扱われ、レポートは身分を識別されない情報のみを使用します。",
  "patient_adult_knee_results":"結果",
  "patient_adult_knee_results_text1":"以下は、アンケートから計算された痛みと膝の機能スコアです。",
  "patient_adult_knee_results_pain":"痛み",
  "patient_adult_knee_results_function":"機能",
  "patient_adult_knee_results_health_perception":"全体的な健康認識",
  "patient_adult_knee_results_self_evaluation":"膝スコア自己評価",
  "patient_adult_knee_results_text2":"まもなく担当医が参ります。",
  "patient_adolescent_knee_pain":"痛み",
  "patient_adolescent_knee_function_q23":"買い物",
  "patient_adolescent_knee_function_q23_a1":"困難なし",
  "patient_adolescent_knee_function_q23_a2":"困難が少しある",
  "patient_adolescent_knee_function_q23_a3":"いくつかの困難がある",
  "patient_adolescent_knee_function_q23_a4":"極度の困難",
  "patient_adolescent_knee_function_q23_a5":"できない",
  "patient_pedia_knee_pain":"痛み",
  "patient_pedia_knee_pain_q1":"過去4週間、または負傷して以来、負傷した膝の痛み期間はどのくらいですか？",
  "patient_pedia_knee_pain_q1_a1":"痛みなし",
  "patient_pedia_knee_pain_q1_a2":"めったに",
  "patient_pedia_knee_pain_q1_a3":"時々",
  "patient_pedia_knee_pain_q1_a4":"頻繁",
  "patient_pedia_knee_pain_q1_a5":"絶え間ない",
  "patient_pedia_knee_pain_q2":"負傷した膝は今日どれほど痛いですか？",
  "patient_pedia_knee_pain_q2_a1":"まったく痛くない",
  "patient_pedia_knee_pain_q2_a2":"とても痛いので我慢できない",
  "patient_pedia_knee_pain_instructions":"過去7日間で膝の痛みをどのくらい経験しましたか？",
  "patient_pedia_knee_pain_instructions_0":"痛みなし",
  "patient_pedia_knee_pain_instructions_1":"やや痛み",
  "patient_pedia_knee_pain_instructions_2":"多少の痛み",
  "patient_pedia_knee_pain_instructions_3":"多くの痛み",
  "patient_pedia_knee_pain_instructions_4":"極度の痛み",
  "patient_pedia_knee_pain_note":"注：膝の活動に関連する次の質問に答えてください。",
  "patient_pedia_knee_pain_q3":"ねじれ",
  "patient_pedia_knee_pain_q3_a1":"痛みなし",
  "patient_pedia_knee_pain_q3_a2":"やや痛み",
  "patient_pedia_knee_pain_q3_a3":"多少の痛み",
  "patient_pedia_knee_pain_q3_a4":"多くの痛み",
  "patient_pedia_knee_pain_q3_a5":"極度の痛み",
  "patient_pedia_knee_pain_q4":"膝を完全に伸ばす",
  "patient_pedia_knee_pain_q4_a1":"痛みなし",
  "patient_pedia_knee_pain_q4_a2":"やや痛み",
  "patient_pedia_knee_pain_q4_a3":"多少の痛み",
  "patient_pedia_knee_pain_q4_a4":"多くの痛み",
  "patient_pedia_knee_pain_q4_a5":"極度の痛み",
  "patient_pedia_knee_pain_q5":"膝は完全に曲げる",
  "patient_pedia_knee_pain_q5_a1":"痛みなし",
  "patient_pedia_knee_pain_q5_a2":"やや痛み",
  "patient_pedia_knee_pain_q5_a3":"多少の痛み",
  "patient_pedia_knee_pain_q5_a4":"多くの痛み",
  "patient_pedia_knee_pain_q5_a5":"極度の痛み",
  "patient_pedia_knee_pain_q6":"階段を登る",
  "patient_pedia_knee_pain_q6_a1":"痛みなし",
  "patient_pedia_knee_pain_q6_a2":"やや痛み",
  "patient_pedia_knee_pain_q6_a3":"多少の痛み",
  "patient_pedia_knee_pain_q6_a4":"多くの痛み",
  "patient_pedia_knee_pain_q6_a5":"極度の痛み",
  "patient_pedia_knee_pain_q7":"階段を下る",
  "patient_pedia_knee_pain_q7_a1":"痛みなし",
  "patient_pedia_knee_pain_q7_a2":"やや痛み",
  "patient_pedia_knee_pain_q7_a3":"多少の痛み",
  "patient_pedia_knee_pain_q7_a4":"多くの痛み",
  "patient_pedia_knee_pain_q7_a5":"極度の痛み",
  "patient_pedia_knee_pain_q8":"膝を曲げて座っている",
  "patient_pedia_knee_pain_q8_a1":"痛みなし",
  "patient_pedia_knee_pain_q8_a2":"やや痛み",
  "patient_pedia_knee_pain_q8_a3":"多少の痛み",
  "patient_pedia_knee_pain_q8_a4":"多くの痛み",
  "patient_pedia_knee_pain_q8_a5":"極度の痛み",
  "patient_pedia_knee_pain_q9":"いつまでも両足でまっすぐ立っている",
  "patient_pedia_knee_pain_q9_a1":"痛みなし",
  "patient_pedia_knee_pain_q9_a2":"やや痛み",
  "patient_pedia_knee_pain_q9_a3":"多少の痛み",
  "patient_pedia_knee_pain_q9_a4":"多くの痛み",
  "patient_pedia_knee_pain_q9_a5":"極度の痛み",
  "patient_pedia_knee_other_symptoms":"その他の症状",
  "patient_pedia_knee_other_symptoms_instruction1":"どのくらいの頻度で次のことをしましたか：",
  "patient_pedia_knee_other_symptoms_instruction1_0":"一度もない",
  "patient_pedia_knee_other_symptoms_instruction1_1":"めったに",
  "patient_pedia_knee_other_symptoms_instruction1_2":"時々",
  "patient_pedia_knee_other_symptoms_instruction1_3":"頻繁",
  "patient_pedia_knee_other_symptoms_instruction1_4":"いつも",
  "patient_pedia_knee_other_symptoms_q1":"膝が音/騒音を立てる",
  "patient_pedia_knee_other_symptoms_q1_a1":"一度もない",
  "patient_pedia_knee_other_symptoms_q1_a2":"めったに",
  "patient_pedia_knee_other_symptoms_q1_a3":"時々",
  "patient_pedia_knee_other_symptoms_q1_a4":"頻繁",
  "patient_pedia_knee_other_symptoms_q1_a5":"いつも",
  "patient_pedia_knee_other_symptoms_q2":"膝が引っかかっている、または位置に固定される",
  "patient_pedia_knee_other_symptoms_q2_a1":"一度もない",
  "patient_pedia_knee_other_symptoms_q2_a2":"めったに",
  "patient_pedia_knee_other_symptoms_q2_a3":"時々",
  "patient_pedia_knee_other_symptoms_q2_a4":"頻繁",
  "patient_pedia_knee_other_symptoms_q2_a5":"いつも",
  "patient_pedia_knee_other_symptoms_q3":"膝が引っかかっているように感じるが、まだ動かすことができる",
  "patient_pedia_knee_other_symptoms_q3_a1":"一度もない",
  "patient_pedia_knee_other_symptoms_q3_a2":"めったに",
  "patient_pedia_knee_other_symptoms_q3_a3":"時々",
  "patient_pedia_knee_other_symptoms_q3_a4":"頻繁",
  "patient_pedia_knee_other_symptoms_q3_a5":"いつも",
  "patient_pedia_knee_other_symptoms_q4":"膝の腫れ",
  "patient_pedia_knee_other_symptoms_q4_a1":"一度もない",
  "patient_pedia_knee_other_symptoms_q4_a2":"めったに",
  "patient_pedia_knee_other_symptoms_q4_a3":"時々",
  "patient_pedia_knee_other_symptoms_q4_a4":"頻繁",
  "patient_pedia_knee_other_symptoms_q4_a5":"いつも",
  "patient_pedia_knee_other_symptoms_q5":"膝はどのくらい腫れていましたか？",
  "patient_pedia_knee_other_symptoms_q5_a1":"まったく腫れない",
  "patient_pedia_knee_other_symptoms_q5_a2":"少し腫れる",
  "patient_pedia_knee_other_symptoms_q5_a3":"ある程度腫れる",
  "patient_pedia_knee_other_symptoms_q5_a4":"非常に腫れる",
  "patient_pedia_knee_other_symptoms_q5_a5":"極度に腫れる",
  "patient_pedia_knee_other_symptoms_instruction2":"以下の活動をするように頼まれた場合、今日できることは何ですか...",
  "patient_pedia_knee_other_symptoms_instruction2_0":"バスケットボールやサッカーでのジャンプや向きを速く変える回転など、非常に難しい運動",
  "patient_pedia_knee_other_symptoms_instruction2_1":"力仕事、スキー、テニスなどの激しい運動",
  "patient_pedia_knee_other_symptoms_instruction2_2":"早歩きやジョギングのようなハードな運動",
  "patient_pedia_knee_other_symptoms_instruction2_3":"通常の速度で歩くなどの軽い活動",
  "patient_pedia_knee_other_symptoms_instruction2_4":"上記の活動は何もできない",
  "patient_pedia_knee_other_symptoms_q6":"膝をひどく痛めることなく",
  "patient_pedia_knee_other_symptoms_q6_a1":"非常に難しい活動",
  "patient_pedia_knee_other_symptoms_q6_a2":"難しい活動",
  "patient_pedia_knee_other_symptoms_q6_a3":"ある程度の活動",
  "patient_pedia_knee_other_symptoms_q6_a4":"軽い活動",
  "patient_pedia_knee_other_symptoms_q6_a5":"上記のことは何もできない",
  "patient_pedia_knee_other_symptoms_q7":"膝が腫れることなく",
  "patient_pedia_knee_other_symptoms_q7_a1":"非常に難しい活動",
  "patient_pedia_knee_other_symptoms_q7_a2":"難しい活動",
  "patient_pedia_knee_other_symptoms_q7_a3":"ある程度の活動",
  "patient_pedia_knee_other_symptoms_q7_a4":"軽い活動",
  "patient_pedia_knee_other_symptoms_q7_a5":"上記のことは何もできない",
  "patient_pedia_knee_other_symptoms_q8":"膝が体を支えることができないように感じることなく",
  "patient_pedia_knee_other_symptoms_q8_a1":"非常に難しい活動",
  "patient_pedia_knee_other_symptoms_q8_a2":"難しい活動",
  "patient_pedia_knee_other_symptoms_q8_a3":"ある程度の活動",
  "patient_pedia_knee_other_symptoms_q8_a5":"上記のことは何もできない",
  "patient_pedia_knee_other_symptoms_q9":"ほとんどの時間",
  "patient_pedia_knee_other_symptoms_q9_a1":"非常に難しい活動",
  "patient_pedia_knee_other_symptoms_q9_a2":"難しい活動",
  "patient_pedia_knee_other_symptoms_q9_a3":"ある程度の活動",
  "patient_pedia_knee_other_symptoms_q9_a4":"軽い活動",
  "patient_pedia_knee_other_symptoms_q9_a5":"上記のことは何もできない",
  "patient_pedia_knee_function":"機能",
  "patient_pedia_knee_function_instruction":"膝のために次の行動はどれほど大変でしたか？",
  "patient_pedia_knee_function_instruction_0":"困難なし",
  "patient_pedia_knee_function_instruction_1":"少し",
  "patient_pedia_knee_function_instruction_2":"若干",
  "patient_pedia_knee_function_instruction_3":"たくさん",
  "patient_pedia_knee_function_instruction_4":"極度の困難/できない",
  "patient_pedia_knee_function_note":"注：特定の活動をしない場合、もしそれをしなければならなくなったら自分の膝がどう感じるかを想像してください",
  "patient_pedia_knee_function_q1":"階段を上る",
  "patient_pedia_knee_function_q1_a1":"困難なし",
  "patient_pedia_knee_function_q1_a2":"少し",
  "patient_pedia_knee_function_q1_a3":"若干",
  "patient_pedia_knee_function_q1_a4":"たくさん",
  "patient_pedia_knee_function_q1_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q2":"階段を下る",
  "patient_pedia_knee_function_q2_a1":"困難なし",
  "patient_pedia_knee_function_q2_a2":"少し",
  "patient_pedia_knee_function_q2_a3":"若干",
  "patient_pedia_knee_function_q2_a4":"たくさん",
  "patient_pedia_knee_function_q2_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q3":"膝の前で膝をつく",
  "patient_pedia_knee_function_q3_a1":"困難なし",
  "patient_pedia_knee_function_q3_a2":"少し",
  "patient_pedia_knee_function_q3_a3":"若干",
  "patient_pedia_knee_function_q3_a4":"たくさん",
  "patient_pedia_knee_function_q3_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q4":"膝を完全に伸ばす",
  "patient_pedia_knee_function_q4_a1":"困難なし",
  "patient_pedia_knee_function_q4_a2":"少し",
  "patient_pedia_knee_function_q4_a3":"若干",
  "patient_pedia_knee_function_q4_a4":"たくさん",
  "patient_pedia_knee_function_q4_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q5":"膝を完全に曲げる",
  "patient_pedia_knee_function_q5_a1":"困難なし",
  "patient_pedia_knee_function_q5_a2":"少し",
  "patient_pedia_knee_function_q5_a3":"若干",
  "patient_pedia_knee_function_q5_a4":"たくさん",
  "patient_pedia_knee_function_q5_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q6":"朝起きたらすぐに膝を動かす",
  "patient_pedia_knee_function_q6_a1":"困難なし",
  "patient_pedia_knee_function_q6_a2":"少し",
  "patient_pedia_knee_function_q6_a3":"若干",
  "patient_pedia_knee_function_q6_a4":"たくさん",
  "patient_pedia_knee_function_q6_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q7":"しばらく座っていた後、同日の後半に膝を動かす",
  "patient_pedia_knee_function_q7_a1":"困難なし",
  "patient_pedia_knee_function_q7_a2":"少し",
  "patient_pedia_knee_function_q7_a3":"若干",
  "patient_pedia_knee_function_q7_a4":"たくさん",
  "patient_pedia_knee_function_q7_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q8":"野球のキャッチャーのようにしゃがむ",
  "patient_pedia_knee_function_q8_a1":"困難なし",
  "patient_pedia_knee_function_q8_a2":"少し",
  "patient_pedia_knee_function_q8_a3":"若干",
  "patient_pedia_knee_function_q8_a4":"たくさん",
  "patient_pedia_knee_function_q8_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q9":"椅子に座って膝を曲げて床に足を平らにつく",
  "patient_pedia_knee_function_q9_a1":"困難なし",
  "patient_pedia_knee_function_q9_a2":"少し",
  "patient_pedia_knee_function_q9_a3":"若干",
  "patient_pedia_knee_function_q9_a4":"たくさん",
  "patient_pedia_knee_function_q9_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q10":"椅子から立ち上がる",
  "patient_pedia_knee_function_q10_a1":"困難なし",
  "patient_pedia_knee_function_q10_a2":"少し",
  "patient_pedia_knee_function_q10_a3":"若干",
  "patient_pedia_knee_function_q10_a4":"たくさん",
  "patient_pedia_knee_function_q10_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q11":"膝を曲げて、床から物を拾う",
  "patient_pedia_knee_function_q11_a1":"困難なし",
  "patient_pedia_knee_function_q11_a2":"少し",
  "patient_pedia_knee_function_q11_a3":"若干",
  "patient_pedia_knee_function_q11_a4":"たくさん",
  "patient_pedia_knee_function_q11_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q12":"遊びやスポーツの最中に走る",
  "patient_pedia_knee_function_q12_a1":"困難なし",
  "patient_pedia_knee_function_q12_a2":"少し",
  "patient_pedia_knee_function_q12_a3":"若干",
  "patient_pedia_knee_function_q12_a4":"たくさん",
  "patient_pedia_knee_function_q12_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q13":"遊びやスポーツ中のジャンプ",
  "patient_pedia_knee_function_q13_a1":"困難なし",
  "patient_pedia_knee_function_q13_a2":"少し",
  "patient_pedia_knee_function_q13_a3":"若干",
  "patient_pedia_knee_function_q13_a4":"たくさん",
  "patient_pedia_knee_function_q13_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q14":"素早く動き出し、素早く止まる",
  "patient_pedia_knee_function_q14_a1":"困難なし",
  "patient_pedia_knee_function_q14_a2":"少し",
  "patient_pedia_knee_function_q14_a3":"若干",
  "patient_pedia_knee_function_q14_a4":"たくさん",
  "patient_pedia_knee_function_q14_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q15":"プレー中やスポーツ活動中にねじれる/回転する",
  "patient_pedia_knee_function_q15_a1":"困難なし",
  "patient_pedia_knee_function_q15_a2":"少し",
  "patient_pedia_knee_function_q15_a3":"若干",
  "patient_pedia_knee_function_q15_a4":"たくさん",
  "patient_pedia_knee_function_q15_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q16":"不規則な地面で歩いたり走ったりするときバランスを保つ",
  "patient_pedia_knee_function_q16_a1":"困難なし",
  "patient_pedia_knee_function_q16_a2":"少し",
  "patient_pedia_knee_function_q16_a3":"若干",
  "patient_pedia_knee_function_q16_a4":"たくさん",
  "patient_pedia_knee_function_q16_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q17":"スポーツをする",
  "patient_pedia_knee_function_q17_a1":"困難なし",
  "patient_pedia_knee_function_q17_a2":"少し",
  "patient_pedia_knee_function_q17_a3":"若干",
  "patient_pedia_knee_function_q17_a4":"たくさん",
  "patient_pedia_knee_function_q17_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q18":"車に乗る/車から降る",
  "patient_pedia_knee_function_q18_a1":"困難なし",
  "patient_pedia_knee_function_q18_a2":"少し",
  "patient_pedia_knee_function_q18_a3":"若干",
  "patient_pedia_knee_function_q18_a4":"たくさん",
  "patient_pedia_knee_function_q18_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q19":"ベッドから起きる",
  "patient_pedia_knee_function_q19_a1":"困難なし",
  "patient_pedia_knee_function_q19_a2":"少し",
  "patient_pedia_knee_function_q19_a3":"若干",
  "patient_pedia_knee_function_q19_a4":"たくさん",
  "patient_pedia_knee_function_q19_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q20":"ベッドに横たわっているときに膝の姿勢を変える",
  "patient_pedia_knee_function_q20_a1":"困難なし",
  "patient_pedia_knee_function_q20_a2":"少し",
  "patient_pedia_knee_function_q20_a3":"若干",
  "patient_pedia_knee_function_q20_a4":"たくさん",
  "patient_pedia_knee_function_q20_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q21":"風呂/シャワーに入る/出る",
  "patient_pedia_knee_function_q21_a1":"困難なし",
  "patient_pedia_knee_function_q21_a2":"少し",
  "patient_pedia_knee_function_q21_a3":"若干",
  "patient_pedia_knee_function_q21_a4":"たくさん",
  "patient_pedia_knee_function_q21_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q22":"重い荷物やバックパックなどを運ぶ",
  "patient_pedia_knee_function_q22_a1":"困難なし",
  "patient_pedia_knee_function_q22_a2":"少し",
  "patient_pedia_knee_function_q22_a3":"若干",
  "patient_pedia_knee_function_q22_a4":"たくさん",
  "patient_pedia_knee_function_q22_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q23":"部屋の掃除、食器洗い機の出し入れ、ベッドメイキングなど、軽い家事をする。",
  "patient_pedia_knee_function_q23_a1":"困難なし",
  "patient_pedia_knee_function_q23_a2":"少し",
  "patient_pedia_knee_function_q23_a3":"若干",
  "patient_pedia_knee_function_q23_a4":"たくさん",
  "patient_pedia_knee_function_q23_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q24":"登校や校内での歩行（階段の昇降、ドアの開閉、本の持ち運び、休み時間の参加など）",
  "patient_pedia_knee_function_q24_a1":"困難なし",
  "patient_pedia_knee_function_q24_a2":"少し",
  "patient_pedia_knee_function_q24_a3":"若干",
  "patient_pedia_knee_function_q24_a4":"たくさん",
  "patient_pedia_knee_function_q24_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q25":"友人と一緒に何かをする",
  "patient_pedia_knee_function_q25_a1":"困難なし",
  "patient_pedia_knee_function_q25_a2":"少し",
  "patient_pedia_knee_function_q25_a3":"若干",
  "patient_pedia_knee_function_q25_a4":"たくさん",
  "patient_pedia_knee_function_q25_a5":"極度の困難/できない",
  "patient_pedia_knee_function_q26":"怪我の前に膝はどれだけうまく機能しましたか？",
  "patient_pedia_knee_function_q26_instruction":"10は正常を意味し、0はスポーツを含む日常の活動を実行できないことを意味します。",
  "patient_pedia_knee_function_q26_instruction_a1":"何もできなかった",
  "patient_pedia_knee_function_q26_instruction_a2":"自分が望んでいたすべてのことができた",
  "patient_pedia_knee_function_q27":"膝は今どれだけうまく機能していますか？",
  "patient_pedia_knee_function_q27_instruction":"10は正常を意味し、0はスポーツを含む日常の活動を実行できないことを意味します。",
  "patient_pedia_knee_function_q27_instruction_a1":"何もできない",
  "patient_pedia_knee_function_q27_instruction_a2":"自分が望むすべてのことができる",
  "patient_pedia_knee_function_q28":"膝頭がずれたり、ずれたように感じたりすることはありますか？",
  "patient_pedia_knee_function_q28_a1":"いいえ",
  "patient_pedia_knee_function_q28_a2":"はい",
  "patient_pedia_knee_instability":"膝蓋大腿部の不安定性",
  "patient_pedia_knee_instability_instruction":"膝頭が関節から「飛び出す」ように感じたり、不安定に感じたりする頻度について、最も適切な表現は何ですか？",
  "patient_pedia_knee_instability_instruction_0":"一度もない",
  "patient_pedia_knee_instability_instruction_1":"めったに",
  "patient_pedia_knee_instability_instruction_2":"時々",
  "patient_pedia_knee_instability_instruction_3":"頻繁",
  "patient_pedia_knee_instability_instruction_4":"いつも",
  "patient_pedia_knee_instability_note":"注：膝の活動に関連する次の質問に答えてください。",
  "patient_pedia_knee_instability_q1":"スポーツ/ゲーム中に体をひねる/方向を変える",
  "patient_pedia_knee_instability_q1_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q1_a1":"一度もない",
  "patient_pedia_knee_instability_q1_a2":"めったに",
  "patient_pedia_knee_instability_q1_a3":"時々",
  "patient_pedia_knee_instability_q1_a4":"頻繁",
  "patient_pedia_knee_instability_q1_a5":"いつも",
  "patient_pedia_knee_instability_q2":"走るときの方向転換",
  "patient_pedia_knee_instability_q2_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q2_a1":"一度もない",
  "patient_pedia_knee_instability_q2_a2":"めったに",
  "patient_pedia_knee_instability_q2_a3":"時々",
  "patient_pedia_knee_instability_q2_a4":"頻繁",
  "patient_pedia_knee_instability_q2_a5":"いつも",
  "patient_pedia_knee_instability_q3":"不規則な路面での直線走行",
  "patient_pedia_knee_instability_q3_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q3_a1":"一度もない",
  "patient_pedia_knee_instability_q3_a2":"めったに",
  "patient_pedia_knee_instability_q3_a3":"時々",
  "patient_pedia_knee_instability_q3_a4":"頻繁",
  "patient_pedia_knee_instability_q3_a5":"いつも",
  "patient_pedia_knee_instability_q4":"滑りやすい、濡れた、または凍った地面を歩く",
  "patient_pedia_knee_instability_q4_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q4_a1":"一度もない",
  "patient_pedia_knee_instability_q4_a2":"めったに",
  "patient_pedia_knee_instability_q4_a3":"時々",
  "patient_pedia_knee_instability_q4_a4":"頻繁",
  "patient_pedia_knee_instability_q4_a5":"いつも",
  "patient_pedia_knee_instability_q5":"横に走る",
  "patient_pedia_knee_instability_q5_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q5_a1":"一度もない",
  "patient_pedia_knee_instability_q5_a2":"めったに",
  "patient_pedia_knee_instability_q5_a3":"時々",
  "patient_pedia_knee_instability_q5_a4":"頻繁",
  "patient_pedia_knee_instability_q5_a5":"いつも",
  "patient_pedia_knee_instability_q6":"飛び跳ねる",
  "patient_pedia_knee_instability_q6_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q6_a1":"一度もない",
  "patient_pedia_knee_instability_q6_a2":"めったに",
  "patient_pedia_knee_instability_q6_a3":"時々",
  "patient_pedia_knee_instability_q6_a4":"頻繁",
  "patient_pedia_knee_instability_q6_a5":"いつも",
  "patient_pedia_knee_instability_q7":"ジャンプ",
  "patient_pedia_knee_instability_q7_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q7_a1":"一度もない",
  "patient_pedia_knee_instability_q7_a2":"めったに",
  "patient_pedia_knee_instability_q7_a3":"時々",
  "patient_pedia_knee_instability_q7_a4":"頻繁",
  "patient_pedia_knee_instability_q7_a5":"いつも",
  "patient_pedia_knee_instability_q8":"平坦な路面での直線走行",
  "patient_pedia_knee_instability_q8_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q8_a1":"一度もない",
  "patient_pedia_knee_instability_q8_a2":"めったに",
  "patient_pedia_knee_instability_q8_a3":"時々",
  "patient_pedia_knee_instability_q8_a4":"頻繁",
  "patient_pedia_knee_instability_q8_a5":"いつも",
  "patient_pedia_knee_instability_q9":"階段を下る",
  "patient_pedia_knee_instability_q9_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q9_a1":"一度もない",
  "patient_pedia_knee_instability_q9_a2":"めったに",
  "patient_pedia_knee_instability_q9_a3":"時々",
  "patient_pedia_knee_instability_q9_a4":"頻繁",
  "patient_pedia_knee_instability_q9_a5":"いつも",
  "patient_pedia_knee_instability_q10":"しゃがむ",
  "patient_pedia_knee_instability_q10_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q10_a1":"一度もない",
  "patient_pedia_knee_instability_q10_a2":"めったに",
  "patient_pedia_knee_instability_q10_a3":"時々",
  "patient_pedia_knee_instability_q10_a4":"頻繁",
  "patient_pedia_knee_instability_q10_a5":"いつも",
  "patient_pedia_knee_instability_q11":"膝をつく",
  "patient_pedia_knee_instability_q11_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q11_a1":"一度もない",
  "patient_pedia_knee_instability_q11_a2":"めったに",
  "patient_pedia_knee_instability_q11_a3":"時々",
  "patient_pedia_knee_instability_q11_a4":"頻繁",
  "patient_pedia_knee_instability_q11_a5":"いつも",
  "patient_pedia_knee_instability_q12":"不規則な路面を直線的に歩く",
  "patient_pedia_knee_instability_q12_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q12_a1":"一度もない",
  "patient_pedia_knee_instability_q12_a2":"めったに",
  "patient_pedia_knee_instability_q12_a3":"時々",
  "patient_pedia_knee_instability_q12_a4":"頻繁",
  "patient_pedia_knee_instability_q12_a5":"いつも",
  "patient_pedia_knee_instability_q13":"階段を上る",
  "patient_pedia_knee_instability_q13_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q13_a1":"一度もない",
  "patient_pedia_knee_instability_q13_a2":"めったに",
  "patient_pedia_knee_instability_q13_a3":"時々",
  "patient_pedia_knee_instability_q13_a4":"頻繁",
  "patient_pedia_knee_instability_q13_a5":"いつも",
  "patient_pedia_knee_instability_q14":"高い段差を上ったり、乗り越えたりする",
  "patient_pedia_knee_instability_q14_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q14_a1":"一度もない",
  "patient_pedia_knee_instability_q14_a2":"めったに",
  "patient_pedia_knee_instability_q14_a3":"時々",
  "patient_pedia_knee_instability_q14_a4":"頻繁",
  "patient_pedia_knee_instability_q14_a5":"いつも",
  "patient_pedia_knee_instability_q15":"座るときに足を組む",
  "patient_pedia_knee_instability_q15_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q15_a1":"一度もない",
  "patient_pedia_knee_instability_q15_a2":"めったに",
  "patient_pedia_knee_instability_q15_a3":"時々",
  "patient_pedia_knee_instability_q15_a4":"頻繁",
  "patient_pedia_knee_instability_q15_a5":"いつも",
  "patient_pedia_knee_instability_q16":"平坦な路面を直線で歩く",
  "patient_pedia_knee_instability_q16_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q16_a1":"一度もない",
  "patient_pedia_knee_instability_q16_a2":"めったに",
  "patient_pedia_knee_instability_q16_a3":"時々",
  "patient_pedia_knee_instability_q16_a4":"頻繁",
  "patient_pedia_knee_instability_q16_a5":"いつも",
  "patient_pedia_knee_instability_q17":"車に出入りする",
  "patient_pedia_knee_instability_q17_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q17_a1":"一度もない",
  "patient_pedia_knee_instability_q17_a2":"めったに",
  "patient_pedia_knee_instability_q17_a3":"時々",
  "patient_pedia_knee_instability_q17_a4":"頻繁",
  "patient_pedia_knee_instability_q17_a5":"いつも",
  "patient_pedia_knee_instability_q18":"スーパーマーケットの通路で重いカートを回す",
  "patient_pedia_knee_instability_q18_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q18_a1":"一度もない",
  "patient_pedia_knee_instability_q18_a2":"めったに",
  "patient_pedia_knee_instability_q18_a3":"時々",
  "patient_pedia_knee_instability_q18_a4":"頻繁",
  "patient_pedia_knee_instability_q18_a5":"いつも",
  "patient_pedia_knee_instability_q19":"肩越しに目を向ける",
  "patient_pedia_knee_instability_q19_do_not_do":"こんな活動はしない",
  "patient_pedia_knee_instability_q19_a1":"一度もない",
  "patient_pedia_knee_instability_q19_a2":"めったに",
  "patient_pedia_knee_instability_q19_a3":"時々",
  "patient_pedia_knee_instability_q19_a4":"頻繁",
  "patient_pedia_knee_instability_q19_a5":"いつも",
  "patient_pedia_knee_quality_of_life":"生活の質",
  "patient_pedia_knee_quality_of_life_instruction":"怪我は生活にどのような影響を及ぼしましたか？",
  "patient_pedia_knee_quality_of_life_q1":"膝についてどのくらいの頻度で意識しますか？",
  "patient_pedia_knee_quality_of_life_q1_a1":"一度もない",
  "patient_pedia_knee_quality_of_life_q1_a2":"めったに",
  "patient_pedia_knee_quality_of_life_q1_a3":"時々",
  "patient_pedia_knee_quality_of_life_q1_a4":"頻繁",
  "patient_pedia_knee_quality_of_life_q1_a5":"いつも",
  "patient_pedia_knee_problem_q2":"負傷した膝のためにライフスタイルをどれだけ変えましたか？",
  "patient_pedia_knee_problem_q2_a1":"全くない",
  "patient_pedia_knee_problem_q2_a2":"少し",
  "patient_pedia_knee_problem_q2_a3":"若干",
  "patient_pedia_knee_problem_q2_a4":"たくさん",
  "patient_pedia_knee_problem_q2_a5":"とても多くの",
  "patient_pedia_knee_problem_q3":"負傷した膝をどれだけ信頼していますか？",
  "patient_pedia_knee_problem_q3_a1":"完全に",
  "patient_pedia_knee_problem_q3_a2":"たくさん",
  "patient_pedia_knee_problem_q3_a3":"若干",
  "patient_pedia_knee_problem_q3_a4":"少し",
  "patient_pedia_knee_problem_q3_a5":"全くない",
  "patient_pedia_knee_problem_q4":"膝が負傷したため、全体的にどれほど困難がありますか？",
  "patient_pedia_knee_problem_q4_a1":"困難なし",
  "patient_pedia_knee_problem_q4_a2":"少し",
  "patient_pedia_knee_problem_q4_a3":"若干",
  "patient_pedia_knee_problem_q4_a4":"たくさん",
  "patient_pedia_knee_problem_q4_a5":"極度の困難",
  "patient_pedia_knee_satisfaction":"満足度",
  "patient_pedia_knee_satisfaction_instruction":"膝の手術/治療の結果にどの程度満足していますか？",
  "patient_pedia_knee_satisfaction_instruction_a1":"非常に不満",
  "patient_pedia_knee_satisfaction_instruction_a2":"ある程度の不満",
  "patient_pedia_knee_satisfaction_instruction_a3":"やや満足",
  "patient_pedia_knee_satisfaction_instruction_a4":"非常に満足",
  "patient_pedia_knee_satisfaction_q1":"全体",
  "patient_pedia_knee_satisfaction_q1_NA":"適用できない",
  "patient_pedia_knee_satisfaction_q1_a1":"非常に不満",
  "patient_pedia_knee_satisfaction_q1_a2":"ある程度の不満",
  "patient_pedia_knee_satisfaction_q1_a3":"やや満足",
  "patient_pedia_knee_satisfaction_q1_a4":"非常に満足",
  "patient_pedia_knee_satisfaction_q2":"痛みの改善については？",
  "patient_pedia_knee_satisfaction_q2_NA":"適用できない",
  "patient_pedia_knee_satisfaction_q2_a1":"非常に不満",
  "patient_pedia_knee_satisfaction_q2_a2":"ある程度の不満",
  "patient_pedia_knee_satisfaction_q2_a3":"やや満足",
  "patient_pedia_knee_satisfaction_q2_a4":"非常に満足",
  "patient_pedia_knee_satisfaction_q3":"家事や庭仕事の能力改善について？",
  "patient_pedia_knee_satisfaction_q3_NA":"適用できない",
  "patient_pedia_knee_satisfaction_q3_a1":"非常に不満",
  "patient_pedia_knee_satisfaction_q3_a2":"ある程度の不満",
  "patient_pedia_knee_satisfaction_q3_a3":"やや満足",
  "patient_pedia_knee_satisfaction_q3_a4":"非常に満足",
  "patient_pedia_knee_satisfaction_q4":"レジャー活動の能力改善について？",
  "patient_pedia_knee_satisfaction_q4_NA":"適用できない",
  "patient_pedia_knee_satisfaction_q4_a1":"非常に不満",
  "patient_pedia_knee_satisfaction_q4_a2":"ある程度の不満",
  "patient_pedia_knee_satisfaction_q4_a3":"やや満足",
  "patient_pedia_knee_satisfaction_q4_a4":"非常に満足",
  "patient_pedia_knee_complete":"アンケート終了",
  "patient_pedia_knee_complete_text1":"時間をかけてこの調査をご記入いただきありがとうございました。",
  "patient_pedia_knee_complete_text2":"[送信]をクリックして完了し、スコアをご確認ください。",
  "patient_pedia_knee_complete_text3":"回答は機密に扱われ、レポートは身分を識別されない情報のみを使用します。",
  "patient_pedia_knee_results":"結果",
  "patient_pedia_knee_results_text1":"以下は、アンケートから計算された痛みと膝の機能スコアです。",
  "patient_pedia_knee_results_pain":"痛み",
  "patient_pedia_knee_results_function":"機能",
  "patient_pedia_knee_results_self_evaluation":"膝スコア自己評価",
  "patient_pedia_knee_results_text2":"まもなく担当医が参ります。",
  "patient_foot_ankle_pain":"痛み",
  "patient_foot_ankle_pain_q1":"足/足首の現在の痛みのレベルはいくらですか？",
  "patient_foot_ankle_pain_q1_0":"なし",
  "patient_foot_ankle_pain_q1_1":"想像できる最悪の痛み",
  "patient_foot_ankle_pain_q2":"足/足首の痛みについて最良の説明は何ですか？",
  "patient_foot_ankle_pain_q2_a1":"なし",
  "patient_foot_ankle_pain_q2_a2":"軽い、時々",
  "patient_foot_ankle_pain_q2_a3":"通常、毎日",
  "patient_foot_ankle_pain_q2_a4":"深刻、ほとんど常に存在",
  "patient_foot_ankle_pain_q3":"どのくらいの頻度で足/足首の痛みを経験しますか？",
  "patient_foot_ankle_pain_q3_a1":"一度もない",
  "patient_foot_ankle_pain_q3_a2":"毎月",
  "patient_foot_ankle_pain_q3_a3":"毎週",
  "patient_foot_ankle_pain_q3_a4":"毎日",
  "patient_foot_ankle_pain_q3_a5":"いつも",
  "patient_foot_ankle_pain_instruction":"次の活動中に足/足首の痛みをどのくらい経験しましたか？",
  "patient_foot_ankle_pain_instruction_0":"なし",
  "patient_foot_ankle_pain_instruction_1":"少し",
  "patient_foot_ankle_pain_instruction_2":"中程度",
  "patient_foot_ankle_pain_instruction_3":"深刻",
  "patient_foot_ankle_pain_instruction_4":"極度に",
  "patient_foot_ankle_pain_q4":"一般的な痛みレベル",
  "patient_foot_ankle_pain_q4_a1":"なし",
  "patient_foot_ankle_pain_q4_a2":"少し",
  "patient_foot_ankle_pain_q4_a3":"中程度",
  "patient_foot_ankle_pain_q4_a4":"深刻",
  "patient_foot_ankle_pain_q4_a5":"極度に",
  "patient_foot_ankle_pain_q5":"休憩中",
  "patient_foot_ankle_pain_q5_a1":"なし",
  "patient_foot_ankle_pain_q5_a2":"少し",
  "patient_foot_ankle_pain_q5_a3":"中程度",
  "patient_foot_ankle_pain_q5_a4":"深刻",
  "patient_foot_ankle_pain_q5_a5":"極度に",
  "patient_foot_ankle_pain_q6":"日常の活動中",
  "patient_foot_ankle_pain_q6_a1":"なし",
  "patient_foot_ankle_pain_q6_a2":"少し",
  "patient_foot_ankle_pain_q6_a3":"中程度",
  "patient_foot_ankle_pain_q6_a4":"深刻",
  "patient_foot_ankle_pain_q6_a5":"極度に",
  "patient_foot_ankle_pain_q7":"朝に最初にすること",
  "patient_foot_ankle_pain_q7_a1":"なし",
  "patient_foot_ankle_pain_q7_a2":"少し",
  "patient_foot_ankle_pain_q7_a3":"中程度",
  "patient_foot_ankle_pain_q7_a4":"深刻",
  "patient_foot_ankle_pain_q7_a5":"極度に",
  "patient_foot_ankle_pain_q8":"足/足首にねじれ/回転",
  "patient_foot_ankle_pain_q8_a1":"なし",
  "patient_foot_ankle_pain_q8_a2":"少し",
  "patient_foot_ankle_pain_q8_a3":"中程度",
  "patient_foot_ankle_pain_q8_a4":"深刻",
  "patient_foot_ankle_pain_q8_a5":"極度に",
  "patient_foot_ankle_pain_q9":"足／足首を完全にまっすぐにする",
  "patient_foot_ankle_pain_q9_a1":"なし",
  "patient_foot_ankle_pain_q9_a2":"少し",
  "patient_foot_ankle_pain_q9_a3":"中程度",
  "patient_foot_ankle_pain_q9_a4":"深刻",
  "patient_foot_ankle_pain_q9_a5":"極度に",
  "patient_foot_ankle_pain_q10":"足/足首を完全に曲げる",
  "patient_foot_ankle_pain_q10_a1":"なし",
  "patient_foot_ankle_pain_q10_a2":"少し",
  "patient_foot_ankle_pain_q10_a3":"中程度",
  "patient_foot_ankle_pain_q10_a4":"深刻",
  "patient_foot_ankle_pain_q10_a5":"極度に",
  "patient_foot_ankle_pain_q11":"平らな表面を歩く",
  "patient_foot_ankle_pain_q11_a1":"なし",
  "patient_foot_ankle_pain_q11_a2":"少し",
  "patient_foot_ankle_pain_q11_a3":"中程度",
  "patient_foot_ankle_pain_q11_a4":"深刻",
  "patient_foot_ankle_pain_q11_a5":"極度に",
  "patient_foot_ankle_pain_q12":"階段を上下する",
  "patient_foot_ankle_pain_q12_a1":"なし",
  "patient_foot_ankle_pain_q12_a2":"少し",
  "patient_foot_ankle_pain_q12_a3":"中程度",
  "patient_foot_ankle_pain_q12_a4":"深刻",
  "patient_foot_ankle_pain_q12_a5":"極度に",
  "patient_foot_ankle_pain_q13":"夜のベッドで",
  "patient_foot_ankle_pain_q13_a1":"なし",
  "patient_foot_ankle_pain_q13_a2":"少し",
  "patient_foot_ankle_pain_q13_a3":"中程度",
  "patient_foot_ankle_pain_q13_a4":"深刻",
  "patient_foot_ankle_pain_q13_a5":"極度に",
  "patient_foot_ankle_pain_q14":"座っている、または寝ている",
  "patient_foot_ankle_pain_q14_a1":"なし",
  "patient_foot_ankle_pain_q14_a2":"少し",
  "patient_foot_ankle_pain_q14_a3":"中程度",
  "patient_foot_ankle_pain_q14_a4":"深刻",
  "patient_foot_ankle_pain_q14_a5":"極度に",
  "patient_foot_ankle_pain_q15":"まっすぐ立つ",
  "patient_foot_ankle_pain_q15_a1":"なし",
  "patient_foot_ankle_pain_q15_a2":"少し",
  "patient_foot_ankle_pain_q15_a3":"中程度",
  "patient_foot_ankle_pain_q15_a4":"深刻",
  "patient_foot_ankle_pain_q15_a5":"極度に",
  "patient_foot_ankle_instability":"不安定",
  "patient_foot_ankle_instability_q1":"何度も捻挫を繰り返したり、足首が曲がってしまったり、足首が曲がってしまいそうになったりしませんか？",
  "patient_foot_ankle_instability_q1_a1":"いいえ",
  "patient_foot_ankle_instability_q1_a2":"はい",
  "patient_foot_ankle_instability_q2":"足首の不安定性や捻挫の再発の診断/治療を受けたことがありますか？",
  "patient_foot_ankle_instability_q2_a1":"いいえ",
  "patient_foot_ankle_instability_q2_a2":"はい",
  "patient_foot_ankle_instability_q3":"患っていない足首は完全に安定していますか？",
  "patient_foot_ankle_instability_q3_a1":"いいえ",
  "patient_foot_ankle_instability_q3_a2":"はい",
  "patient_foot_ankle_cumberland":"カンバーランド足首不安定性ツール",
  "patient_foot_ankle_cumberland_instruction":"各設問の中で、あなたの足首について最も適切な記述を1つ選んでください。",
  "patient_foot_ankle_cumberland_q1":"足首に痛みがあります。",
  "patient_foot_ankle_cumberland_q1_a1":"一度もない",
  "patient_foot_ankle_cumberland_q1_a2":"スポーツ中",
  "patient_foot_ankle_cumberland_q1_a3":"不規則な路面で走る",
  "patient_foot_ankle_cumberland_q1_a4":"平らな表面で走る",
  "patient_foot_ankle_cumberland_q1_a5":"不規則な路面で歩く",
  "patient_foot_ankle_cumberland_q1_a6":"平らな表面で歩く",
  "patient_foot_ankle_cumberland_q2":"足首は不安定に感じる",
  "patient_foot_ankle_cumberland_q2_a1":"一度もない",
  "patient_foot_ankle_cumberland_q2_a2":"スポーツ中に時々（毎回ではない）",
  "patient_foot_ankle_cumberland_q2_a3":"スポーツ中に頻繁に（毎回）",
  "patient_foot_ankle_cumberland_q2_a4":"日常活動中で時々",
  "patient_foot_ankle_cumberland_q2_a5":"日常活動中で頻繁に",
  "patient_foot_ankle_cumberland_q3":"急旋回をすると、足首が不安定に感じる。",
  "patient_foot_ankle_cumberland_q3_a1":"一度もない",
  "patient_foot_ankle_cumberland_q3_a2":"走っているとき、時折に",
  "patient_foot_ankle_cumberland_q3_a3":"走っているとき、頻繁に",
  "patient_foot_ankle_cumberland_q3_a4":"歩くとき",
  "patient_foot_ankle_cumberland_q4":"階段を下ると、足首が不安定に感じる",
  "patient_foot_ankle_cumberland_q4_a1":"一度もない",
  "patient_foot_ankle_cumberland_q4_a2":"速く行くとき",
  "patient_foot_ankle_cumberland_q4_a3":"たまに",
  "patient_foot_ankle_cumberland_q4_a4":"いつも",
  "patient_foot_ankle_cumberland_q5":"片足で立つと足首が不安定に感じる。",
  "patient_foot_ankle_cumberland_q5_a1":"一度もない",
  "patient_foot_ankle_cumberland_q5_a2":"足の裏に",
  "patient_foot_ankle_cumberland_q5_a3":"平らな足で",
  "patient_foot_ankle_cumberland_q6":"足首が不安定だと感じるのはどんなとき？",
  "patient_foot_ankle_cumberland_q6_a1":"一度もない",
  "patient_foot_ankle_cumberland_q6_a2":"左右に飛び跳ねる",
  "patient_foot_ankle_cumberland_q6_a3":"その場で飛び跳ねる",
  "patient_foot_ankle_cumberland_q6_a4":"ジャンプする",
  "patient_foot_ankle_cumberland_q7":"足首が不安定だと感じるのはどんなとき？",
  "patient_foot_ankle_cumberland_q7_a1":"一度もない",
  "patient_foot_ankle_cumberland_q7_a2":"不規則な路面で走る",
  "patient_foot_ankle_cumberland_q7_a3":"不規則な路面でジョギングする",
  "patient_foot_ankle_cumberland_q7_a4":"不規則な路面で歩く",
  "patient_foot_ankle_cumberland_q7_a5":"平らな路面で歩く",
  "patient_foot_ankle_cumberland_q8":"一般的に、足首をひねって転がり始めると、それを止めることができる。",
  "patient_foot_ankle_cumberland_q8_a1":"すぐに",
  "patient_foot_ankle_cumberland_q8_a2":"頻繁",
  "patient_foot_ankle_cumberland_q8_a3":"時々",
  "patient_foot_ankle_cumberland_q8_a4":"一度もない",
  "patient_foot_ankle_cumberland_q8_a5":"足首をひねって転がったことがない",
  "patient_foot_ankle_cumberland_q9":"足首ひねって転がるという典型的な出来事の後、私の足首は \"正常 \"に戻った。",
  "patient_foot_ankle_cumberland_q9_a1":"ほぼすぐに",
  "patient_foot_ankle_cumberland_q9_a2":"1日未満",
  "patient_foot_ankle_cumberland_q9_a3":"1〜2日",
  "patient_foot_ankle_cumberland_q9_a4":"2日以上",
  "patient_foot_ankle_cumberland_q9_a5":"足首をひねって転がったことがない",
  "patient_foot_ankle_other_symptoms":"その他の症状",
  "patient_foot_ankle_other_symptoms_instruction1":"足/足首の症状に最適な答えを選択してください。",
  "patient_foot_ankle_other_symptoms_instruction2":"質問に答える方法がわからない場合は、できる限り最良の答えをお願いします。",
  "patient_foot_ankle_other_symptoms_note":"注：この質問に答えて、先週の足/足首の症状について考えてみる必要があります。",
  "patient_foot_ankle_other_symptoms_instruction3":"次のことはありますか？",
  "patient_foot_ankle_other_symptoms_instruction3_0":"一度もない",
  "patient_foot_ankle_other_symptoms_instruction3_1":"めったに",
  "patient_foot_ankle_other_symptoms_instruction3_2":"時々",
  "patient_foot_ankle_other_symptoms_instruction3_3":"頻繁",
  "patient_foot_ankle_other_symptoms_instruction3_4":"いつも",
  "patient_foot_ankle_other_symptoms_q1":"足/足首の腫れ",
  "patient_foot_ankle_other_symptoms_q1_a1":"一度もない",
  "patient_foot_ankle_other_symptoms_q1_a2":"めったに",
  "patient_foot_ankle_other_symptoms_q1_a3":"時々",
  "patient_foot_ankle_other_symptoms_q1_a4":"頻繁",
  "patient_foot_ankle_other_symptoms_q1_a5":"いつも",
  "patient_foot_ankle_other_symptoms_q2":"足/足首が動くと、ガリガリ音、カチカチ音やその他の異音がする",
  "patient_foot_ankle_other_symptoms_q2_a1":"一度もない",
  "patient_foot_ankle_other_symptoms_q2_a2":"めったに",
  "patient_foot_ankle_other_symptoms_q2_a3":"時々",
  "patient_foot_ankle_other_symptoms_q2_a4":"頻繁",
  "patient_foot_ankle_other_symptoms_q2_a5":"いつも",
  "patient_foot_ankle_other_symptoms_q3":"動くときは引っかかったりぶら下がったりするような感じ",
  "patient_foot_ankle_other_symptoms_q3_a1":"一度もない",
  "patient_foot_ankle_other_symptoms_q3_a2":"めったに",
  "patient_foot_ankle_other_symptoms_q3_a3":"時々",
  "patient_foot_ankle_other_symptoms_q3_a4":"頻繁",
  "patient_foot_ankle_other_symptoms_q3_a5":"いつも",
  "patient_foot_ankle_other_symptoms_instruction4":"あなたの足/足首のこりはどれほど深刻ですか：",
  "patient_foot_ankle_other_symptoms_instruction4_0":"なし",
  "patient_foot_ankle_other_symptoms_instruction4_1":"少し",
  "patient_foot_ankle_other_symptoms_instruction4_2":"中程度",
  "patient_foot_ankle_other_symptoms_instruction4_3":"深刻",
  "patient_foot_ankle_other_symptoms_instruction4_4":"極度に",
  "patient_foot_ankle_other_symptoms_q4":"朝目覚めた後",
  "patient_foot_ankle_other_symptoms_q4_a1":"なし",
  "patient_foot_ankle_other_symptoms_q4_a2":"少し",
  "patient_foot_ankle_other_symptoms_q4_a3":"中程度",
  "patient_foot_ankle_other_symptoms_q4_a4":"深刻",
  "patient_foot_ankle_other_symptoms_q4_a5":"極度に",
  "patient_foot_ankle_other_symptoms_q5":"午後/夕方に座ったり横になったりした後",
  "patient_foot_ankle_other_symptoms_q5_a1":"なし",
  "patient_foot_ankle_other_symptoms_q5_a2":"少し",
  "patient_foot_ankle_other_symptoms_q5_a3":"中程度",
  "patient_foot_ankle_other_symptoms_q5_a4":"深刻",
  "patient_foot_ankle_other_symptoms_q5_a5":"極度に",
  "patient_foot_ankle_function":"機能",
  "patient_foot_ankle_function_q1":"足や足首のために、日常生活でどのような制限がありますか？",
  "patient_foot_ankle_function_q1_a1":"制限なし。サポートも必要なし",
  "patient_foot_ankle_function_q1_a2":"日常活動、レジャー活動の制限なし。サポートも必要なし",
  "patient_foot_ankle_function_q1_a3":"日常生活やレジャー活動の制限あり、杖が必要",
  "patient_foot_ankle_function_q1_a4":"日常生活やレジャー活動に重大な制限。歩行器、松葉杖、車椅子、補助装置が必要",
  "patient_foot_ankle_function_q2":"通常どんな靴を履いていますか？",
  "patient_foot_ankle_function_q2_a1":"ファッショナブルで従来の靴、挿入物は必要なし",
  "patient_foot_ankle_function_q2_a2":"快適な靴、挿入物あり",
  "patient_foot_ankle_function_q2_a3":"改造された靴または補助装置必要",
  "patient_foot_ankle_function_q3":"どのような歩行面を歩くのが難しいですか？",
  "patient_foot_ankle_function_q3_a1":"どんな表面でも困難はない",
  "patient_foot_ankle_function_q3_a2":"不規則な地形、階段、傾斜地、はしごで多少の困難",
  "patient_foot_ankle_function_q3_a3":"不規則な地形、階段、傾斜地、はしごで深刻な困難",
  "patient_foot_ankle_function_instruction":"最近1週間で足/足首のため経験した困難の程度を示してください。",
  "patient_foot_ankle_function_instruction_0":"困難なし",
  "patient_foot_ankle_function_instruction_1":"わずか",
  "patient_foot_ankle_function_instruction_2":"いくつかの困難がある",
  "patient_foot_ankle_function_instruction_3":"極度の困難",
  "patient_foot_ankle_function_instruction_4":"できない",
  "patient_foot_ankle_function_note":"注：足または足首以外によって活動が制限されている場合は、「該当しない」にチェックしてください。そうでない場合は、すべての質問に、過去1週間以内のあなたの状態に最も近いものを選んでお答えください。",
  "patient_foot_ankle_function_q4":"足／足首を完全にまっすぐにする",
  "patient_foot_ankle_function_q4_a1":"困難なし",
  "patient_foot_ankle_function_q4_a2":"わずか",
  "patient_foot_ankle_function_q4_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q4_a4":"極度の困難",
  "patient_foot_ankle_function_q4_a5":"できない",
  "patient_foot_ankle_function_q5":"足/足首を完全に曲げる",
  "patient_foot_ankle_function_q5_a1":"困難なし",
  "patient_foot_ankle_function_q5_a2":"わずか",
  "patient_foot_ankle_function_q5_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q5_a4":"極度の困難",
  "patient_foot_ankle_function_q5_a5":"できない",
  "patient_foot_ankle_function_q6":"階段を下る",
  "patient_foot_ankle_function_q6_a1":"困難なし",
  "patient_foot_ankle_function_q6_a2":"わずか",
  "patient_foot_ankle_function_q6_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q6_a4":"極度の困難",
  "patient_foot_ankle_function_q6_a5":"できない",
  "patient_foot_ankle_function_q7":"階段を上る",
  "patient_foot_ankle_function_q7_a1":"困難なし",
  "patient_foot_ankle_function_q7_a2":"わずか",
  "patient_foot_ankle_function_q7_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q7_a4":"極度の困難",
  "patient_foot_ankle_function_q7_a5":"できない",
  "patient_foot_ankle_function_q8":"座っている状態から立ち上がる",
  "patient_foot_ankle_function_q8_a1":"困難なし",
  "patient_foot_ankle_function_q8_a2":"わずか",
  "patient_foot_ankle_function_q8_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q8_a4":"極度の困難",
  "patient_foot_ankle_function_q8_a5":"できない",
  "patient_foot_ankle_function_q9":"立っている",
  "patient_foot_ankle_function_q9_a1":"困難なし",
  "patient_foot_ankle_function_q9_a2":"わずか",
  "patient_foot_ankle_function_q9_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q9_a4":"極度の困難",
  "patient_foot_ankle_function_q9_a5":"できない",
  "patient_foot_ankle_function_q10":"床に屈む／物を拾う",
  "patient_foot_ankle_function_q10_a1":"困難なし",
  "patient_foot_ankle_function_q10_a2":"わずか",
  "patient_foot_ankle_function_q10_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q10_a4":"極度の困難",
  "patient_foot_ankle_function_q10_a5":"できない",
  "patient_foot_ankle_function_q11":"平らな表面を歩く",
  "patient_foot_ankle_function_q11_a1":"困難なし",
  "patient_foot_ankle_function_q11_a2":"わずか",
  "patient_foot_ankle_function_q11_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q11_a4":"極度の困難",
  "patient_foot_ankle_function_q11_a5":"できない",
  "patient_foot_ankle_function_q12":"歩ける最大距離は？",
  "patient_foot_ankle_function_q12_a1":"6つ以上のブロック",
  "patient_foot_ankle_function_q12_a2":"4-6ブロック",
  "patient_foot_ankle_function_q12_a3":"1-3ブロック",
  "patient_foot_ankle_function_q12_a4":"1ブロック未満",
  "patient_foot_ankle_function_q13":"靴なしで平らな土地を歩く",
  "patient_foot_ankle_function_q13_a1":"困難なし",
  "patient_foot_ankle_function_q13_a2":"わずか",
  "patient_foot_ankle_function_q13_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q13_a4":"極度の困難",
  "patient_foot_ankle_function_q13_a5":"できない",
  "patient_foot_ankle_function_q14":"坂を上る",
  "patient_foot_ankle_function_q14_a1":"困難なし",
  "patient_foot_ankle_function_q14_a2":"わずか",
  "patient_foot_ankle_function_q14_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q14_a4":"極度の困難",
  "patient_foot_ankle_function_q14_a5":"できない",
  "patient_foot_ankle_function_q15":"坂を下る",
  "patient_foot_ankle_function_q15_a1":"困難なし",
  "patient_foot_ankle_function_q15_a2":"わずか",
  "patient_foot_ankle_function_q15_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q15_a4":"極度の困難",
  "patient_foot_ankle_function_q15_a5":"できない",
  "patient_foot_ankle_function_q16":"不規則な地面を歩く",
  "patient_foot_ankle_function_q16_a1":"困難なし",
  "patient_foot_ankle_function_q16_a2":"わずか",
  "patient_foot_ankle_function_q16_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q16_a4":"極度の困難",
  "patient_foot_ankle_function_q16_a5":"できない",
  "patient_foot_ankle_function_q17":"縁石の上り下り",
  "patient_foot_ankle_function_q17_a1":"困難なし",
  "patient_foot_ankle_function_q17_a2":"わずか",
  "patient_foot_ankle_function_q17_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q17_a4":"極度の困難",
  "patient_foot_ankle_function_q17_a5":"できない",
  "patient_foot_ankle_function_q18":"つま先まで来る",
  "patient_foot_ankle_function_q18_a1":"困難なし",
  "patient_foot_ankle_function_q18_a2":"わずか",
  "patient_foot_ankle_function_q18_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q18_a4":"極度の困難",
  "patient_foot_ankle_function_q18_a5":"できない",
  "patient_foot_ankle_function_q19":"最初の歩く",
  "patient_foot_ankle_function_q19_a1":"困難なし",
  "patient_foot_ankle_function_q19_a2":"わずか",
  "patient_foot_ankle_function_q19_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q19_a4":"極度の困難",
  "patient_foot_ankle_function_q19_a5":"できない",
  "patient_foot_ankle_function_q20":"5分以内に歩く",
  "patient_foot_ankle_function_q20_a1":"困難なし",
  "patient_foot_ankle_function_q20_a2":"わずか",
  "patient_foot_ankle_function_q20_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q20_a4":"極度の困難",
  "patient_foot_ankle_function_q20_a5":"できない",
  "patient_foot_ankle_function_q21":"徒歩約10分",
  "patient_foot_ankle_function_q21_a1":"困難なし",
  "patient_foot_ankle_function_q21_a2":"わずか",
  "patient_foot_ankle_function_q21_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q21_a4":"極度の困難",
  "patient_foot_ankle_function_q21_a5":"できない",
  "patient_foot_ankle_function_q22":"15分以上歩く",
  "patient_foot_ankle_function_q22_a1":"困難なし",
  "patient_foot_ankle_function_q22_a2":"わずか",
  "patient_foot_ankle_function_q22_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q22_a4":"極度の困難",
  "patient_foot_ankle_function_q22_a5":"できない",
  "patient_foot_ankle_function_q23":"車の乗り降り",
  "patient_foot_ankle_function_q23_a1":"困難なし",
  "patient_foot_ankle_function_q23_a2":"わずか",
  "patient_foot_ankle_function_q23_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q23_a4":"極度の困難",
  "patient_foot_ankle_function_q23_a5":"できない",
  "patient_foot_ankle_function_q24":"買い物",
  "patient_foot_ankle_function_q24_a1":"困難なし",
  "patient_foot_ankle_function_q24_a2":"わずか",
  "patient_foot_ankle_function_q24_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q24_a4":"極度の困難",
  "patient_foot_ankle_function_q24_a5":"できない",
  "patient_foot_ankle_function_q25":"靴下/ストッキングを着用",
  "patient_foot_ankle_function_q25_a1":"困難なし",
  "patient_foot_ankle_function_q25_a2":"わずか",
  "patient_foot_ankle_function_q25_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q25_a4":"極度の困難",
  "patient_foot_ankle_function_q25_a5":"できない",
  "patient_foot_ankle_function_q26":"靴下/ストッキングを脱ぐ",
  "patient_foot_ankle_function_q26_a1":"困難なし",
  "patient_foot_ankle_function_q26_a2":"わずか",
  "patient_foot_ankle_function_q26_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q26_a4":"極度の困難",
  "patient_foot_ankle_function_q26_a5":"できない",
  "patient_foot_ankle_function_q27":"ベッドから立ち上がる",
  "patient_foot_ankle_function_q27_a1":"困難なし",
  "patient_foot_ankle_function_q27_a2":"わずか",
  "patient_foot_ankle_function_q27_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q27_a4":"極度の困難",
  "patient_foot_ankle_function_q27_a5":"できない",
  "patient_foot_ankle_function_q28":"ベッドに横たわっている（ひっくり返り、足/足首の姿勢を維持する）",
  "patient_foot_ankle_function_q28_a1":"困難なし",
  "patient_foot_ankle_function_q28_a2":"わずか",
  "patient_foot_ankle_function_q28_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q28_a4":"極度の困難",
  "patient_foot_ankle_function_q28_a5":"できない",
  "patient_foot_ankle_function_q29":"睡眠",
  "patient_foot_ankle_function_q29_a1":"困難なし",
  "patient_foot_ankle_function_q29_a2":"わずか",
  "patient_foot_ankle_function_q29_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q29_a4":"極度の困難",
  "patient_foot_ankle_function_q29_a5":"できない",
  "patient_foot_ankle_function_q30":"お風呂に出入りする",
  "patient_foot_ankle_function_q30_a1":"困難なし",
  "patient_foot_ankle_function_q30_a2":"わずか",
  "patient_foot_ankle_function_q30_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q30_a4":"極度の困難",
  "patient_foot_ankle_function_q30_a5":"できない",
  "patient_foot_ankle_function_q31":"座っている",
  "patient_foot_ankle_function_q31_a1":"困難なし",
  "patient_foot_ankle_function_q31_a2":"わずか",
  "patient_foot_ankle_function_q31_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q31_a4":"極度の困難",
  "patient_foot_ankle_function_q31_a5":"できない",
  "patient_foot_ankle_function_q32":"トイレの乗り降り",
  "patient_foot_ankle_function_q32_a1":"困難なし",
  "patient_foot_ankle_function_q32_a2":"わずか",
  "patient_foot_ankle_function_q32_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q32_a4":"極度の困難",
  "patient_foot_ankle_function_q32_a5":"できない",
  "patient_foot_ankle_function_q33":"家事責任",
  "patient_foot_ankle_function_q33_a1":"困難なし",
  "patient_foot_ankle_function_q33_a2":"わずか",
  "patient_foot_ankle_function_q33_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q33_a4":"極度の困難",
  "patient_foot_ankle_function_q33_a5":"できない",
  "patient_foot_ankle_function_q34":"日常生活の活動",
  "patient_foot_ankle_function_q34_a1":"困難なし",
  "patient_foot_ankle_function_q34_a2":"わずか",
  "patient_foot_ankle_function_q34_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q34_a4":"極度の困難",
  "patient_foot_ankle_function_q34_a5":"できない",
  "patient_foot_ankle_function_q35":"個人管理（体の洗浄や着替えを含む)",
  "patient_foot_ankle_function_q35_a1":"困難なし",
  "patient_foot_ankle_function_q35_a2":"わずか",
  "patient_foot_ankle_function_q35_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q35_a4":"極度の困難",
  "patient_foot_ankle_function_q35_a5":"できない",
  "patient_foot_ankle_function_q36":"力仕事（押し引き、登山、物を運ぶ、重い箱の移動、床拭きなど）",
  "patient_foot_ankle_function_q36_a1":"困難なし",
  "patient_foot_ankle_function_q36_a2":"わずか",
  "patient_foot_ankle_function_q36_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q36_a4":"極度の困難",
  "patient_foot_ankle_function_q36_a5":"できない",
  "patient_foot_ankle_function_q37":"軽い家事（料理、掃除など）",
  "patient_foot_ankle_function_q37_a1":"困難なし",
  "patient_foot_ankle_function_q37_a2":"わずか",
  "patient_foot_ankle_function_q37_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q37_a4":"極度の困難",
  "patient_foot_ankle_function_q37_a5":"できない",
  "patient_foot_ankle_function_q38":"軽～中程度の作業（立ち仕事、歩行）",
  "patient_foot_ankle_function_q38_a1":"困難なし",
  "patient_foot_ankle_function_q38_a2":"わずか",
  "patient_foot_ankle_function_q38_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q38_a4":"極度の困難",
  "patient_foot_ankle_function_q38_a5":"できない",
  "patient_foot_ankle_function_q39":"レジャー",
  "patient_foot_ankle_function_q39_a1":"困難なし",
  "patient_foot_ankle_function_q39_a2":"わずか",
  "patient_foot_ankle_function_q39_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q39_a4":"極度の困難",
  "patient_foot_ankle_function_q39_a5":"できない",
  "patient_foot_ankle_function_q40":"しゃがむ",
  "patient_foot_ankle_function_q40_a1":"困難なし",
  "patient_foot_ankle_function_q40_a2":"わずか",
  "patient_foot_ankle_function_q40_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q40_a4":"極度の困難",
  "patient_foot_ankle_function_q40_a5":"できない",
  "patient_foot_ankle_function_q41":"足/足首にねじれ/回転",
  "patient_foot_ankle_function_q41_a1":"困難なし",
  "patient_foot_ankle_function_q41_a2":"わずか",
  "patient_foot_ankle_function_q41_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q41_a4":"極度の困難",
  "patient_foot_ankle_function_q41_a5":"できない",
  "patient_foot_ankle_function_q42":"膝をつく",
  "patient_foot_ankle_function_q42_a1":"困難なし",
  "patient_foot_ankle_function_q42_a2":"わずか",
  "patient_foot_ankle_function_q42_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q42_a4":"極度の困難",
  "patient_foot_ankle_function_q42_a5":"できない",
  "patient_foot_ankle_function_q43":"ランニング",
  "patient_foot_ankle_function_q43_not_applicable":"適用できない",
  "patient_foot_ankle_function_q43_a1":"困難なし",
  "patient_foot_ankle_function_q43_a2":"わずか",
  "patient_foot_ankle_function_q43_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q43_a4":"極度の困難",
  "patient_foot_ankle_function_q43_a5":"できない",
  "patient_foot_ankle_function_q44":"ジャンプ",
  "patient_foot_ankle_function_q44_not_applicable":"適用できない",
  "patient_foot_ankle_function_q44_a1":"困難なし",
  "patient_foot_ankle_function_q44_a2":"わずか",
  "patient_foot_ankle_function_q44_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q44_a4":"極度の困難",
  "patient_foot_ankle_function_q44_a5":"できない",
  "patient_foot_ankle_function_q45":"着陸",
  "patient_foot_ankle_function_q45_not_applicable":"適用できない",
  "patient_foot_ankle_function_q45_a1":"困難なし",
  "patient_foot_ankle_function_q45_a2":"わずか",
  "patient_foot_ankle_function_q45_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q45_a4":"極度の困難",
  "patient_foot_ankle_function_q45_a5":"できない",
  "patient_foot_ankle_function_q46":"急発進・急停止",
  "patient_foot_ankle_function_q46_not_applicable":"適用できない",
  "patient_foot_ankle_function_q46_a1":"困難なし",
  "patient_foot_ankle_function_q46_a2":"わずか",
  "patient_foot_ankle_function_q46_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q46_a4":"極度の困難",
  "patient_foot_ankle_function_q46_a5":"できない",
  "patient_foot_ankle_function_q47":"カッティング/横方向の動き",
  "patient_foot_ankle_function_q47_not_applicable":"適用できない",
  "patient_foot_ankle_function_q47_a1":"困難なし",
  "patient_foot_ankle_function_q47_a2":"わずか",
  "patient_foot_ankle_function_q47_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q47_a4":"極度の困難",
  "patient_foot_ankle_function_q47_a5":"できない",
  "patient_foot_ankle_function_q48":"低負荷の活動",
  "patient_foot_ankle_function_q48_not_applicable":"適用できない",
  "patient_foot_ankle_function_q48_a1":"困難なし",
  "patient_foot_ankle_function_q48_a2":"わずか",
  "patient_foot_ankle_function_q48_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q48_a4":"極度の困難",
  "patient_foot_ankle_function_q48_a5":"できない",
  "patient_foot_ankle_function_q49":"通常のテクニックで活動を行う能力",
  "patient_foot_ankle_function_q49_not_applicable":"適用できない",
  "patient_foot_ankle_function_q49_a1":"困難なし",
  "patient_foot_ankle_function_q49_a2":"わずか",
  "patient_foot_ankle_function_q49_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q49_a4":"極度の困難",
  "patient_foot_ankle_function_q49_a5":"できない",
  "patient_foot_ankle_function_q50":"希望するスポーツに好きなだけ長い間参加する能力",
  "patient_foot_ankle_function_q50_not_applicable":"適用できない",
  "patient_foot_ankle_function_q50_a1":"困難なし",
  "patient_foot_ankle_function_q50_a2":"わずか",
  "patient_foot_ankle_function_q50_a3":"いくつかの困難がある",
  "patient_foot_ankle_function_q50_a4":"極度の困難",
  "patient_foot_ankle_function_q50_a5":"できない",
  "patient_foot_ankle_function_q51":"今日の足首/足をどのように評価しますか？ （0〜100％、100％が正常）",
  "patient_foot_ankle_function_q51_a1":"普通",
  "patient_foot_ankle_function_q52":"定期的に参加できる最高レベルの活動は何ですか？",
  "patient_foot_ankle_function_q52_q1":"足や足首の怪我/問題の前",
  "patient_foot_ankle_function_q52_q1_a1":"最上位レベルのスポーツ",
  "patient_foot_ankle_function_q52_q1_a2":"一般レベルの競技スポーツ",
  "patient_foot_ankle_function_q52_q1_a3":"レジャースポーツ",
  "patient_foot_ankle_function_q52_q1_a4":"スポーツ活動はしない",
  "patient_foot_ankle_function_q52_q1_a5":"運動も仕事もしない",
  "patient_foot_ankle_function_q52_q1_a4_o7":"足首の問題のために歩くことができない障害者",
  "patient_foot_ankle_function_q52_q1_a1_title":"最上位レベル（国際エリート、プロ、ナショナルチーム、1部リーグ）",
  "patient_foot_ankle_function_q52_q1_a2_title":"一般レベルの競技スポーツ",
  "patient_foot_ankle_function_q52_q1_a3_title":"レジャー活動",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1":"エアロビクス、フィットネス",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2":"アルパインスキーとスノーボード",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3":"アメリカンフットボール",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4":"バドミントン",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5":"野球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6":"バスケットボール",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7":"ボウリング/カーリング",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08":"ボクシング",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9":"クロスカントリー競走",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10":"サイクリング",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11":"ダンス",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12":"ダイビング",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13":"馬術",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14":"フェンシング",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15":"フロアボール",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16":"フリースタイルスノーボード",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17":"ゴルフ",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18":"体操",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19":"ハンドボール",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20":"ホッケー",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21":"アイスホッケー",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22":"コーフボール",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23":"武道",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24":"近代五種",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25":"モータースポーツ、テクノロジースポーツ",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26":"山と丘を登る",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27":"マウンテンバイク/BMX",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28":"ノルディックスキー",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29":"オリエンテーリング",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30":"パラシュート",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31":"パワーリフティング",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32":"新体操",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33":"ローイング、カヤック",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34":"ラグビー",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35":"セーリング",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36":"スキューバダイビング",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37":"射撃、アーチェリー",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38":"スケート、インラインスケート",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39":"サッカー",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40":"ソフトボール",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41":"スカッシュ",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42":"サーフィン、ウィンドサーフィン",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43":"卓球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44":"テニス",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45":"陸上：フィールド競技",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46":"陸上：トラック競技",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47":"トライアスロン",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48":"バレーボール",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49":"水球と水泳",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50":"水上スキー",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51":"重量挙げ、ボディービル",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52":"レスリング",
  "patient_foot_ankle_function_q52_q1_a4_o1":"激しい肉体労働",
  "patient_foot_ankle_function_q52_q1_a4_o2":"肉体労働",
  "patient_foot_ankle_function_q52_q1_a4_o3":"特別な職業：バレエダンサー、兵士、特別救助隊、スタントマン",
  "patient_foot_ankle_function_q52_q1_a4_o4":"不規則な地面を歩くことができる",
  "patient_foot_ankle_function_q52_q1_a4_o5":"日常的な活動に制限なし",
  "patient_foot_ankle_function_q52_q1_a4_o6":"平地での歩行は可能だが、日常生活に制限がある",
  "patient_foot_ankle_function_q52_q2":"現在の状況",
  "patient_foot_ankle_function_q52_q2_a1":"最上位レベル",
  "patient_foot_ankle_function_q52_q2_a2":"一般レベルの競技スポーツ",
  "patient_foot_ankle_function_q52_q2_a3":"レジャー活動",
  "patient_foot_ankle_function_q52_q2_a4":"スポーツ活動はしない",
  "patient_foot_ankle_function_q52_q2_a5":"運動も仕事もしない",
  "patient_foot_ankle_function_q52_q2_a4_o7":"足首の問題のために歩くことができない障害者",
  "patient_foot_ankle_function_q52_q2_a1_title":"ハイレベル（国際エリート、プロ、ナショナルチーム、または第1リーグ）",
  "patient_foot_ankle_function_q52_q2_a2_title":"一般レベルの競技スポーツ",
  "patient_foot_ankle_function_q52_q2_a3_title":"レジャー活動",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1":"エアロビクス、フィットネス",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2":"アルパインスキーとスノーボード",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3":"フットボール",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4":"バドミントン",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5":"野球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6":"バスケットボール",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7":"ボウリング/カーリング",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8":"ボクシング",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9":"クロスカントリー競走",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10":"サイクリング",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11":"ダンス",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12":"ダイビング",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13":"馬術",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14":"フェンシング",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15":"フロアボール",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16":"フリースタイルスノーボード",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17":"ゴルフ",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18":"体操",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19":"ハンドボール",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20":"ホッケー",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21":"アイスホッケー",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22":"コーフボール",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23":"武道",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24":"近代五種",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25":"モータースポーツ、テクノロジースポーツ",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26":"山と丘を登る",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27":"マウンテンバイク/BMX",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28":"ノルディックスキー",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29":"オリエンテーリング",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30":"パラシュート",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31":"パワーリフティング",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32":"新体操",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33":"ローイング、カヤック",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34":"ラグビー",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35":"セーリング",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36":"スキューバダイビング",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37":"射撃、アーチェリー",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38":"スケート、インラインスケート",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39":"サッカー",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40":"ソフトボール",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41":"スカッシュ",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42":"サーフィン、ウィンドサーフィン",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43":"卓球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44":"テニス",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45":"陸上：フィールド競技",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46":"陸上：トラック競技",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47":"トライアスロン",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48":"バレーボール",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49":"水球と水泳",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50":"水上スキー",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51":"重量挙げ、ボディービル",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52":"レスリング",
  "patient_foot_ankle_function_q52_q2_a4_o1":"激しい肉体労働",
  "patient_foot_ankle_function_q52_q2_a4_o2":"肉体労働",
  "patient_foot_ankle_function_q52_q2_a4_o3":"特別な職業：バレエダンサー、兵士、特別救助隊、スタントマン",
  "patient_foot_ankle_function_q52_q2_a4_o4":"不規則な地面を歩くことができる",
  "patient_foot_ankle_function_q52_q2_a4_o5":"日常的な活動に制限なし",
  "patient_foot_ankle_function_q52_q2_a4_o6":"平地での歩行は可能だが、あらゆる活動が制限される",
  "patient_foot_ankle_quality_of_life":"生活の質",
  "patient_foot_ankle_quality_of_life_instructions":"以下は、足/足首の問題に感じる社会的、感情的、ライフスタイルに関する質問です。足/足首について通常感じていることを考えてください。",
  "patient_foot_ankle_quality_of_life_q1":"足/足首の問題を意識する頻度は？",
  "patient_foot_ankle_quality_of_life_q1_a1":"一度もない",
  "patient_foot_ankle_quality_of_life_q1_a2":"毎月",
  "patient_foot_ankle_quality_of_life_q1_a3":"毎週",
  "patient_foot_ankle_quality_of_life_q1_a4":"毎日",
  "patient_foot_ankle_quality_of_life_q1_a5":"常に",
  "patient_foot_ankle_quality_of_life_q2":"足/足首の問題を避けるためにライフスタイルを変えましたか？",
  "patient_foot_ankle_quality_of_life_q2_a1":"全くない",
  "patient_foot_ankle_quality_of_life_q2_a2":"少し",
  "patient_foot_ankle_quality_of_life_q2_a3":"中程度",
  "patient_foot_ankle_quality_of_life_q2_a4":"ひどく",
  "patient_foot_ankle_quality_of_life_q2_a5":"完全に",
  "patient_foot_ankle_quality_of_life_q3":"足/足首に自信がないため、どれほど痛みを感じていますか？",
  "patient_foot_ankle_quality_of_life_q3_a1":"全くない",
  "patient_foot_ankle_quality_of_life_q3_a2":"少し",
  "patient_foot_ankle_quality_of_life_q3_a3":"中程度",
  "patient_foot_ankle_quality_of_life_q3_a4":"ひどく",
  "patient_foot_ankle_quality_of_life_q3_a5":"極度に",
  "patient_foot_ankle_quality_of_life_q4":"一般的に、足/足首にどの程度支障がありますか？",
  "patient_foot_ankle_quality_of_life_q4_a1":"なし",
  "patient_foot_ankle_quality_of_life_q4_a2":"少し",
  "patient_foot_ankle_quality_of_life_q4_a3":"中程度",
  "patient_foot_ankle_quality_of_life_q4_a4":"深刻",
  "patient_foot_ankle_quality_of_life_q4_a5":"極度に",
  "patient_foot_ankle_quality_of_life_q5":"今日は不安/落ち込んでいますか？",
  "patient_foot_ankle_quality_of_life_q5_a1":"不安/落ち込んでいない",
  "patient_foot_ankle_quality_of_life_q5_a2":"少し",
  "patient_foot_ankle_quality_of_life_q5_a3":"中程度",
  "patient_foot_ankle_quality_of_life_q5_a4":"ひどく",
  "patient_foot_ankle_quality_of_life_q5_a5":"極度に",
  "patient_foot_ankle_quality_of_life_q6":"今日の健康はどれくらい良いですか？ （100は最高の健康であり、0は想像できる最悪の健康を意味します）",
  "patient_foot_ankle_quality_of_life_q6_a1":"最悪の健康",
  "patient_foot_ankle_quality_of_life_q6_a2":"最高の健康",
  "patient_foot_ankle_satisfaction":"満足度",
  "patient_foot_ankle_satisfaction_instruction":"足/足首の手術/治療の結果にどの程度満足していますか？",
  "patient_foot_ankle_satisfaction_instruction_a1":"非常に不満",
  "patient_foot_ankle_satisfaction_instruction_a2":"ある程度の不満",
  "patient_foot_ankle_satisfaction_instruction_a3":"やや満足",
  "patient_foot_ankle_satisfaction_instruction_a4":"非常に満足",
  "patient_foot_ankle_satisfaction_q1":"症状の緩和については？",
  "patient_foot_ankle_satisfaction_q1_not_applicable":"適用できない",
  "patient_foot_ankle_satisfaction_q1_a1":"非常に不満",
  "patient_foot_ankle_satisfaction_q1_a2":"ある程度の不満",
  "patient_foot_ankle_satisfaction_q1_a3":"やや満足",
  "patient_foot_ankle_satisfaction_q1_a4":"非常に満足",
  "patient_foot_ankle_satisfaction_q2":"日常活動の能力改善については？",
  "patient_foot_ankle_satisfaction_q2_not_applicable":"適用できない",
  "patient_foot_ankle_satisfaction_q2_a1":"非常に不満",
  "patient_foot_ankle_satisfaction_q2_a2":"ある程度の不満",
  "patient_foot_ankle_satisfaction_q2_a3":"やや満足",
  "patient_foot_ankle_satisfaction_q2_a4":"非常に満足",
  "patient_foot_ankle_satisfaction_q3":"足/足首の問題を「忘れた」かについては？ （足/足首の問題への意識）",
  "patient_foot_ankle_satisfaction_q3_not_applicable":"適用できない",
  "patient_foot_ankle_satisfaction_q3_a1":"非常に不満",
  "patient_foot_ankle_satisfaction_q3_a2":"ある程度の不満",
  "patient_foot_ankle_satisfaction_q3_a3":"やや満足",
  "patient_foot_ankle_satisfaction_q3_a4":"非常に満足",
  "patient_foot_ankle_complete":"アンケート終了",
  "patient_foot_ankle_complete_text1":"時間をかけてこの調査をご記入いただきありがとうございました。",
  "patient_foot_ankle_complete_text2":"[送信]をクリックして完了し、スコアをご確認ください。",
  "patient_foot_ankle_complete_text3":"回答は機密に扱われ、レポートは身分を識別されない情報のみを使用します。",
  "patient_foot_ankle_results":"結果",
  "patient_foot_ankle_results_text1":"以下は、アンケートから計算された痛みと足/足首の機能スコアです。",
  "patient_foot_ankle_results_pain":"痛み",
  "patient_foot_ankle_results_function":"機能",
  "patient_foot_ankle_results_overall_health_perception":"全体的な健康認識",
  "patient_foot_ankle_results_feeling_of_instability":"不安定",
  "patient_foot_ankle_results_self_evaluation":"足/足首スコア自己評価",
  "patient_foot_ankle_results_text2":"まもなく担当医が参ります。",
  "patient_elbow_pain":"痛み",
  "patient_elbow_pain_q1":"肘に痛みを感じますか？",
  "patient_elbow_pain_q1_a1":"いいえ",
  "patient_elbow_pain_q1_a2":"はい",
  "patient_elbow_pain_q2":"肘の痛みについて最良の説明は何ですか？",
  "patient_elbow_pain_q2_a1":"なしまたは無視できる",
  "patient_elbow_pain_q2_a2":"軽度で活動性があり、薬物/鎮痛剤を使用しないか、時々使用する。",
  "patient_elbow_pain_q2_a3":"中程度、活動の有無にかかわらず、鎮痛薬を毎日使用",
  "patient_elbow_pain_q2_a4":"安静時、夜間は中程度",
  "patient_elbow_pain_q2_a5":"休憩中の激しい痛み、継続的な薬物治療、行動障害",
  "patient_elbow_pain_instruction":"肘の痛みを評価してください",
  "patient_elbow_pain_q3":"今日",
  "patient_elbow_pain_q3_a1":"痛みなし",
  "patient_elbow_pain_q3_a2":"最悪の痛み",
  "patient_elbow_pain_q4":"過去4週間は一般的な痛み",
  "patient_elbow_pain_q4_a1":"痛みなし",
  "patient_elbow_pain_q4_a2":"最悪の痛み",
  "patient_elbow_pain_q5":"過去4週間で最悪のとき",
  "patient_elbow_pain_q5_a1":"痛みなし",
  "patient_elbow_pain_q5_a2":"最悪の痛み",
  "patient_elbow_pain_q6":"休憩中",
  "patient_elbow_pain_q6_a1":"痛みなし",
  "patient_elbow_pain_q6_a2":"最悪の痛み",
  "patient_elbow_pain_q7":"重いものを持ち上げる",
  "patient_elbow_pain_q7_a1":"痛みなし",
  "patient_elbow_pain_q7_a2":"最悪の痛み",
  "patient_elbow_pain_q8":"肘を繰り返し使う作業をする場合",
  "patient_elbow_pain_q8_a1":"痛みなし",
  "patient_elbow_pain_q8_a2":"最悪の痛み",
  "patient_elbow_pain_q9":"夜に",
  "patient_elbow_pain_q9_a1":"痛みなし",
  "patient_elbow_pain_q9_a2":"最悪の痛み",
  "patient_elbow_pain_q10":"夜のベッドで肘の痛みに悩まされている頻度はどれくらいですか？",
  "patient_elbow_pain_q10_a1":"全くない",
  "patient_elbow_pain_q10_a2":"一夜か二夜",
  "patient_elbow_pain_q10_a3":"いくつかの夜",
  "patient_elbow_pain_q10_a4":"ほとんどの夜",
  "patient_elbow_pain_q10_a5":"毎晩",
  "patient_elbow_pain_q11":"肘の痛みで睡眠が妨げられたことはどれくらいありますか？",
  "patient_elbow_pain_q11_a1":"全くない",
  "patient_elbow_pain_q11_a2":"たまに",
  "patient_elbow_pain_q11_a3":"時々",
  "patient_elbow_pain_q11_a4":"ほとんどの時間",
  "patient_elbow_pain_q11_a5":"いつも",
  "patient_elbow_function":"機能",
  "patient_elbow_function_q1":"肘の機能について最良の説明は何ですか？",
  "patient_elbow_function_q1_a1":"制限はありません",
  "patient_elbow_function_q1_a2":"いくつかの制限、日常生活の活動に関する制限はない",
  "patient_elbow_function_q1_a3":"4.5kg以上の物を持ち上げることができない",
  "patient_elbow_function_q1_a4":"日常生活の活動における中程度の制限",
  "patient_elbow_function_q1_a5":"髪をとかすことも、頭を触ることもできない",
  "patient_elbow_function_q1_a6":"食事を自分で摂ることができない",
  "patient_elbow_function_q2":"あなたができる活動について最良の説明は何ですか？",
  "patient_elbow_function_q2_a1":"肘の使用に制限なし",
  "patient_elbow_function_q2_a2":"余暇の目的に限られる",
  "patient_elbow_function_q2_a3":"家事/仕事関連の活動に限られる",
  "patient_elbow_function_q2_a4":"自分の世話をすることができる",
  "patient_elbow_function_q2_a5":"使用不可",
  "patient_elbow_function_q3":"肘をどのくらい使用できるか？",
  "patient_elbow_function_q3_a1":"30分間活動可能",
  "patient_elbow_function_q3_a2":"15分間活動可能",
  "patient_elbow_function_q3_a3":"5分間活動可能",
  "patient_elbow_function_q3_a4":"肘は使用できない",
  "patient_elbow_function_instruction":"最近1週間で肘のため、次の活動で経験した困難の程度を示してください。",
  "patient_elbow_function_instruction_0":"困難なし",
  "patient_elbow_function_instruction_1":"少し",
  "patient_elbow_function_instruction_2":"中程度",
  "patient_elbow_function_instruction_3":"深刻",
  "patient_elbow_function_instruction_4":"不可能",
  "patient_elbow_function_q4":"固いまたは新しい瓶を開ける",
  "patient_elbow_function_q4_a1":"困難なし",
  "patient_elbow_function_q4_a2":"少し",
  "patient_elbow_function_q4_a3":"中程度",
  "patient_elbow_function_q4_a4":"深刻",
  "patient_elbow_function_q4_a5":"不可能",
  "patient_elbow_function_q5":"買い物袋やブリーフケースを持ち歩く",
  "patient_elbow_function_q5_a1":"困難なし",
  "patient_elbow_function_q5_a2":"少し",
  "patient_elbow_function_q5_a3":"中程度",
  "patient_elbow_function_q5_a4":"深刻",
  "patient_elbow_function_q5_a5":"不可能",
  "patient_elbow_function_q6":"ゴミ出すなど、家の中のものを持ち上げる",
  "patient_elbow_function_q6_a1":"困難なし",
  "patient_elbow_function_q6_a2":"少し",
  "patient_elbow_function_q6_a3":"中程度",
  "patient_elbow_function_q6_a4":"深刻",
  "patient_elbow_function_q6_a5":"不可能",
  "patient_elbow_function_q7":"背中を洗う",
  "patient_elbow_function_q7_a1":"困難なし",
  "patient_elbow_function_q7_a2":"少し",
  "patient_elbow_function_q7_a3":"中程度",
  "patient_elbow_function_q7_a4":"深刻",
  "patient_elbow_function_q7_a5":"不可能",
  "patient_elbow_function_q8":"トイレでお尻を拭く",
  "patient_elbow_function_q8_a1":"困難なし",
  "patient_elbow_function_q8_a2":"少し",
  "patient_elbow_function_q8_a3":"中程度",
  "patient_elbow_function_q8_a4":"深刻",
  "patient_elbow_function_q8_a5":"不可能",
  "patient_elbow_function_q9":"全身を洗う",
  "patient_elbow_function_q9_a1":"困難なし",
  "patient_elbow_function_q9_a2":"少し",
  "patient_elbow_function_q9_a3":"中程度",
  "patient_elbow_function_q9_a4":"深刻",
  "patient_elbow_function_q9_a5":"不可能",
  "patient_elbow_function_q10":"個人衛生を実行",
  "patient_elbow_function_q10_a1":"困難なし",
  "patient_elbow_function_q10_a2":"少し",
  "patient_elbow_function_q10_a3":"中程度",
  "patient_elbow_function_q10_a4":"深刻",
  "patient_elbow_function_q10_a5":"不可能",
  "patient_elbow_function_q11":"ナイフを使って食べ物を切る",
  "patient_elbow_function_q11_a1":"困難なし",
  "patient_elbow_function_q11_a2":"少し",
  "patient_elbow_function_q11_a3":"中程度",
  "patient_elbow_function_q11_a4":"深刻",
  "patient_elbow_function_q11_a5":"不可能",
  "patient_elbow_function_q12":"道具を使って食べる",
  "patient_elbow_function_q12_a1":"困難なし",
  "patient_elbow_function_q12_a2":"少し",
  "patient_elbow_function_q12_a3":"中程度",
  "patient_elbow_function_q12_a4":"深刻",
  "patient_elbow_function_q12_a5":"不可能",
  "patient_elbow_function_q13":"腕、肩、手を通して何らかの力や衝撃を受けるレジャー活動（例：ゴルフ、ハンマー打ち、テニスなど）",
  "patient_elbow_function_q13_a1":"困難なし",
  "patient_elbow_function_q13_a2":"少し",
  "patient_elbow_function_q13_a3":"中程度",
  "patient_elbow_function_q13_a4":"深刻",
  "patient_elbow_function_q13_a5":"不可能",
  "patient_elbow_function_q14":"腕、肩、手の痛みによって引き起こされる睡眠障害",
  "patient_elbow_function_q14_a1":"困難なし",
  "patient_elbow_function_q14_a2":"少し",
  "patient_elbow_function_q14_a3":"中程度",
  "patient_elbow_function_q14_a4":"深刻",
  "patient_elbow_function_q14_a5":"不可能",
  "patient_elbow_function_q15":"シャツ着用",
  "patient_elbow_function_q15_a1":"困難なし",
  "patient_elbow_function_q15_a2":"少し",
  "patient_elbow_function_q15_a3":"中程度",
  "patient_elbow_function_q15_a4":"深刻",
  "patient_elbow_function_q15_a5":"不可能",
  "patient_elbow_function_q16":"シャツの一番上のボタンを留める",
  "patient_elbow_function_q16_a1":"困難なし",
  "patient_elbow_function_q16_a2":"少し",
  "patient_elbow_function_q16_a3":"中程度",
  "patient_elbow_function_q16_a4":"深刻",
  "patient_elbow_function_q16_a5":"不可能",
  "patient_elbow_function_q17":"自分に服を着せる",
  "patient_elbow_function_q17_a1":"困難なし",
  "patient_elbow_function_q17_a2":"少し",
  "patient_elbow_function_q17_a3":"中程度",
  "patient_elbow_function_q17_a4":"深刻",
  "patient_elbow_function_q17_a5":"不可能",
  "patient_elbow_function_q18":"髪をとかす",
  "patient_elbow_function_q18_a1":"困難なし",
  "patient_elbow_function_q18_a2":"少し",
  "patient_elbow_function_q18_a3":"中程度",
  "patient_elbow_function_q18_a4":"深刻",
  "patient_elbow_function_q18_a5":"不可能",
  "patient_elbow_function_q19":"靴を履く",
  "patient_elbow_function_q19_a1":"困難なし",
  "patient_elbow_function_q19_a2":"少し",
  "patient_elbow_function_q19_a3":"中程度",
  "patient_elbow_function_q19_a4":"深刻",
  "patient_elbow_function_q19_a5":"不可能",
  "patient_elbow_function_q20":"靴紐を結ぶ",
  "patient_elbow_function_q20_a1":"困難なし",
  "patient_elbow_function_q20_a2":"少し",
  "patient_elbow_function_q20_a3":"中程度",
  "patient_elbow_function_q20_a4":"深刻",
  "patient_elbow_function_q20_a5":"不可能",
  "patient_elbow_function_q21":"重い物を運ぶ",
  "patient_elbow_function_q21_a1":"困難なし",
  "patient_elbow_function_q21_a2":"少し",
  "patient_elbow_function_q21_a3":"中程度",
  "patient_elbow_function_q21_a4":"深刻",
  "patient_elbow_function_q21_a5":"不可能",
  "patient_elbow_function_q22":"腕を押しながら椅子から立ち上がる",
  "patient_elbow_function_q22_a1":"困難なし",
  "patient_elbow_function_q22_a2":"少し",
  "patient_elbow_function_q22_a3":"中程度",
  "patient_elbow_function_q22_a4":"深刻",
  "patient_elbow_function_q22_a5":"不可能",
  "patient_elbow_function_q23":"重い家事（例：壁の掃除、床の掃除）",
  "patient_elbow_function_q23_a1":"困難なし",
  "patient_elbow_function_q23_a2":"少し",
  "patient_elbow_function_q23_a3":"中程度",
  "patient_elbow_function_q23_a4":"深刻",
  "patient_elbow_function_q23_a5":"不可能",
  "patient_elbow_function_q24":"鍵を回す",
  "patient_elbow_function_q24_a1":"困難なし",
  "patient_elbow_function_q24_a2":"少し",
  "patient_elbow_function_q24_a3":"中程度",
  "patient_elbow_function_q24_a4":"深刻",
  "patient_elbow_function_q24_a5":"不可能",
  "patient_elbow_function_q25":"ボールを投げる",
  "patient_elbow_function_q25_a1":"困難なし",
  "patient_elbow_function_q25_a2":"少し",
  "patient_elbow_function_q25_a3":"中程度",
  "patient_elbow_function_q25_a4":"深刻",
  "patient_elbow_function_q25_a5":"不可能",
  "patient_elbow_function_q26":"腕、肩、手の痛みのため、普段の仕事ができない（家事が主な役割であれば、家事も含まれる）",
  "patient_elbow_function_q26_a1":"困難なし",
  "patient_elbow_function_q26_a2":"少し",
  "patient_elbow_function_q26_a3":"中程度",
  "patient_elbow_function_q26_a4":"深刻",
  "patient_elbow_function_q26_a5":"不可能",
  "patient_elbow_function_q27":"普段の仕事を指定してください",
  "patient_elbow_function_q27_placeholder":"仕事を指定してください",
  "patient_elbow_function_i_donot_work":"私は働かない",
  "patient_elbow_function_q28":"いつものテクニックで仕事をする",
  "patient_elbow_function_q28_a1":"困難なし",
  "patient_elbow_function_q28_a2":"少し",
  "patient_elbow_function_q28_a3":"中程度",
  "patient_elbow_function_q28_a4":"深刻",
  "patient_elbow_function_q28_a5":"不可能",
  "patient_elbow_function_q29":"思い通りに仕事をこなす",
  "patient_elbow_function_q29_a1":"困難なし",
  "patient_elbow_function_q29_a2":"少し",
  "patient_elbow_function_q29_a3":"中程度",
  "patient_elbow_function_q29_a4":"深刻",
  "patient_elbow_function_q29_a5":"不可能",
  "patient_elbow_function_q30":"いつも通りの時間を仕事に費やす",
  "patient_elbow_function_q30_a1":"困難なし",
  "patient_elbow_function_q30_a2":"少し",
  "patient_elbow_function_q30_a3":"中程度",
  "patient_elbow_function_q30_a4":"深刻",
  "patient_elbow_function_q30_a5":"不可能",
  "patient_elbow_function_q31":"腕、肩、または手の痛みのために楽器やスポーツをすることが困難",
  "patient_elbow_function_q31_a1":"困難なし",
  "patient_elbow_function_q31_a2":"少し",
  "patient_elbow_function_q31_a3":"中程度",
  "patient_elbow_function_q31_a4":"深刻",
  "patient_elbow_function_q31_a5":"不可能",
  "patient_elbow_function_q32":"スポーツや楽器を指定してください",
  "patient_elbow_function_q32_placeholder":"スポーツ/楽器を指定して",
  "patient_elbow_function_i_donot_play":"スポーツや楽器はしない",
  "patient_elbow_function_q33":"普段の楽器やスポーツのテクニックを使う",
  "patient_elbow_function_q33_a1":"困難なし",
  "patient_elbow_function_q33_a2":"少し",
  "patient_elbow_function_q33_a3":"中程度",
  "patient_elbow_function_q33_a4":"深刻",
  "patient_elbow_function_q33_a5":"不可能",
  "patient_elbow_function_q34":"楽器やスポーツを思うように演奏する",
  "patient_elbow_function_q34_a1":"困難なし",
  "patient_elbow_function_q34_a2":"少し",
  "patient_elbow_function_q34_a3":"中程度",
  "patient_elbow_function_q34_a4":"深刻",
  "patient_elbow_function_q34_a5":"不可能",
  "patient_elbow_function_q35":"楽器やスポーツの練習、プレーするのに通常の時間を費やす",
  "patient_elbow_function_q35_a1":"困難なし",
  "patient_elbow_function_q35_a2":"少し",
  "patient_elbow_function_q35_a3":"中程度",
  "patient_elbow_function_q35_a4":"深刻",
  "patient_elbow_function_q35_a5":"不可能",
  "patient_elbow_function_q36":"過去1週間、腕、肩、または手の問題は、家族、友人、隣人、またはグループとの通常のソーシャル/レジャー活動をどの程度妨げましたか？",
  "patient_elbow_function_q36_a1":"全くない",
  "patient_elbow_function_q36_a2":"少し",
  "patient_elbow_function_q36_a3":"中程度",
  "patient_elbow_function_q36_a4":"かなり",
  "patient_elbow_function_q36_a5":"極度に",
  "patient_elbow_function_q37":"過去1週間腕、肩、または手の問題のために、仕事や他の日常的な活動に制限されていますか？",
  "patient_elbow_function_q37_a1":"全くない",
  "patient_elbow_function_q37_a2":"少し",
  "patient_elbow_function_q37_a3":"中程度",
  "patient_elbow_function_q37_a4":"かなり",
  "patient_elbow_function_q37_a5":"極度に",
  "patient_elbow_symptoms":"その他の症状",
  "patient_elbow_symptoms_instruction":"先週の次の症状の深刻さを評価してください：",
  "patient_elbow_symptoms_instruction_0":"なし",
  "patient_elbow_symptoms_instruction_1":"少し",
  "patient_elbow_symptoms_instruction_2":"中程度",
  "patient_elbow_symptoms_instruction_3":"深刻",
  "patient_elbow_symptoms_instruction_4":"極度に",
  "patient_elbow_symptoms_q1":"腕、肩、または手の痛み",
  "patient_elbow_symptoms_q1_a1":"なし",
  "patient_elbow_symptoms_q1_a2":"少し",
  "patient_elbow_symptoms_q1_a3":"中程度",
  "patient_elbow_symptoms_q1_a4":"深刻",
  "patient_elbow_symptoms_q1_a5":"極度に",
  "patient_elbow_symptoms_q2":"腕、肩、手のうずき（針やピンに刺されたような痛み）",
  "patient_elbow_symptoms_q2_a1":"なし",
  "patient_elbow_symptoms_q2_a2":"少し",
  "patient_elbow_symptoms_q2_a3":"中程度",
  "patient_elbow_symptoms_q2_a4":"深刻",
  "patient_elbow_symptoms_q2_a5":"極度に",
  "patient_elbow_symptoms_q3":"今日の肘をどのように評価しますか？ （0〜100％、100％が正常）",
  "patient_elbow_quality_of_life":"生活の質",
  "patient_elbow_quality_of_life_instruction":"以下は、肘の問題に感じる社会的、感情的、ライフスタイルに関する質問です。肘について通常感じていることを考えてください。",
  "patient_elbow_quality_of_life_q1":"過去4週間、肘の問題が「あなたの人生を支配している」と感じたことがありますか？",
  "patient_elbow_quality_of_life_q1_a1":"全くない",
  "patient_elbow_quality_of_life_q1_a2":"たまに",
  "patient_elbow_quality_of_life_q1_a3":"時々",
  "patient_elbow_quality_of_life_q1_a4":"ほとんどの日",
  "patient_elbow_quality_of_life_q1_a5":"毎日",
  "patient_elbow_quality_of_life_q2":"過去4週間で肘をどれだけ心配していましたか？",
  "patient_elbow_quality_of_life_q2_a1":"全くない",
  "patient_elbow_quality_of_life_q2_a2":"少し",
  "patient_elbow_quality_of_life_q2_a3":"時々",
  "patient_elbow_quality_of_life_q2_a4":"ほとんどの時間",
  "patient_elbow_quality_of_life_q2_a5":"いつも",
  "patient_elbow_quality_of_life_instruction1":"今日の健康について最良の説明を選択してください。",
  "patient_elbow_quality_of_life_instruction1_0":"問題ない",
  "patient_elbow_quality_of_life_instruction1_1":"少し",
  "patient_elbow_quality_of_life_instruction1_2":"中程度",
  "patient_elbow_quality_of_life_instruction1_3":"深刻",
  "patient_elbow_quality_of_life_instruction1_4":"極度に不可能",
  "patient_elbow_quality_of_life_q3":"歩くときの問題",
  "patient_elbow_quality_of_life_q3_a1":"問題ない",
  "patient_elbow_quality_of_life_q3_a2":"少し",
  "patient_elbow_quality_of_life_q3_a3":"中程度",
  "patient_elbow_quality_of_life_q3_a4":"深刻",
  "patient_elbow_quality_of_life_q3_a5":"不可能",
  "patient_elbow_quality_of_life_q4":"体の洗浄や着替えの問題",
  "patient_elbow_quality_of_life_q4_a1":"問題ない",
  "patient_elbow_quality_of_life_q4_a2":"少し",
  "patient_elbow_quality_of_life_q4_a3":"中程度",
  "patient_elbow_quality_of_life_q4_a4":"深刻",
  "patient_elbow_quality_of_life_q4_a5":"不可能",
  "patient_elbow_quality_of_life_q5":"通常の活動（仕事、勉強、家事、家族活動、余暇活動など）に支障",
  "patient_elbow_quality_of_life_q5_a1":"問題ない",
  "patient_elbow_quality_of_life_q5_a2":"少し",
  "patient_elbow_quality_of_life_q5_a3":"中程度",
  "patient_elbow_quality_of_life_q5_a4":"深刻",
  "patient_elbow_quality_of_life_q5_a5":"不可能",
  "patient_elbow_quality_of_life_q6":"痛みや不便",
  "patient_elbow_quality_of_life_q6_a1":"なし",
  "patient_elbow_quality_of_life_q6_a2":"少し",
  "patient_elbow_quality_of_life_q6_a3":"中程度",
  "patient_elbow_quality_of_life_q6_a4":"深刻",
  "patient_elbow_quality_of_life_q6_a5":"極度に",
  "patient_elbow_quality_of_life_q7":"不安/うつ病",
  "patient_elbow_quality_of_life_q7_a1":"不安/落ち込んでいない",
  "patient_elbow_quality_of_life_q7_a2":"少し不安/うつ病",
  "patient_elbow_quality_of_life_q7_a3":"媒体/うつ病の不安",
  "patient_elbow_quality_of_life_q7_a4":"深刻不安/うつ病",
  "patient_elbow_quality_of_life_q7_a5":"極度に不安/うつ病",
  "patient_elbow_quality_of_life_q8":"今日の健康はどれくらい良いですか？",
  "patient_elbow_quality_of_life_q8_instruction":"100は最高の健康を意味し、0はあなたが想像できる最悪の健康です。",
  "patient_elbow_quality_of_life_q8_a1":"最悪",
  "patient_elbow_quality_of_life_q8_a2":"最高",
  "patient_elbow_satisfaction":"満足度",
  "patient_elbow_satisfaction_q1":"肘の治療に満足していますか？",
  "patient_elbow_satisfaction_na":"適用できない",
  "patient_elbow_satisfaction_q1_a1":"まったく満足していません",
  "patient_elbow_satisfaction_q1_a2":"非常に満足",
  "patient_elbow_complete":"アンケート終了",
  "patient_elbow_complete_text1":"時間をかけてこの調査をご記入いただきありがとうございました。",
  "patient_elbow_complete_text2":"[送信]をクリックして完了し、スコアをご確認ください。",
  "patient_elbow_complete_text3":"あなたの答えは機密として処理され、すべてのレポートは正体不明の情報のみが使用されます。",
  "patient_elbow_results":"結果",
  "patient_elbow_results_text1":"以下は、アンケートから計算された痛みと肘の機能スコアです。",
  "patient_elbow_results_function":"機能",
  "patient_elbow_results_pain":"痛み",
  "patient_elbow_results_health_perception":"全体的な健康認識",
  "patient_elbow_results_self_evaluation":"肘スコア自己評価",
  "patient_elbow_results_text4":"まもなく担当医が参ります。",
  "patient_hand_pain":"痛み",
  "patient_hand_pain_q1":"先週の手/手首の痛みを説明してください。",
  "patient_hand_pain_q1_a1":"なしまたはとても軽い",
  "patient_hand_pain_q1_a2":"少し",
  "patient_hand_pain_q1_a3":"中程度",
  "patient_hand_pain_q1_a4":"深刻",
  "patient_hand_pain_q1_a5":"非常に深刻/極度に",
  "patient_hand_pain_q2":"現在の手/手首の痛みはどれほど深刻ですか？",
  "patient_hand_pain_q2_a1":"痛みなし",
  "patient_hand_pain_q2_a2":"最悪",
  "patient_hand_pain_q3":"あなたの手/手首の痛みは、過去1週間にあなたの日常の活動（食事や入浴など）にどのくらいの頻度で妨げましたか？",
  "patient_hand_pain_q3_a1":"一度もない",
  "patient_hand_pain_q3_a2":"めったに",
  "patient_hand_pain_q3_a3":"時々",
  "patient_hand_pain_q3_a4":"頻繁",
  "patient_hand_pain_q3_a5":"いつも",
  "patient_hand_symptoms":"その他の症状",
  "patient_hand_symptoms_q1":"先週腕、肩、手のうずき（針やピンに刺されたような痛み）はどれほど悪いのでしたか？",
  "patient_hand_symptoms_q1_a1":"なし",
  "patient_hand_symptoms_q1_a2":"少し",
  "patient_hand_symptoms_q1_a3":"中程度",
  "patient_hand_symptoms_q1_a4":"深刻",
  "patient_hand_symptoms_q1_a5":"極度に",
  "patient_hand_symptoms_q2":"過去1週間の感覚（感情）はどうでしたか？",
  "patient_hand_symptoms_q2_a1":"とても良い",
  "patient_hand_symptoms_q2_a2":"良い",
  "patient_hand_symptoms_q2_a3":"一般的に",
  "patient_hand_symptoms_q2_a4":"悪い",
  "patient_hand_symptoms_q2_a5":"ひどい",
  "patient_hand_symptoms_q3":"手の外観は通常の毎日活動を妨害しますか？",
  "patient_hand_symptoms_q3_a1":"大いに同意しない",
  "patient_hand_symptoms_q3_a2":"同意しない",
  "patient_hand_symptoms_q3_a3":"中立",
  "patient_hand_symptoms_q3_a4":"同意する",
  "patient_hand_symptoms_q3_a5":"強く同意する",
  "patient_hand_symptoms_q3_a6":"今日の手/手首をどのように評価しますか？ （0〜100％、100％が正常）",
  "patient_hand_symptoms_q3_a6_normal":"普通",
  "patient_hand_function":"機能",
  "patient_hand_function_instruction1":"ある仕事をしない場合、その仕事をすることの難易度を推定してください。",
  "patient_hand_function_instruction2":"質問に答える方法がわからない場合は、できる限り最良の答えをお願いします。",
  "patient_hand_function_q1":"過去1週間、手は全体的にどれだけうまく機能しましたか？",
  "patient_hand_function_q1_a1":"とても良い",
  "patient_hand_function_q1_a2":"良い",
  "patient_hand_function_q1_a3":"一般的に",
  "patient_hand_function_q1_a4":"悪い",
  "patient_hand_function_q1_a5":"ひどい",
  "patient_hand_function_q2":"手や手首の問題で仕事ができないことがよくありましたか？",
  "patient_hand_function_q2_a1":"一度もない",
  "patient_hand_function_q2_a2":"めったに",
  "patient_hand_function_q2_a3":"時々",
  "patient_hand_function_q2_a4":"頻繁",
  "patient_hand_function_q2_a5":"いつも",
  "patient_hand_function_q3":"手や手首の問題で仕事をする時間がかかったことがありますか？",
  "patient_hand_function_q3_a1":"一度もない",
  "patient_hand_function_q3_a2":"めったに",
  "patient_hand_function_q3_a3":"時々",
  "patient_hand_function_q3_a4":"頻繁",
  "patient_hand_function_q3_a5":"いつも",
  "patient_hand_function_instruction":"最近1週間で手/手首のため、次の活動で経験した困難の程度を示してください。",
  "patient_hand_function_instruction_0":"困難なし",
  "patient_hand_function_instruction_1":"少し難しい",
  "patient_hand_function_instruction_2":"いくつかの困難がある",
  "patient_hand_function_instruction_3":"深刻困難",
  "patient_hand_function_instruction_4":"極度の困難/不可能",
  "patient_hand_function_q4":"固いまたは新しい瓶を開ける",
  "patient_hand_function_q4_a1":"困難なし",
  "patient_hand_function_q4_a2":"少し難しい",
  "patient_hand_function_q4_a3":"いくつかの困難がある",
  "patient_hand_function_q4_a4":"深刻困難",
  "patient_hand_function_q4_a5":"極度の困難/不可能",
  "patient_hand_function_q5":"フライパンを持つ",
  "patient_hand_function_q5_a1":"困難なし",
  "patient_hand_function_q5_a2":"少し難しい",
  "patient_hand_function_q5_a3":"いくつかの困難がある",
  "patient_hand_function_q5_a4":"深刻困難",
  "patient_hand_function_q5_a5":"極度の困難/不可能",
  "patient_hand_function_q6":"シャツやブラウスのボタンを留める",
  "patient_hand_function_q6_a1":"困難なし",
  "patient_hand_function_q6_a2":"少し難しい",
  "patient_hand_function_q6_a3":"いくつかの困難がある",
  "patient_hand_function_q6_a4":"深刻困難",
  "patient_hand_function_q6_a5":"極度の困難/不可能",
  "patient_hand_function_q7":"重い家事（例：壁の掃除、床の掃除）",
  "patient_hand_function_q7_a1":"困難なし",
  "patient_hand_function_q7_a2":"少し難しい",
  "patient_hand_function_q7_a3":"いくつかの困難がある",
  "patient_hand_function_q7_a4":"深刻困難",
  "patient_hand_function_q7_a5":"極度の困難/不可能",
  "patient_hand_function_q8":"買い物袋やブリーフケースを持ち歩く",
  "patient_hand_function_q8_a1":"困難なし",
  "patient_hand_function_q8_a2":"少し難しい",
  "patient_hand_function_q8_a3":"いくつかの困難がある",
  "patient_hand_function_q8_a4":"深刻困難",
  "patient_hand_function_q8_a5":"極度の困難/不可能",
  "patient_hand_function_q9":"背中を洗う",
  "patient_hand_function_q9_a1":"困難なし",
  "patient_hand_function_q9_a2":"少し難しい",
  "patient_hand_function_q9_a3":"いくつかの困難がある",
  "patient_hand_function_q9_a4":"深刻困難",
  "patient_hand_function_q9_a5":"極度の困難/不可能",
  "patient_hand_function_q10":"ナイフを使って食べ物を切る",
  "patient_hand_function_q10_a1":"困難なし",
  "patient_hand_function_q10_a2":"少し難しい",
  "patient_hand_function_q10_a3":"いくつかの困難がある",
  "patient_hand_function_q10_a4":"深刻困難",
  "patient_hand_function_q10_a5":"極度の困難/不可能",
  "patient_hand_function_q11":"腕、肩、手を通して何らかの力や衝撃を受けるレジャー活動（例：ゴルフ、ハンマー打ち、テニスなど）",
  "patient_hand_function_q11_a1":"困難なし",
  "patient_hand_function_q11_a2":"少し難しい",
  "patient_hand_function_q11_a3":"いくつかの困難がある",
  "patient_hand_function_q11_a4":"深刻困難",
  "patient_hand_function_q11_a5":"極度の困難/不可能",
  "patient_hand_function_q12":"腕、肩、手の痛みによって引き起こされる睡眠障害",
  "patient_hand_function_q12_a1":"困難なし",
  "patient_hand_function_q12_a2":"少し難しい",
  "patient_hand_function_q12_a3":"いくつかの困難がある",
  "patient_hand_function_q12_a4":"深刻困難",
  "patient_hand_function_q12_a5":"極度の困難/不可能",
  "patient_hand_function_q13":"腕、肩、手の痛みのため、普段の仕事ができない（家事が主な役割であれば、家事も含まれる）",
  "patient_hand_function_q13_a1":"困難なし",
  "patient_hand_function_q13_a2":"少し難しい",
  "patient_hand_function_q13_a3":"いくつかの困難がある",
  "patient_hand_function_q13_a4":"深刻困難",
  "patient_hand_function_q13_a5":"極度の困難/不可能",
  "patient_hand_function_q14":"普段の仕事を指定してください",
  "patient_hand_function_q14_placeholder":"仕事を指定してください",
  "patient_hand_function_do_dot_work":"私は働いていません",
  "patient_hand_function_q15":"いつものテクニックで仕事をする",
  "patient_hand_function_q15_a1":"困難なし",
  "patient_hand_function_q15_a2":"少し難しい",
  "patient_hand_function_q15_a3":"いくつかの困難がある",
  "patient_hand_function_q15_a4":"深刻困難",
  "patient_hand_function_q15_a5":"極度の困難/不可能",
  "patient_hand_function_q16":"思い通りに仕事をこなす",
  "patient_hand_function_q16_a1":"困難なし",
  "patient_hand_function_q16_a2":"少し難しい",
  "patient_hand_function_q16_a3":"いくつかの困難がある",
  "patient_hand_function_q16_a4":"深刻困難",
  "patient_hand_function_q16_a5":"極度の困難/不可能",
  "patient_hand_function_q17":"いつも通りの時間を仕事に費やす",
  "patient_hand_function_q17_a1":"困難なし",
  "patient_hand_function_q17_a2":"少し難しい",
  "patient_hand_function_q17_a3":"いくつかの困難がある",
  "patient_hand_function_q17_a4":"深刻困難",
  "patient_hand_function_q17_a5":"極度の困難/不可能",
  "patient_hand_function_q18":"腕、肩、または手の痛みのために楽器やスポーツをする",
  "patient_hand_function_q18_a1":"困難なし",
  "patient_hand_function_q18_a2":"少し難しい",
  "patient_hand_function_q18_a3":"いくつかの困難がある",
  "patient_hand_function_q18_a4":"深刻困難",
  "patient_hand_function_q18_a5":"極度の困難/不可能",
  "patient_hand_function_q19":"スポーツや楽器を指定してください",
  "patient_hand_function_q19_placeholder":"スポーツ/楽器を指定して",
  "patient_hand_function_donot_play":"スポーツや楽器はしない",
  "patient_hand_function_20":"普段の楽器やスポーツのテクニックを使う",
  "patient_hand_function_q20_a1":"困難なし",
  "patient_hand_function_q20_a2":"少し難しい",
  "patient_hand_function_q20_a3":"いくつかの困難がある",
  "patient_hand_function_q20_a4":"深刻困難",
  "patient_hand_function_q20_a5":"極度の困難/不可能",
  "patient_hand_function_q21":"楽器やスポーツを思うように演奏する",
  "patient_hand_function_q21_a1":"困難なし",
  "patient_hand_function_q21_a2":"少し難しい",
  "patient_hand_function_q21_a3":"いくつかの困難がある",
  "patient_hand_function_q21_a4":"深刻困難",
  "patient_hand_function_q21_a5":"極度の困難/不可能",
  "patient_hand_function_q22":"楽器やスポーツの練習、プレーするのに通常の時間を費やす",
  "patient_hand_function_q22_a1":"困難なし",
  "patient_hand_function_q22_a2":"少し難しい",
  "patient_hand_function_q22_a3":"いくつかの困難がある",
  "patient_hand_function_q22_a4":"深刻困難",
  "patient_hand_function_q22_a5":"極度の困難/不可能",
  "patient_hand_function_q23":"過去1週間、腕、肩、または手の問題は、家族、友人、隣人、またはグループとの通常のソーシャル/レジャー活動をどの程度妨げましたか？",
  "patient_hand_function_q23_a1":"全くない",
  "patient_hand_function_q23_a2":"少し",
  "patient_hand_function_q23_a3":"中程度",
  "patient_hand_function_q23_a4":"かなり",
  "patient_hand_function_q23_a5":"極度に",
  "patient_hand_function_q24":"過去1週間腕、肩、または手の問題のために、仕事や他の日常的な活動に制限されていますか？",
  "patient_hand_function_q24_a1":"全くない",
  "patient_hand_function_q24_a2":"少し",
  "patient_hand_function_q24_a3":"中程度",
  "patient_hand_function_q24_a4":"かなり",
  "patient_hand_function_q24_a5":"極度に",
  "patient_hand_questionnaire":"ボストン手根管症候群アンケート",
  "patient_hand_questionnaire_symptom":"症状",
  "patient_hand_questionnaire_symptom_instruction":"あなたの症状に最適な答えを選択してください。",
  "patient_hand_questionnaire_symptom_instruction_0":"普通",
  "patient_hand_questionnaire_symptom_instruction_1":"少し",
  "patient_hand_questionnaire_symptom_instruction_2":"中くらい",
  "patient_hand_questionnaire_symptom_instruction_3":"深刻",
  "patient_hand_questionnaire_symptom_instruction_4":"極度に深刻です",
  "patient_hand_questionnaire_symptom_q1":"夜に手や手首の痛みはどれほど深刻ですか？",
  "patient_hand_questionnaire_symptom_q1_a1":"普通",
  "patient_hand_questionnaire_symptom_q1_a2":"少し",
  "patient_hand_questionnaire_symptom_q1_a3":"中くらい",
  "patient_hand_questionnaire_symptom_q1_a4":"深刻",
  "patient_hand_questionnaire_symptom_q1_a5":"極度に深刻です",
  "patient_hand_questionnaire_symptom_q2":"過去2週間、手または手首の痛みで目覚める頻度はどれくらいですか？",
  "patient_hand_questionnaire_symptom_q2_a1":"普通",
  "patient_hand_questionnaire_symptom_q2_a2":"一度",
  "patient_hand_questionnaire_symptom_q2_a3":"2-3回",
  "patient_hand_questionnaire_symptom_q2_a4":"4-5回",
  "patient_hand_questionnaire_symptom_q2_a5":"5回以上",
  "patient_hand_questionnaire_symptom_q3":"一般的に、日中は手や手首に痛みを感じますか？",
  "patient_hand_questionnaire_symptom_q3_a1":"痛みなし",
  "patient_hand_questionnaire_symptom_q3_a2":"少し",
  "patient_hand_questionnaire_symptom_q3_a3":"中くらい",
  "patient_hand_questionnaire_symptom_q3_a4":"深刻",
  "patient_hand_questionnaire_symptom_q3_a5":"極度に深刻です",
  "patient_hand_questionnaire_symptom_q4":"日中はどのくらいの頻度で手または手首の痛みを感じますか？",
  "patient_hand_questionnaire_symptom_q4_a1":"普通",
  "patient_hand_questionnaire_symptom_q4_a2":"毎日1〜2回",
  "patient_hand_questionnaire_symptom_q4_a3":"毎日3〜5回",
  "patient_hand_questionnaire_symptom_q4_a4":"1日5回以上",
  "patient_hand_questionnaire_symptom_q4_a5":"続ける",
  "patient_hand_questionnaire_symptom_q5":"日中、痛みの発作は平均どのくらい続きますか？",
  "patient_hand_questionnaire_symptom_q5_a1":"普通",
  "patient_hand_questionnaire_symptom_q5_a2":"<10分",
  "patient_hand_questionnaire_symptom_q5_a3":"10-60分続けた",
  "patient_hand_questionnaire_symptom_q5_a4":"> 60分",
  "patient_hand_questionnaire_symptom_q5_a5":"続ける",
  "patient_hand_questionnaire_symptom_q6":"手にしびれ（感覚の喪失）がありますか？",
  "patient_hand_questionnaire_symptom_q6_a1":"普通",
  "patient_hand_questionnaire_symptom_q6_a2":"少し",
  "patient_hand_questionnaire_symptom_q6_a3":"中くらい",
  "patient_hand_questionnaire_symptom_q6_a4":"深刻",
  "patient_hand_questionnaire_symptom_q6_a5":"極度に深刻です",
  "patient_hand_questionnaire_symptom_q7":"手や手首の力が弱いですか？",
  "patient_hand_questionnaire_symptom_q7_a1":"普通",
  "patient_hand_questionnaire_symptom_q7_a2":"少し",
  "patient_hand_questionnaire_symptom_q7_a3":"中くらい",
  "patient_hand_questionnaire_symptom_q7_a4":"深刻",
  "patient_hand_questionnaire_symptom_q7_a5":"極度に深刻です",
  "patient_hand_questionnaire_symptom_q8":"手にチクチク感がありますか？",
  "patient_hand_questionnaire_symptom_q8_a1":"普通",
  "patient_hand_questionnaire_symptom_q8_a2":"少し",
  "patient_hand_questionnaire_symptom_q8_a3":"中くらい",
  "patient_hand_questionnaire_symptom_q8_a4":"深刻",
  "patient_hand_questionnaire_symptom_q8_a5":"極度に深刻です",
  "patient_hand_questionnaire_symptom_q9":"夜のしびれ（感覚の喪失）またはうずきはどれほど深刻ですか？",
  "patient_hand_questionnaire_symptom_q9_a1":"普通",
  "patient_hand_questionnaire_symptom_q9_a2":"少し",
  "patient_hand_questionnaire_symptom_q9_a3":"中くらい",
  "patient_hand_questionnaire_symptom_q9_a4":"深刻",
  "patient_hand_questionnaire_symptom_q9_a5":"極度に深刻です",
  "patient_hand_questionnaire_symptom_q10":"過去2週間の間に、手のしびれやうずきで目が覚めたことがありますか？",
  "patient_hand_questionnaire_symptom_q10_a1":"普通",
  "patient_hand_questionnaire_symptom_q10_a2":"一度",
  "patient_hand_questionnaire_symptom_q10_a3":"2-3回",
  "patient_hand_questionnaire_symptom_q10_a4":"4-5回",
  "patient_hand_questionnaire_symptom_q10_a5":"5回以上",
  "patient_hand_questionnaire_function":"機能",
  "patient_hand_questionnaire_function_instructions":"症状として、次の活動を実行する際の困難の程度を評価します。",
  "patient_hand_questionnaire_function_instructions_0":"困難なし",
  "patient_hand_questionnaire_function_instructions_1":"少し難しい",
  "patient_hand_questionnaire_function_instructions_2":"いくつかの困難がある",
  "patient_hand_questionnaire_function_instructions_3":"極度の困難",
  "patient_hand_questionnaire_function_instructions_4":"まったく実行できない",
  "patient_hand_questionnaire_function_q1":"鍵やペンなど、小さなものを握ったり使ったりする困難",
  "patient_hand_questionnaire_function_q1_a1":"困難なし",
  "patient_hand_questionnaire_function_q1_a2":"少し難しい",
  "patient_hand_questionnaire_function_q1_a3":"いくつかの困難がある",
  "patient_hand_questionnaire_function_q1_a4":"極度の困難",
  "patient_hand_questionnaire_function_q1_a5":"まったく実行できない",
  "patient_hand_questionnaire_function_q2":"書き込み",
  "patient_hand_questionnaire_function_q2_a1":"困難なし",
  "patient_hand_questionnaire_function_q2_a2":"少し難しい",
  "patient_hand_questionnaire_function_q2_a3":"いくつかの困難がある",
  "patient_hand_questionnaire_function_q2_a4":"極度の困難",
  "patient_hand_questionnaire_function_q2_a5":"まったく実行できない",
  "patient_hand_questionnaire_function_q3":"服のボタンを止める",
  "patient_hand_questionnaire_function_q3_a1":"困難なし",
  "patient_hand_questionnaire_function_q3_a2":"少し難しい",
  "patient_hand_questionnaire_function_q3_a3":"いくつかの困難がある",
  "patient_hand_questionnaire_function_q3_a4":"極度の困難",
  "patient_hand_questionnaire_function_q3_a5":"まったく実行できない",
  "patient_hand_questionnaire_function_q4":"本を持ちながら読む",
  "patient_hand_questionnaire_function_q4_a1":"困難なし",
  "patient_hand_questionnaire_function_q4_a2":"少し難しい",
  "patient_hand_questionnaire_function_q4_a3":"いくつかの困難がある",
  "patient_hand_questionnaire_function_q4_a4":"極度の困難",
  "patient_hand_questionnaire_function_q4_a5":"まったく実行できない",
  "patient_hand_questionnaire_function_q5":"電話のハンドルを握る",
  "patient_hand_questionnaire_function_q5_a1":"困難なし",
  "patient_hand_questionnaire_function_q5_a2":"少し難しい",
  "patient_hand_questionnaire_function_q5_a3":"いくつかの困難がある",
  "patient_hand_questionnaire_function_q5_a4":"極度の困難",
  "patient_hand_questionnaire_function_q5_a5":"まったく実行できない",
  "patient_hand_questionnaire_function_q6":"瓶の開封",
  "patient_hand_questionnaire_function_q6_a1":"困難なし",
  "patient_hand_questionnaire_function_q6_a2":"少し難しい",
  "patient_hand_questionnaire_function_q6_a3":"いくつかの困難がある",
  "patient_hand_questionnaire_function_q6_a4":"極度の困難",
  "patient_hand_questionnaire_function_q6_a5":"まったく実行できない",
  "patient_hand_questionnaire_function_q7":"家事",
  "patient_hand_questionnaire_function_q7_a1":"困難なし",
  "patient_hand_questionnaire_function_q7_a2":"少し難しい",
  "patient_hand_questionnaire_function_q7_a3":"いくつかの困難がある",
  "patient_hand_questionnaire_function_q7_a4":"極度の困難",
  "patient_hand_questionnaire_function_q7_a5":"まったく実行できない",
  "patient_hand_questionnaire_function_q8":"食料品バスケットの運搬",
  "patient_hand_questionnaire_function_q8_a1":"困難なし",
  "patient_hand_questionnaire_function_q8_a2":"少し難しい",
  "patient_hand_questionnaire_function_q8_a3":"いくつかの困難がある",
  "patient_hand_questionnaire_function_q8_a4":"極度の困難",
  "patient_hand_questionnaire_function_q8_a5":"まったく実行できない",
  "patient_hand_questionnaire_function_q9":"入浴と着替え",
  "patient_hand_questionnaire_function_q9_a1":"困難なし",
  "patient_hand_questionnaire_function_q9_a2":"少し難しい",
  "patient_hand_questionnaire_function_q9_a3":"いくつかの困難がある",
  "patient_hand_questionnaire_function_q9_a4":"極度の困難",
  "patient_hand_questionnaire_function_q9_a5":"まったく実行できない",
  "patient_hand_uram":"手と手首のURAMスケール表",
  "patient_hand_uram_canyou":"それできますか ...",
  "patient_hand_uram_q1":"手を平らに保ちながら、タオルで体を洗う。",
  "patient_hand_uram_q1_a1":"困難なし",
  "patient_hand_uram_q1_a2":"困難ほとんどない",
  "patient_hand_uram_q1_a3":"いくつかの困難",
  "patient_hand_uram_q1_a4":"多くの困難",
  "patient_hand_uram_q1_a5":"ほぼ不可能",
  "patient_hand_uram_q1_a6":"不可能",
  "patient_hand_uram_q2":"顔を洗う",
  "patient_hand_uram_q2_a1":"困難なし",
  "patient_hand_uram_q2_a2":"困難ほとんどない",
  "patient_hand_uram_q2_a3":"いくつかの困難",
  "patient_hand_uram_q2_a4":"多くの困難",
  "patient_hand_uram_q2_a5":"ほぼ不可能",
  "patient_hand_uram_q2_a6":"不可能",
  "patient_hand_uram_q3":"片手でボトルを持つ",
  "patient_hand_uram_q3_a1":"困難なし",
  "patient_hand_uram_q3_a2":"困難ほとんどない",
  "patient_hand_uram_q3_a3":"いくつかの困難",
  "patient_hand_uram_q3_a4":"多くの困難",
  "patient_hand_uram_q3_a5":"ほぼ不可能",
  "patient_hand_uram_q3_a6":"不可能",
  "patient_hand_uram_q4":"握手する",
  "patient_hand_uram_q4_a1":"困難なし",
  "patient_hand_uram_q4_a2":"困難ほとんどない",
  "patient_hand_uram_q4_a3":"いくつかの困難",
  "patient_hand_uram_q4_a4":"多くの困難",
  "patient_hand_uram_q4_a5":"ほぼ不可能",
  "patient_hand_uram_q4_a6":"不可能",
  "patient_hand_uram_q5":"何かをなでる、誰かを撫でる",
  "patient_hand_uram_q5_a1":"困難なし",
  "patient_hand_uram_q5_a2":"困難ほとんどない",
  "patient_hand_uram_q5_a3":"いくつかの困難",
  "patient_hand_uram_q5_a4":"多くの困難",
  "patient_hand_uram_q5_a5":"ほぼ不可能",
  "patient_hand_uram_q5_a6":"不可能",
  "patient_hand_uram_q6":"拍手",
  "patient_hand_uram_q6_a1":"困難なし",
  "patient_hand_uram_q6_a2":"困難ほとんどない",
  "patient_hand_uram_q6_a3":"いくつかの困難",
  "patient_hand_uram_q6_a4":"多くの困難",
  "patient_hand_uram_q6_a5":"ほぼ不可能",
  "patient_hand_uram_q6_a6":"不可能",
  "patient_hand_uram_q7":"指を広げる",
  "patient_hand_uram_q7_a1":"困難なし",
  "patient_hand_uram_q7_a2":"困難ほとんどない",
  "patient_hand_uram_q7_a3":"いくつかの困難",
  "patient_hand_uram_q7_a4":"多くの困難",
  "patient_hand_uram_q7_a5":"ほぼ不可能",
  "patient_hand_uram_q7_a6":"不可能",
  "patient_hand_uram_q8":"傾けて手で体重を支える",
  "patient_hand_uram_q8_a1":"困難なし",
  "patient_hand_uram_q8_a2":"困難ほとんどない",
  "patient_hand_uram_q8_a3":"いくつかの困難",
  "patient_hand_uram_q8_a4":"多くの困難",
  "patient_hand_uram_q8_a5":"ほぼ不可能",
  "patient_hand_uram_q8_a6":"不可能",
  "patient_hand_uram_q9":"親指と人差し指で小さなものをつまむ",
  "patient_hand_uram_q9_a1":"困難なし",
  "patient_hand_uram_q9_a2":"困難ほとんどない",
  "patient_hand_uram_q9_a3":"いくつかの困難",
  "patient_hand_uram_q9_a4":"多くの困難",
  "patient_hand_uram_q9_a5":"ほぼ不可能",
  "patient_hand_uram_q9_a6":"不可能",
  "patient_hand_quality_of_life":"生活の質",
  "patient_hand_quality_of_life_instruction":"今日の健康について最良の説明を選択してください。",
  "patient_hand_quality_of_life_instruction_0":"問題ない",
  "patient_hand_quality_of_life_instruction_1":"少し",
  "patient_hand_quality_of_life_instruction_2":"中程度",
  "patient_hand_quality_of_life_instruction_3":"深刻",
  "patient_hand_quality_of_life_instruction_4":"極度に/不可能",
  "patient_hand_quality_of_life_q1":"歩くときの問題",
  "patient_hand_quality_of_life_q1_a1":"問題ない",
  "patient_hand_quality_of_life_q1_a2":"少し",
  "patient_hand_quality_of_life_q1_a3":"中程度",
  "patient_hand_quality_of_life_q1_a4":"深刻",
  "patient_hand_quality_of_life_q1_a5":"不可能",
  "patient_hand_quality_of_life_q2":"体の洗浄や着替えの問題",
  "patient_hand_quality_of_life_q2_a1":"問題ない",
  "patient_hand_quality_of_life_q2_a2":"少し",
  "patient_hand_quality_of_life_q2_a3":"中程度",
  "patient_hand_quality_of_life_q2_a4":"深刻",
  "patient_hand_quality_of_life_q2_a5":"不可能",
  "patient_hand_quality_of_life_q3":"通常の活動（仕事、勉強、家事、家族活動、余暇活動など）に支障",
  "patient_hand_quality_of_life_q3_a1":"問題ない",
  "patient_hand_quality_of_life_q3_a2":"少し",
  "patient_hand_quality_of_life_q3_a3":"中程度",
  "patient_hand_quality_of_life_q3_a4":"深刻",
  "patient_hand_quality_of_life_q3_a5":"不可能",
  "patient_hand_quality_of_life_q4":"痛みや不便",
  "patient_hand_quality_of_life_q4_a1":"なし",
  "patient_hand_quality_of_life_q4_a2":"少し",
  "patient_hand_quality_of_life_q4_a3":"中程度",
  "patient_hand_quality_of_life_q4_a4":"深刻",
  "patient_hand_quality_of_life_q4_a5":"極度に",
  "patient_hand_quality_of_life_q5":"不安/うつ病",
  "patient_hand_quality_of_life_q5_a1":"不安/落ち込んでいない",
  "patient_hand_quality_of_life_q5_a2":"少しの不安/うつ病",
  "patient_hand_quality_of_life_q5_a3":"中程度の不安/うつ病",
  "patient_hand_quality_of_life_q5_a4":"深刻不安/うつ病",
  "patient_hand_quality_of_life_q5_a5":"極度に不安/うつ病",
  "patient_hand_quality_of_life_q6":"今日のあなたの健康はどれくらい良いですか？ （100が最高の健康であり、0は最悪の健康を意味します。）",
  "patient_hand_quality_of_life_q6_a1":"最悪",
  "patient_hand_quality_of_life_q6_a2":"最高",
  "patient_hand_satisfaction":"満足度",
  "patient_hand_satisfaction_instrucion":"次の項目であなたがどれだけ満足しているかを指定してください。",
  "patient_hand_satisfaction_instrucion_0":"非常に不満",
  "patient_hand_satisfaction_instrucion_1":"不満",
  "patient_hand_satisfaction_instrucion_2":"満足でも不満でもない",
  "patient_hand_satisfaction_instrucion_3":"やや満足",
  "patient_hand_satisfaction_instrucion_4":"非常に満足",
  "patient_hand_satisfaction_q1":"指の動き",
  "patient_hand_satisfaction_q1_na":"適用できない",
  "patient_hand_satisfaction_q1_a1":"非常に不満",
  "patient_hand_satisfaction_q1_a2":"不満",
  "patient_hand_satisfaction_q1_a3":"満足でも不満でもない",
  "patient_hand_satisfaction_q1_a4":"やや満足",
  "patient_hand_satisfaction_q1_a5":"非常に満足",
  "patient_hand_satisfaction_q2":"手首",
  "patient_hand_satisfaction_q2_na":"適用できない",
  "patient_hand_satisfaction_q2_a1":"非常に不満",
  "patient_hand_satisfaction_q2_a2":"不満",
  "patient_hand_satisfaction_q2_a3":"満足でも不満でもない",
  "patient_hand_satisfaction_q2_a4":"やや満足",
  "patient_hand_satisfaction_q2_a5":"非常に満足",
  "patient_hand_satisfaction_q3":"手の外観",
  "patient_hand_satisfaction_q3_na":"適用できない",
  "patient_hand_satisfaction_q3_a1":"非常に不満",
  "patient_hand_satisfaction_q3_a2":"不満",
  "patient_hand_satisfaction_q3_a3":"満足でも不満でもない",
  "patient_hand_satisfaction_q3_a4":"やや満足",
  "patient_hand_satisfaction_q3_a5":"非常に満足",
  "patient_hand_satisfaction_q4":"手/手首の治療",
  "patient_hand_satisfaction_q4_na":"適用できない",
  "patient_hand_satisfaction_q4_a1":"非常に不満",
  "patient_hand_satisfaction_q4_a2":"不満",
  "patient_hand_satisfaction_q4_a3":"満足でも不満でもない",
  "patient_hand_satisfaction_q4_a4":"やや満足",
  "patient_hand_satisfaction_q4_a5":"非常に満足",
  "patient_hand_complete":"アンケート終了",
  "patient_hand_complete_text1":"時間をかけてこの調査をご記入いただきありがとうございました。",
  "patient_hand_complete_text2":"[送信]をクリックして完了し、スコアをご確認ください。",
  "patient_hand_complete_text3":"あなたの答えは機密として処理され、すべてのレポートは正体不明の情報のみが使用されます。",
  "patient_hand_result":"結果",
  "patient_hand_result_text1":"以下は、アンケートから計算された痛みと手/手首の機能スコアです。",
  "patient_hand_result_function":"機能",
  "patient_hand_result_pain":"痛み",
  "patient_hand_result_health_perception":"全体的な健康認識",
  "patient_hand_result_self_evaluation":"手と手首スコア自己評価",
  "patient_hand_result_text2":"まもなく担当医が参ります。",
  "patient_general_history":"現在の不便/怪我/病気の歴史",
  "patient_general_history_q1":"主訴（自身の言葉で苦情を説明してください）",
  "patient_general_history_q2":"問題が始まった日付",
  "patient_general_history_q3":"画像診断を受けたことがありますか？",
  "patient_general_history_q3_a1":"なし",
  "patient_general_history_q3_a2":"X線",
  "patient_general_history_q3_a3":"超音波",
  "patient_general_history_q3_a4":"MRI",
  "patient_general_history_q3_a5":"MRI関節血管造影（関節に染料を注入するMRI）",
  "patient_general_history_q3_a6":"CT",
  "patient_general_history_q3_a7":"CT関節血管造影（染料を関節に注入するCT）",
  "patient_general_history_q4":"上記に記載されていない症状について、さらに詳しい検査を受けたことがありますか？",
  "patient_general_history_q4_placeholder":"その他の調査",
  "patient_general_past_history":"過去の病歴／現在進行中の医療問題",
  "patient_general_past_history_q1":"既知のアレルギーはありますか？ (薬物または食物）",
  "patient_general_past_history_q1_a1":"いいえ",
  "patient_general_past_history_q1_a2":"はい",
  "patient_general_past_history_placeholder":"アレルギーと反応の種類を指定してください",
  "patient_general_past_history_q2":"ラテックスにアレルギーはありますか？",
  "patient_general_past_history_q2_a1":"いいえ",
  "patient_general_past_history_q2_a2":"はい",
  "patient_general_past_history_q3":"高血圧ですか？それとも高血圧治療を受けていますか？",
  "patient_general_past_history_q3_a1":"いいえ",
  "patient_general_past_history_q3_a2":"はい",
  "patient_general_past_history_q3_a2_placeholder":"高血圧薬を指定してください",
  "patient_general_past_history_q4":"現在、または過去に心臓病や心臓疾患を患ったことがありますか？",
  "patient_general_past_history_q4_a1":"いいえ",
  "patient_general_past_history_q4_a2":"はい",
  "patient_general_past_history_q4_a2_o1":"健康問題を選択してください",
  "patient_general_past_history_q4_a2_o2":"狭心症",
  "patient_general_past_history_q4_a2_o3":"心臓発作",
  "patient_general_past_history_q4_a2_o4":"心不全（肺液）",
  "patient_general_past_history_q4_a2_o5":"濁音または心臓弁膜症",
  "patient_general_past_history_q4_a2_o6":"動悸／不整脈",
  "patient_general_past_history_q4_a2_o7":"植込みデバイス（ICD、ペースメーカー）",
  "patient_general_past_history_q4_a2_o8":"心臓手術",
  "patient_general_past_history_q4_a2_o9":"頸動脈手術",
  "patient_general_past_history_q4_a2_o10":"他の心臓の問題",
  "patient_general_past_history_q4_a2_o8_q1_placeholder":"心臓手術を指定してください",
  "patient_general_past_history_q4_a2_o10_q1_placeholder":"他の心臓問題を指定してください",
  "patient_general_past_history_q5":"心臓専門医はいますか？",
  "patient_general_past_history_q5_a1":"いいえ",
  "patient_general_past_history_q5_a2":"はい",
  "patient_general_past_history_q5_a2_placeholder":"名前と診療所",
  "patient_general_past_history_q6":"脚の血液循環に問題はありますか（末梢血管疾患）？",
  "patient_general_past_history_q6_a1":"いいえ",
  "patient_general_past_history_q6_a2":"はい",
  "patient_general_past_history_q6_a2_placeholder":"説明",
  "patient_general_past_history_q7":"先月、血液希釈剤を服用したことがありますか？",
  "patient_general_past_history_q7_a1":"いいえ",
  "patient_general_past_history_q7_a2":"はい",
  "patient_general_past_history_q7_a2_q1":"それは何ですか？",
  "patient_general_past_history_q7_a2_q1_a1":"アスピリン",
  "patient_general_past_history_q7_a2_q1_a2":"クロピドグレル/プラビックス",
  "patient_general_past_history_q7_a2_q1_a3":"リバロキサバン",
  "patient_general_past_history_q7_a2_q1_a4":"エリクス",
  "patient_general_past_history_q7_a2_q1_a5":"その他",
  "patient_general_past_history_q7_a2_q1_a5_placeholder":"血液希釈剤を指定してください",
  "patient_general_past_history_q8":"肺や胸に問題がありますか？",
  "patient_general_past_history_q8_a1":"いいえ",
  "patient_general_past_history_q8_a2":"はい",
  "patient_general_past_history_q8_a2_o1":"痰を伴う咳？",
  "patient_general_past_history_q8_a2_o2":"呼吸に何か問題はありますか？",
  "patient_general_past_history_q8_a2_o3":"その他",
  "patient_general_past_history_q8_a2_o2_o1":"喘息",
  "patient_general_past_history_q8_a2_o2_o2":"肺の高血圧（肺動脈高血圧症）",
  "patient_general_past_history_q8_a2_o2_o3":"肺線維症",
  "patient_general_past_history_q8_a2_o2_o4":"サルコイドーシス",
  "patient_general_past_history_q8_a2_o2_o5":"COPDまたは肺気腫",
  "patient_general_past_history_q8_a2_o2_o6":"嚢胞性線維症",
  "patient_general_past_history_q8_a2_o2_o7":"結核（TB）",
  "patient_general_past_history_q8_a2_o2_o8":"その他",
  "patient_general_past_history_q8_a2_o3_placehold":"特定して",
  "patient_general_past_history_q8_a2_o2_o8_placeholder":"特定して",
  "patient_general_past_history_q9":"過去1年間、ステロイド（プレドニゾンまたはコルチゾン）を服用したことがありますか？",
  "patient_general_past_history_q9_a1":"いいえ",
  "patient_general_past_history_q9_a2":"はい",
  "patient_general_past_history_q10":"自宅で酸素を使用していますか？",
  "patient_general_past_history_q10_a1":"いいえ",
  "patient_general_past_history_q10_a2":"はい",
  "patient_general_past_history_q11":"呼吸器専門医はいますか？",
  "patient_general_past_history_q11_a1":"いいえ",
  "patient_general_past_history_q11_a2":"はい",
  "patient_general_past_history_q11_a2_placeholder":"名前と診療所",
  "patient_general_past_history_q12":"過度のいびきや睡眠時無呼吸症候群がありますか？",
  "patient_general_past_history_q12_a1":"いいえ",
  "patient_general_past_history_q12_a2":"はい",
  "patient_general_past_history_q12_a2_q1":"睡眠時無呼吸症候群はCPAPまたはBiPAPで治療されていますか？",
  "patient_general_past_history_q12_a2_q1_a1":"いいえ",
  "patient_general_past_history_q12_a2_q1_a2":"はい",
  "patient_general_past_history_q13":"肝臓の問題を抱えたことはありますか？",
  "patient_general_past_history_q13_a1":"いいえ",
  "patient_general_past_history_q13_a2":"はい",
  "patient_general_past_history_q13_a2_o1":"選択した",
  "patient_general_past_history_q13_a2_o2":"肝硬変",
  "patient_general_past_history_q13_a2_o3":"A、B、またはC型肝炎",
  "patient_general_past_history_q13_a2_o4":"黄疸",
  "patient_general_past_history_q13_a2_o5":"その他",
  "patient_general_past_history_q13_a2_o5_placeholder":"特定して",
  "patient_general_past_history_q14":"胸焼け、潰瘍、食道裂孔ヘルニアが頻繁にありますか？",
  "patient_general_past_history_q14_a1":"いいえ",
  "patient_general_past_history_q14_a2":"はい",
  "patient_general_past_history_q15":"腎臓病になったことはありますか？",
  "patient_general_past_history_q15_a1":"いいえ",
  "patient_general_past_history_q15_a2":"はい",
  "patient_general_past_history_q15_a2_q1":"あなたは透析患者ですか？腹膜透析？血液透析？",
  "patient_general_past_history_q15_a2_q1_a1":"いいえ",
  "patient_general_past_history_q15_a2_q1_a2":"はい",
  "patient_general_past_history_q15_a2_placeholder":"腎臓病を指定してください",
  "patient_general_past_history_q15_a2_q1_a2_placeholder":"透析の種類とスケジュールを指定してください",
  "patient_general_past_history_q16":"糖尿病を患っていますか？",
  "patient_general_past_history_q16_a1":"いいえ",
  "patient_general_past_history_q16_a2":"はい",
  "patient_general_past_history_q16_a2_q1":"糖尿病をどのように管理していますか？食事/経口薬またはインスリンを使用していますか？",
  "patient_general_past_history_q16_a2_q1_placeholder":"特定して",
  "patient_general_past_history_q17":"甲状腺の問題はありますか？",
  "patient_general_past_history_q17_a1":"いいえ",
  "patient_general_past_history_q17_a2":"はい",
  "patient_general_past_history_q18":"てんかん、発作、または痙攣を以前に経験したことがありますか？",
  "patient_general_past_history_q18_a1":"いいえ",
  "patient_general_past_history_q18_a2":"はい",
  "patient_general_past_history_q18_a2_q1":"最後の発作はいつでしたか？",
  "patient_general_past_history_q18_a2_q1_placeholder":"特定して",
  "patient_general_past_history_q19":"神経や筋肉に問題がありましたか？",
  "patient_general_past_history_q19_a1":"いいえ",
  "patient_general_past_history_q19_a2":"はい",
  "patient_general_past_history_q19_a2_o1":"発作",
  "patient_general_past_history_q19_a2_o1_placeholder":"いつ ？",
  "patient_general_past_history_q19_a2_o2":"TIA（一過性脳虚血発作/軽症脳卒中）または脳卒中",
  "patient_general_past_history_q19_a2_o2_placeholder":"いつ ？",
  "patient_general_past_history_q19_a2_o3":"顔、足、または腕の衰弱",
  "patient_general_past_history_q19_a2_o4":"神経障害（例：多発性硬化症、ALS、アルツハイマー病）",
  "patient_general_past_history_q19_a2_o4_placeholder":"特定して",
  "patient_general_past_history_q19_a2_o5":"筋肉障害（例：重症筋無力症、筋ジストロフィー）",
  "patient_general_past_history_q19_a2_o5_placeholder":"特定して",
  "patient_general_past_history_q19_a2_o6":"聴力、視力、記憶力の問題",
  "patient_general_past_history_q19_a2_o7":"慢性の痛み",
  "patient_general_past_history_q19_a2_o8":"その他",
  "patient_general_past_history_q19_a2_o8_placeholder":"特定して",
  "patient_general_past_history_q20":"関節炎はありますか？",
  "patient_general_past_history_q20_a1":"いいえ",
  "patient_general_past_history_q20_a2":"はい",
  "patient_general_past_history_q20_a2_o1":"変形性関節症",
  "patient_general_past_history_q20_a2_o2":"リウマチ性関節炎",
  "patient_general_past_history_q20_a2_o3":"その他",
  "patient_general_past_history_q20_a2_o3_placeholder":"特定して",
  "patient_general_past_history_q21":"精神疾患の治療を受けたことはありますか？",
  "patient_general_past_history_q21_a1":"いいえ",
  "patient_general_past_history_q21_a2":"はい",
  "patient_general_past_history_q21_a2_o1":"重度の不安",
  "patient_general_past_history_q21_a2_o2":"うつ",
  "patient_general_past_history_q21_a2_o3":"その他",
  "patient_general_past_history_q21_a2_o3_placeholder":"特定して",
  "patient_general_past_history_q22":"出血障害と診断されたことはありますか？",
  "patient_general_past_history_q22_a1":"いいえ",
  "patient_general_past_history_q22_a2":"はい",
  "patient_general_past_history_q22_a2_o1":"白血病またはリンパ腫",
  "patient_general_past_history_q22_a2_o2":"血友病",
  "patient_general_past_history_q22_a2_o3":"血の塊",
  "patient_general_past_history_q22_a2_o4":"貧血",
  "patient_general_past_history_q22_a2_o5":"鎌状赤血球症",
  "patient_general_past_history_q22_a2_o6":"その他",
  "patient_general_past_history_q22_a2_o6_placeholder":"特定して",
  "patient_general_past_history_q23":"貧血はありますか？",
  "patient_general_past_history_q23_a1":"いいえ",
  "patient_general_past_history_q23_a2":"はい",
  "patient_general_past_history_q24":"足や肺に血栓があったことはありますか？",
  "patient_general_past_history_q24_a1":"いいえ",
  "patient_general_past_history_q24_a2":"はい",
  "patient_general_past_history_q25":"自分または家族の誰かが以下の深刻な問題を抱えていますか？",
  "patient_general_past_history_q25_a1":"鼻出血",
  "patient_general_past_history_q25_a1_o1":"いいえ",
  "patient_general_past_history_q25_a1_o2":"はい",
  "patient_general_past_history_q25_a2":"抜歯による出血",
  "patient_general_past_history_q25_a2_o1":"いいえ",
  "patient_general_past_history_q25_a2_o2":"はい",
  "patient_general_past_history_q25_a3":"手術後の出血",
  "patient_general_past_history_q25_a3_o1":"いいえ",
  "patient_general_past_history_q25_a3_o2":"はい",
  "patient_general_past_history_q26":"癌と診断されたことはありますか？",
  "patient_general_past_history_q26_a1":"いいえ",
  "patient_general_past_history_q26_a2":"はい",
  "patient_general_past_history_q26_a2_placeholder1":"がんの種類を指定してください",
  "patient_general_past_history_q26_a2_placeholder2":"いつ？",
  "patient_general_past_history_q27":"化学療法や放射線療法で治療されたことはありますか？",
  "patient_general_past_history_q27_a1":"いいえ",
  "patient_general_past_history_q27_a2":"はい",
  "patient_general_past_history_q27_a2_placeholder1":"治療名",
  "patient_general_past_history_q27_a2_placeholder2":"最近の治療日",
  "patient_general_past_history_q28":"自分や親族が麻酔で何か問題を起こしたことがありますか？",
  "patient_general_past_history_q28_a1":"いいえ",
  "patient_general_past_history_q28_a2":"はい",
  "patient_general_past_history_q28_a2_q1":"問題は何ですか？",
  "patient_general_past_history_q28_a2_q1_placeholder":"選択する",
  "patient_general_past_history_q28_a2_q1_o1":"手術後の激しい吐き気または嘔吐",
  "patient_general_past_history_q28_a2_q1_o2":"悪性高熱（血縁者または自分自身）",
  "patient_general_past_history_q28_a2_q1_o3":"呼吸の問題や麻酔呼吸チューブの挿入が困難？",
  "patient_general_past_history_q28_a2_q1_o4":"呼吸チューブの挿入に問題がある",
  "patient_general_past_history_q28_a2_q1_o5":"その他",
  "patient_general_past_history_q28_a2_q1_o5_placeholder":"特定して",
  "patient_general_past_history_q29":"次のうちどれがありますか？",
  "patient_general_past_history_q29_q1":"歯の欠け、緩み、入れ歯、部分入れ歯",
  "patient_general_past_history_q29_q1_a1":"いいえ",
  "patient_general_past_history_q29_q1_a2":"はい",
  "patient_general_past_history_q29_q2":"首を動かすことに問題があります",
  "patient_general_past_history_q29_q2_a1":"いいえ",
  "patient_general_past_history_q29_q2_a2":"はい",
  "patient_general_past_history_q29_q3":"口を開けられない問題",
  "patient_general_past_history_q29_q3_a1":"いいえ",
  "patient_general_past_history_q29_q3_a2":"はい",
  "patient_general_past_history_q30":"妊娠する可能性はありますか？",
  "patient_general_past_history_q30_a1":"いいえ",
  "patient_general_past_history_q30_a2":"はい",
  "patient_general_past_history_q30_not_applicable":"適用できない",
  "patient_general_past_history_q30_a2_q1":"最後の月経期間",
  "patient_general_past_history_q30_a2_q1_placeholder":"最後の月経期間",
  "patient_general_past_history_q31":"医療記録：上記の上記で述べられていない医療疾患を書いてください。",
  "patient_general_past_history_q31_q1":"すべての薬を記入してください（市販薬、吸入器、パッチ、ドロップ、ビタミン、ミネラル、サプリメント、ハーブを含む）",
  "patient_general_past_history_q31_q2":"薬の名前、用量、頻度、およびそれを服用する理由を示してください",
  "patient_general_past_history_q31_q2_placeholder":"薬の名前、用量、頻度、服用の理由",
  "patient_general_past_history_q31_q3":"用量",
  "patient_general_past_history_q31_q4":"頻繁",
  "patient_general_past_history_q31_q5":"薬を服用する理由",
  "patient_general_past_history_q31_q6":"外科的歴史",
  "patient_general_past_history_q31_q6_placeholder":"手術名と手術日をご記入ください。",
  "patient_general_social_history":"社会の歴史",
  "patient_general_social_history_q1":"ひとり暮らしですか？",
  "patient_general_social_history_q1_a1":"いいえ",
  "patient_general_social_history_q1_a2":"はい",
  "patient_general_social_history_q1_a1_q1":"誰と住んでいますか？",
  "patient_general_social_history_q1_a1_q1_placeholder":"誰と住んでいますか？",
  "patient_general_social_history_q2":"タバコは吸​​いますか？",
  "patient_general_social_history_q2_a1":"いいえ",
  "patient_general_social_history_q2_a2":"はい",
  "patient_general_social_history_q2_a2_q1":"1日に何本吸いますか？",
  "patient_general_social_history_q2_a2_q1_placeholder":"タバコの数",
  "patient_general_social_history_q2_a2_q2":"何年間？",
  "patient_general_social_history_q2_a2_q2_placeholder":"年",
  "patient_general_social_history_q3":"過去に喫煙したことがありますか？",
  "patient_general_social_history_q3_a1":"いいえ",
  "patient_general_social_history_q3_a2":"はい",
  "patient_general_social_history_q3_a2_q1":"いつ喫煙をやめましたか？",
  "patient_general_social_history_q3_a2_q1_placeholder":"いつ喫煙をやめましたか？",
  "patient_general_social_history_q3_a2_q2_placeholder":"年",
  "patient_general_social_history_q4":"アルコールを飲みますか？",
  "patient_general_social_history_q4_a1":"いいえ",
  "patient_general_social_history_q4_a2":"はい",
  "patient_general_social_history_q4_a2_q1_placeholder":"1週間の標準的な飲酒量",
  "patient_general_social_history_q5":"処方されていないレクリエーション・ドラッグ、ストリート・ドラッグ(違法薬物)、マリファナ、オピオイドを使用していますか、または使用したことがありますか？",
  "patient_general_social_history_q5_a1":"いいえ",
  "patient_general_social_history_q5_a2":"はい",
  "patient_general_social_history_q5_a2_placeholder":"薬を指定してください",
  "patient_general_complete":"アンケート終了",
  "patient_general_complete_text1":"時間をかけてこの調査をご記入いただきありがとうございました。",
  "patient_general_complete_text2":"[送信]をクリックして完了し、スコアをご確認ください。",
  "patient_general_complete_text3":"あなたの答えは機密として処理され、すべてのレポートは正体不明の情報のみが使用されます。",
  "AppVersion":"アプリバージョン",
  "Complete_personal_info":"完全な個人情報",
  "To_complete_the_sign_up":"サインアップを完了するには、以下の情報を入力してください。",
  "Select_Existing_Profile":"既存のプロファイルを選択",
  "Get_In":"入力",
  "Create_new_patient":"新しい患者を作成",
  "myProfile":"私のプロフィール",
  "First_Name":"名前*",
  "Last_Name":"苗字*",
  "DOB":"生年月日*",
  "Sex":"性別*",
  "Address":"住所*",
  "Postcode":"郵便番号*",
  "Suburb":"エリア*",
  "Country_Code":"国番号*",
  "Mobile_Phone":"携帯番号*",
  "Home_Phone":"自宅の電話番号*",
  "Email":"Eメール*",
  "Emergency_Contact":"緊急連絡先*",
  "Phone":"電話*",
  "Relationship":"関係*",
  "Regular_GP_Name":"一般診療の医者名*",
  "Practice_Name":"医療場所名*",
  "Medicare_Card_Number":"メディケアカード番号",
  "Ref":"参照",
  "Message":"メッセージ",
  "Expiry":"有効期限",
  "Private_Health_Fund":"民間医療保険基金",
  "Membership_Number":"会員番号",
  "Claim_Type":"請求タイプ",
  "Work_Cover":"雇用保険の範囲",
  "Employer":"雇用者",
  "Third_Party_Claim_Number":"第三者請求番号",
  "Third_Party_Name":"第三者機関名",
  "DVA_Concession_Card":"DVA割引カード",
  "Number":"番号",
  "Defence_Personnel":"防衛要員",
  "I_agree":"私は、個人情報の取り扱いに同意し、以下の文書を読みました。",
  "Privacy_Policy":"個人情報保護方針",
  "visitClinic":"予約",
  "selectdocOrg":"医師と病院を選ぶ",
  "doctorOrganization":"医師組織\"",
  "Select_Treatment_Category":"治療カテゴリを選択*",
  "Do_you_want_to_submit_patient_form_or_not":"患者フォームを提出しますか？",
  "areyousurewanttocontinueyourform":"前のフォームは保留中です。続けますか？",
  "myVisit":"過去の訪問",
  "View_score":"スコアを表示",
  "upcoming_visits":"近日公開",
  "No_data_found":"データが見つかりませんでした",
  "Doctor_Name":"医師の名前",
  "Appointment_Date_Time":"予約日と時刻",
  "Clinic_Name":"クリニック名",
  "Clinic_Address":"クリニックの住所",
  "akmed":"Akunah Med",
  "Type":"タイプ",
  "Date_Time":"日付と時刻",
  "Action":"行動",
  "View":"表示",
  "No_Messages":"メッセージはありません",
  "settings":"環境設定",
  "Language":"言語",
  "language":"言語と地域",
  "timezone":"タイムゾーン",
  "Timeformat":"時間形式",
  "List_of_my_profile":"私のプロフィール",
  "next_button":"次へ",
  "back_button":"戻る",
  "Continue":"続く",
  "Close":"閉じる",
  "submit":"提出",
  "Sign_out":"プロフィールに戻る",
  "Results":"結果",
  "update":"フォームが正常に更新されました",
  "Personal_Data":"個人データ",
  "Date_of_Birth":"生年月日",
  "Hip_arthritis":"股関節炎？*",
  "Area_of_foot_ankle":"足/足首の患部?*",
  "Diagnosis":"診断？*",
  "Evaluated_Shoulder":"評価された肩",
  "Dominant_Hand":"利き手*",
  "Age":"年",
  "No":"いいえ",
  "Yes":"はい",
  "visitNo":"visitNo",
  "visitYes":"visitYes",
  "shoulderPainR":"shoulderPainR",
  "Is_it_initial_visit":"これはあなたの最初の訪問ですか",
  "for_the_problem":"この問題について？*",
  "Diagnosis_Procedure":"診断/手順",
  "Procedure":"手順",
  "Evaluated_sdfs":"評価された *",
  "Please_Select_previous_visits":"前の訪問を選択してください*",
  "Follow_Up":"フォローアップ",
  "Assessment_Date":"評価日",
  "Do_you_have_Dupuytren_disease":"デュフィトレン病はありますか？",
  "Do_you_have_carpal_tunnel_syndrome":"手根管症候群はありますか？",
  "Is_your_unaffected_shoulder_pain":"患っていない肩に痛みはなく、正常な機能で働きますか？",
  "Is_your_unaffected_hip_pain":"患っていない股関節に痛みはなく、正常な機能で働きますか？",
  "Is_your_unaffected_knee_pain":"患っていない膝に痛みはなく、正常な機能で働きますか？",
  "Is_your_unaffected_elbow_pain":"患っていない肘に痛みはなく、正常な機能で働きますか？",
  "Is_your_unaffected_foot_and_ankle_pain":"患っていない足と足首に痛みはなく、正常な機能で働きますか？",
  "Is_your_unaffected_hand_pain":"患っていない手と手首に痛みはなく、正常な機能で働きますか？",
  "Patient_Consent":"Akunah患者情報と事前同意書",
  "Patient_Last_Name":"患者の苗字",
  "Patient_First_Name":"患者の名",
  "Patient_Date_of_Birth":"患者の生年月日",
  "information":"情報",
  "I_am_informedDDD":"あなたの整形外科治療の評価、計画、結果評価のために、あなたの医師",
  "Clinician":"（以下「臨床」と呼ばれる）は、Akunah Medical Technology Pty Ltdとその子会社（「Akunah」）によって提供された評価、結果データ収集と分析またはソフトウェアを使用します。",
  "For_that":"その目的のために、医師はあなたの個人データをAkunahに送ります。これには、体のコンピューター断層撮影（CT）スキャンやその他の医療データが含まれます。",
  "Akunah_softawre":"Akunahのソフトウェアとサービスには、画像評価、医療データ評価、およびパーソナライズされた解剖学的3Dモデル、外科計画、および臨床試験と共有される外科的サポートなどの主要な解剖学的測定が含まれる場合があります。",
  "the_clinic":"臨床医は、整形外科治療のために評価と手術前の計画を使用します。",
  "oportunnity":"また、Akunahの患者ポータルを通じて個人データを提供し、Akunahのモデルと計画にアクセスする機会があります。",
  "akunah_approch":"プライバシーとセキュリティに対するAkunahの取り組み",
  "akunah_approch1":"臨床医を支援するためにAkunahがお客様から収集するデータのプライバシーとセキュリティは、当社の最優先事項です。",
  "akunah_approch2":"Akunahは、世界で最も強力な個人情報保護であるEU一般データ保護規則EU/2016/679を含む、欧州データ保護法を自発的に遵守しています。これらの法律は、オーストラリア、米国、その他の国で制定されたプライバシー法を超えています。",
  "akunah_approch3":"Akunahの「顧客個人の個人情報を処理するためのプライバシーコード」は、Akunahが該当する欧州データ保護法にどのように準拠しているかを説明しています（",
  "akunah_approch4":"プライバシーコード",
  "akunah_approch5":"Akunahの「データ処理契約」は、あなたの個人情報を保護および維持するための臨床医との取り決めについて説明しています（",
  "akunah_approch6":"データ処理契約",
  "akunah_approch7":"このポリシーは、AkunahのWebサイトで見つけることができます。",
  "terms_":"https://akunah.com/termsofservices",
  "akunah_approch8":"プライバシーコード、データ処理契約、および本文書の間に矛盾がある場合は、本文書との矛盾の範囲において、以上最初に言及された文書の条項が適用されます。",
  "Data_Collection_and_Processing":"データの収集と処理",
  "I_am_informed":"Akunahは、連絡先情報、認証、識別、職業、人口統計に関する情報を含むデータを収集する場合があります。 Akunahは、あなたの健康に関する医療データを収集することもあります。",
  "Assessment_and_evaluation":"•評価と評価データ、物理的特性、および提案された治療と結果に関連するその他の情報。",
  "Previous_surgery":"•以前の手術、診断、放射スキャン、3Dモデル、解剖学、解剖学的測定。そして",
  "Health_and_medical_history":"•健康状態や病歴（症状、投薬、過去の診断や治療歴など）",
  "I_have_been_assured":"Akunahは、患者様から直接、または臨床医から、あるいはAkunah製品を通じて、常に適法かつ公正な方法で情報を収集します。Akunahは、必要に応じて評価および術前計画サービスを提供できるよう、今後表明される患者様の同意に従ってこれらのデータを処理します。",
  "My_data":"さらに、Akunahは、適用されるすべての法律および規制を遵守するために、お客様の個人情報を使用することがあります。",
  "your_data":"また、提案されたサービスまたは法律で提供を要求されている場合、あなたのデータは第三者に開示される場合があります。",
  "Data_Access_and_Storage":"データアクセス、ストレージ、セキュリティ",
  "Personal_data_provided":"あなたとあなたの臨床医が提供するデータは、あなたの治療または回復に貢献するAkunahおよび第三者（例：麻酔薬、理学療法士）によってのみアクセス可能であり、あなたのデータにアクセスする必要があります。",
  "data1":"Akunahのソフトウェアを通じて入力されたデータは、承認されたホスティングプロバイダーにより、オーストラリア連邦内で安全にホスティングされます。これにより、Akunahのソフトウェアを通じて処理されるお客様のデータの性質を反映した、適切なレベルの機密性と安全性が確保されます。",
  "data2":"オーストラリア連邦の外に住んでいる場合、またはAkunahソフトウェアを通じてデータ入力に入力する場合、オーストラリア連邦または米国を含むオーストラリア連邦外にあるAkunah子会社からアクセスできます。この場合、あなたの個人データは国内に保存されます。",
  "data3":"Akunahは、データの機密を維持し、すべての第三者と適切なレベルのデータ保護を保証するために、すべての法的要件に従って適切な安全装置を提供します。アクセスを持つすべての人が採用したAkunahと同じレベルのデータ保護を保証する標準を採用する必要があります。",
  "data4":"臨床医は、法的要件に従ってデータを収集、アクセス、分析、共有する責任を共有しています。",
  "p_data1":"お客様の個人情報は、Akunahによって異なる期間保管されます。保存期間は、以下の基準によって決定されます： (i) Akunahまたは臨床医がお客様の個人データを使用する目的（その目的に必要な期間のみデータを保管する必要があります）、(ii) Akunahがお客様の個人データを保管する最低期間を定める適用法または規制。",
  "Deidentified_Data":"身分非特定されたデータ",
  "d_txt1":"Akunahは患者様のデータを身分非特定化し、これらの非特定化データ（通常は他の患者様の非特定化データと集計されたもの）を、記録目的、研究開発、データ分析、ソフトウェア/デバイスの開発、およびAkunahの製品およびサービスの改善のために使用することがあります。これにより、Akunahは、皆様のような患者様のために製品やサービスを継続的に改善することができます。",
  "Patient_Rights":"あなたの権利",
  "I_understand_that":"お客様は、ご自身の個人情報をAkunahに提供することを拒否することができます。この場合、Akunahのソフトウェアやサービスを使用しなければ、医療サービスや治療を提供できない可能性がありますので、担当の医師にご相談ください。",
  "I_understand_that_1txt":"また、該当する場合、お客様は以下の権利を有します： (i) 個人データの処理に関する情報を入手し、当該個人データにアクセスする権利 (ii) 個人データが不正確である場合、その修正を要求する権利 (iii) 個人データの特定の処理に異議を唱え、提供した同意の撤回を要求することを含め、個人データの処理の制限を要求する権利； (iv) お客様の個人データの消去を要求すること (v) お客様の死亡後のデータ処理に関するガイドラインを定めること (vi) 上記の条件で処理されたお客様の個人データのコピーを、構造化され、一般的に使用され、機械で読み取り可能な形式で入手すること。これらの権利は、臨床医に直接請求することで行使することができます。",
  "I_understand_that_2txt":"お客様の権利は、適用される法律の免除および制限に従うものとします。",
  "I_understand_that_3txt":"撤回後、提案された処置の前にお客様がそれらの権利を行使した場合、臨床医はお客様に医療サービスや治療を提供できなくなる可能性があります。",
  "I_understand_that_4txt":"また、お客様の居住国の監督当局にあなたの個人データを処理することについて自由に苦情を申し立てることができます。",
  "I_understand_that_5txt":"身分非識別化データに関しては、非識別化データは個人データと同様の法的制限を受けません。Akunahはお客様のデータを見つけることができないため、非識別化データへのアクセスおよび使用に関する同意を撤回したり、これらのデータの消去を要求したりすることはできません。Akunahは、非識別化されたデータを追跡することはできません。",
  "Contact":"同意書",
  "I_am_able_to_contact":"私は、上記の「情報」セクションを読み、理解しました。または、私が理解できる言語で誰かが読んでくれました。",
  "or_by_post":"私は質問をする機会があり、私が受け取った答えに満足しています。私の情報は機密のままであることを理解しています。",
  "or_by_post2":"私は医者に以下のことを許可します：",
  "or_by_post2_1":"私は(「臨床医」)が、私の個人データをAkunahと共有することに同意しました。",
  "or_by_post3":"私は、Akunahが上記の「情報」セクションに記載されているとおり、私の個人情報および非識別化データを収集、保存、使用、共有することを許可します。",
  "or_by_post4":"私はいつでも自由に自分の同意を撤回できることを理解しています。",
  "or_by_post5":"私は、この文書を保管するために署名入りのコピーを渡されることを理解しています。",
  "or_by_post6":"私は、私のデータ処理に関して、以下の宛先まで電子メールでAkunahに連絡することができます。",
  "info_akunah":"info@akunah.com",
  "or_by_post7":"または郵送にて、Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australiaまでお送ることができます。",
  "Clear":"クリア",
  "Date":"日付",
  "Signed_by_Mouse":"マウスで署名*",
  "Name_if_consent_on_behalf_of_patient":"名前（患者の代わりに合意されたとき）",
  "Relationship_to_patient_if_applicable":"患者との関係(該当する場合)",
  "Choose_Sign_Type":"署名方法選択*",
  "Draw":"描く",
  "Signed_by_Keyboard":"キーボードで署名 *",
  "Agreement":"合意",
  "agree_that_I_have_read":"私は、この患者同意書を読み、理解したことに同意し、上記の目的のために私の個人データが処理されることに明示的に同意します。",
  "NOTE":"注記",
  "If_the_patient":"患者が18歳未満の場合、親または法定後見人の署名が必要です。",
  "If_the_patient_is_deceased":"患者が死亡している場合は、近親者の署名が必要です。",
  "patient_signature":"患者の署名",
  "profile_updated":"プロフィール更新済み",
  "profile_not_updated":"プロファイル更新なし",
  "video":"ビデオ",
  "radiology_form":"放射線科フォーム",
  "doc_document":"書類",
  "pathology_form":"病理学フォーム",
  "text":"文章",
  "Document_Name":"ドキュメント名",
  "email_sent_successfully":"削除要求が正常に送信されました。",
  "messages":"メッセージ",
  "adult_knee":"大人の膝",
  "paediatric_knee":"小児膝",
  "document":"私の書類",
  "Check_in":"チェックイン",
  "info_icon":"患者のアンケートに記入してください",
  "reminder":"注意",
  "Search_with_minimum_three_characters":"最低3文字から検索",
  "Select":"選択する",
  "delete_account":"アカウントを削除",
  "DD_MM_YYYY":"dd / mm / yyyy",
  "Female":"女性",
  "Male":"男",
  "want_to_disclose":"開示したくない",
  "enter_address":"住所入力",
  "logout":"ログアウト",
  "full_name":"氏名",
  "mmyy":"MM / YY",
  "health_found":"健康基金",
  "Not_Applicable":"適用できない",
  "not_available":"利用不可",
  "available":"利用可能",
  "shared":"共有",
  "private":"プライベート",
  "add_document":"文書の追加",
  "drap_and_drop":"ここにファイルをドラッグ＆ドロップ",
  "or_browser_files":"またはコンピュータのファイルを閲覧する",
  "browse_files":"ファイル閲覧",
  "download":"ダウンロード",
  "Oops_error":"おっとっと！要求したデータは見つかりませんでした。",
  "go_to_home":"ホームに帰る",
  "This_field_is_required":"この項目は必須です",
  "PendingForm":"保留中のフォーム",
  "NoFollowUpRecords":"追跡記録はありません",
  "Right":"右",
  "Both":"両方",
  "Left":"左",
  "shoulder":"肩",
  "hip_form":"股関節",
  "Foot_And_Ankle":"足と足首",
  "Hand_And_Wrist":"手と手首",
  "General":"一般項目",
  "adolescent_knee":"青少年の膝",
  "Elbow":"肘",
  "title":"タイトル",
  "description":"説明",
  "enter_your_description_here":"ここに説明を入力してください",
  "enter_your_title_here":"ここにタイトルを入力してください",
  "progress":"保留中",
  "done":"完了",
  "reset":"リセット",
  "send":"送信",
  "Edit":"編集",
  "delete":"消去",
  "Are_you_sure_want_to_done_the_remainder":"本当にこのノートを完成させますか？",
  "Confirm":"確認",
  "Update":"更新",
  "success":"成功",
  "error":"エラー",
  "Are_you_sure_you_want_to_delete":"消去してもよろしいですか？",
  "cancel":"キャンセル",
  "Hour_Time":"24時間",
  "Knee":"膝",
  "no":"いいえ",
  "yes":"はい",
  "Year":"0年、0か月、0日",
  "Bilateral":"両手利き",
  "Left_Arm":"左肩",
  "Right_Arm":"右肩",
  "out_of":"/",
  "Right_Elbow":"右肘",
  "Left_Elbow":"左肘",
  "right_foot_ankle":"右足/足首",
  "left_foot_ankle":"左足/足首",
  "Right_hand_and_wrist":"右手と手首",
  "Left_hand_and_wrist":"左手と手首",
  "Hip_Function_R":"右腰",
  "Hip_Function_L":"左腰",
  "Right_Knee":"右膝",
  "Left_Knee":"左膝",
  "Right_Pedi_Knee":"右膝",
  "Left_Pedi_Knee":"左膝",
  "Scoring_Self_Evaluation":"スコア自己評価",
  "presciption_form":"処方箋",
  "insurance_form":"保険",
  "letter_form":"手紙",
  "refferal_form":"紹介者",
  "medical_form":"医療",
  "are_you_sure_delete_account":"本当にアカウントを削除しますか？",
  "Adult_Knee_Pain":"大人の膝の痛み",
  "Knee_pain_13_18_year":"膝の痛み",
  "day_pdata":"日",
  "days_pdata":"日",
  "month_pdata":"月",
  "months_pdata":"月",
  "year_pdata":"年",
  "years_pdata":"年",
  "Name":"名前",
  "akunah_id":"AkunahID",
  "mobile":"電話",
  "email":"Eメール",
  "upload_a_profile":"画像をアップロード",
  "No_special_characters_are_allowed":"特殊文字は使用できません",
  "numbers_are_not_allowed":"数字は使用できません",
  "maximum_50_character_are_allowed":"最大50文字まで",
  "invalid_character":"無効な文字",
  "fields_only_contain_10_character":"フィールドに入力できるのは10文字まで",
  "maximum_10_Numbers_are_allowed":"最大10桁の数字が使用可能",
  "Only_I":"数字のみ使用可",
  "invalid_home_phone_number":"無効な自宅の電話番号",
  "special_characters_are_not_allowed":"特殊文字は使用できません",
  "maximum_digit_are_allowed":"最大1桁まで",
  "invalid_date":"無効な日付",
  "maximum_10_digits_are_allowed":"最大10桁まで可能",
  "switch_profile":"プロファイルの切り替え",
  "loading":"読み込み",
  "error_max_profile_limit":"1つのメールアドレスで使用できるアカウント数が上限に達しています。",
  "error_upcoming_appointments":"今後の予定が見つかりません",
  "error_past_visits":"過去の訪問は見つかりません",
  "error_documents":"文書が見つかりません",
  "country_hawaii":"(UTC-10:00) Hawaii Time",
  "country_tahiti":"(UTC-10:00) Tahiti",
  "country_pitcairn":"(UTC-08:00) Pitcairn",
  "country_niue":"(UTC-11:00) Niue",
  "country_mountain":"(UTC-07:00) Mountain Time",
  "country_arizona":"(UTC-07:00) Mountain Time - Arizona",
  "country_whitehorse":"(UTC-08:00) Pacific Time - Whitehorse",
  "country_chihuahua":"(UTC-07:00) Mountain Time - Chihuahua, Mazatlan",
  "country_edmonton":"(UTC-07:00) Mountain Time - Edmonton",
  "country_gambier":"(UTC-09:00) Gambier",
  "country_yellowknife":"(UTC-07:00) Mountain Time - Yellowknife",
  "country_dawson":"(UTC-07:00) Mountain Time - Dawson Creek",
  "country_belize":"(UTC-06:00) Belize",
  "country_hermosillo":"(UTC-07:00) Mountain Time - Hermosillo",
  "country_mexico":"(UTC-06:00) Central Time - Mexico City",
  "country_regina":"(UTC-06:00) Central Time - Regina",
  "country_tegucigalpa":"(UTC-06:00) Central Time - Tegucigalpa",
  "country_centraltime":"(UTC-06:00) Central Time",
  "country_rarotonga":"(UTC-10:00) Rarotonga",
  "country_pago":"(UTC-11:00) Pago Pago",
  "country_pacific":"(UTC-08:00) Pacific Time",
  "country_alaska":"(UTC-09:00) Alaska Time",
  "country_vancouver":"(UTC-08:00) Pacific Time - Vancouver",
  "country_tijuana":"(UTC-08:00) Pacific Time - Tijuana",
  "country_salvador":"(UTC-06:00) El Salvador",
  "country_costa":"(UTC-06:00) Costa Rica",
  "country_winnipeg":"(UTC-06:00) Central Time - Winnipeg",
  "country_galapagos":"(UTC-06:00) Galapagos",
  "country_managua":"(UTC-06:00) Managua",
  "country_america_cancun":"(UTC-05:00) America Cancun",
  "country_guatemala":"(UTC-06:00) Guatemala",
  "country_bogota":"(UTC-05:00) Bogota",
  "country_easter_island":"(UTC-05:00) Easter Island",
  "country_iqaluit":"(UTC-05:00) Eastern Time - Iqaluit",
  "country_toronto":"(UTC-05:00) Eastern Time - Toronto",
  "country_eastern_time":"(UTC-05:00) Eastern Time",
  "country_havana":"(UTC-05:00) Havana",
  "country_lima":"(UTC-05:00) Lima",
  "country_jamaica":"(UTC-05:00) Jamaica",
  "country_panama":"(UTC-05:00) Panama",
  "country_port_au_prince":"(UTC-05:00) Port-au-Prince",
  "country_nassau":"(UTC-05:00) Nassau",
  "country_rio_branco":"(UTC-05:00) Rio Branco",
  "country_barbados":"(UTC-04:00) Barbados",
  "country_halifax":"(UTC-04:00) Atlantic Time - Halifax",
  "country_bermuda":"(UTC-04:00) Bermuda",
  "country_boa_vista":"(UTC-04:00) Boa Vista",
  "country_curacao":"(UTC-04:00) Curacao",
  "country_marquesas":"(UTC-09:30) Marquesas",
  "country_caracas":"(UTC-04:00) Caracas",
  "country_grand_turk":"(UTC-04:00) Grand Turk",
  "country_la_paz":"(UTC-04:00) La Paz",
  "timezone_guyana":"(UTC-04:00) Guyana",
  "timezone_port_of_spain":"(UTC-04:00) Port of Spain",
  "timezone_manaus":"(UTC-04:00) Manaus",
  "timezone_martinique":"(UTC-04:00) Martinique",
  "timezone_porto_velho":"(UTC-04:00) Porto Velho",
  "timezone_santo_domingo":"(UTC-04:00) Santo Domingo",
  "timezone_puerto_rico":"(UTC-04:00) Puerto Rico",
  "timezone_araguaina":"(UTC-03:00) Araguaina",
  "timezone_belem":"(UTC-03:00) Belem",
  "timezone_buenos_aires":"(UTC-03:00) Buenos Aires",
  "timezone_newfound_time":"(UTC-03:30) Newfoundland Time - St. Johns",
  "timezone_asuncion":"(UTC-03:00) Asuncion",
  "timezone_campo":"(UTC-03:00) Campo Grande",
  "timezone_cayenne":"(UTC-03:00) Cayenne",
  "timezone_cuiaba":"(UTC-03:00) Cuiaba",
  "timezone_maceio":"(UTC-03:00) Maceio",
  "timezone_godthab":"(UTC-03:00) Godthab",
  "timezone_fortaleza":"(UTC-03:00) Fortaleza",
  "timezone_miquelon":"(UTC-03:00) Miquelon",
  "timezone_palmer":"(UTC-03:00) Palmer",
  "timezone_montevideo":"(UTC-03:00) Montevideo",
  "timezone_recife":"(UTC-03:00) Recife",
  "timezone_rothera":"(UTC-03:00) Rothera",
  "timezone_paramaribo":"(UTC-03:00) Paramaribo",
  "timezone_punta_arenas":"(UTC-03:00) Punta Arenas",
  "timezone_santig":"(UTC-03:00) Santiag",
  "timezone_salvador":"(UTC-03:00) Salvador",
  "timezone_stanley":"(UTC-03:00) Stanley",
  "timezone_south_georgia":"(UTC-02:00) South Georgia",
  "timezone_noronha":"(UTC-02:00) Noronha",
  "timezone_sao_pulo":"(UTC-02:00) Sao Paulo",
  "timezone_azores":"(UTC-01:00) Azores",
  "timezone_abidjan":"(UTC+00:00) Abidjan",
  "timezone_cape_verde":"(UTC-01:00) Cape Verde",
  "timezone_scoresbysund":"(UTC-01:00) Scoresbysund",
  "timezone_bissau":"(UTC+00:00) Bissau",
  "timezone_accra":"(UTC+00:00) Accra",
  "timezone_danmarkshavn":"(UTC+00:00) Danmarkshavn",
  "timezone_casablanca":"(UTC+00:00) Casablanca",
  "timezone_dublin":"(UTC+00:00) Dublin",
  "timezone_canary_islands":"(UTC+00:00) Canary Islands",
  "timezone_el_aaiun":"(UTC+00:00) El Aaiun",
  "timezone_thule":"(UTC-04:00) Thule",
  "timezone_gmt":"(UTC+00:00) GMT (no daylight saving)",
  "timezone_lisbon":"(UTC+00:00) Lisbon",
  "timezone_faeroe":"(UTC+00:00) Faeroe",
  "timezone_reykjavik":"(UTC+00:00) Reykjavik",
  "timezone_monrovia":"(UTC+00:00) Monrovia",
  "timezone_algiers":"(UTC+01:00) Algiers",
  "timezone_london":"(UTC+00:00) London",
  "timezone_amsterdam":"(UTC+01:00) Amsterdam",
  "timezone_andorra":"(UTC+01:00) Andorra",
  "timezone_berlin":"(UTC+01:00) Berlin",
  "timezone_budapest":"(UTC+01:00) Budapest",
  "timezone_ceuta":"(UTC+01:00) Ceuta",
  "timezone_brussels":"(UTC+01:00) Brussels",
  "timezone_copenhagen":"(UTC+01:00) Copenhagen",
  "timezone_gibrltar":"(UTC+01:00) Gibraltar",
  "timezone_prague":"(UTC+01:00) Central European Time - Prague",
  "timezone_lagos":"(UTC+01:00) Lagos",
  "timezone_luxembourg":"(UTC+01:00) Luxembourg",
  "timezone_madrid":"(UTC+01:00) Madrid",
  "timezone_malta":"(UTC+01:00) Malta",
  "timezone_ndjamena":"(UTC+01:00) Ndjamena",
  "timezone_paris":"(UTC+01:00) Paris",
  "timezone_oslo":"(UTC+01:00) Oslo",
  "timezone_monaco":"(UTC+01:00) Monaco",
  "timezone_tirane":"(UTC+01:00) Tirane",
  "timezone_rome":"(UTC+01:00) Rome",
  "timezone_vienna":"(UTC+01:00) Vienna",
  "timezone_tunis":"(UTC+01:00) Tunis",
  "timezone_warsaw":"(UTC+01:00) Warsaw",
  "timezone_amman":"(UTC+02:00) Amman",
  "timezone_zurich":"(UTC+01:00) Zurich",
  "timezone_athens":"(UTC+02:00) Athens",
  "timezone_burcharest":"(UTC+02:00) Bucharest",
  "timezone_chisinau":"(UTC+02:00) Chisinau",
  "timezone_stockholm":"(UTC+01:00) Stockholm",
  "timezone_cairo":"(UTC+02:00) Cairo",
  "timezone_gaza":"(UTC+02:00) Gaza",
  "timezone_jerusalem":"(UTC+02:00) Jerusalem",
  "timezone_johannesburg":"(UTC+02:00) Johannesburg",
  "timezone_helsinki":"(UTC+02:00) Helsinki",
  "timezone_khartoum":"(UTC+02:00) Khartoum",
  "timezone_belgrade":"(UTC+01:00) Central European Time - Belgrade",
  "timezone_damascus":"(UTC+02:00) Damascus",
  "timezone_maputo":"(UTC+02:00) Maputo",
  "timezone_kaliningrad":"(UTC+02:00) Moscow-01 - Kaliningrad",
  "timezone_riga":"(UTC+02:00) Riga",
  "timezone_kiev":"(UTC+02:00) Kiev",
  "timezone_nicosia":"(UTC+02:00) Nicosia",
  "timezone_tallinn":"(UTC+02:00) Tallinn",
  "timezone_tripoli":"(UTC+02:00) Tripoli",
  "timezone_sofia":"(UTC+02:00) Sofia",
  "timezone_vilnius":"(UTC+02:00) Vilnius",
  "timezone_istanbul":"(UTC+03:00) Istanbul",
  "timezone_baghdad":"(UTC+03:00) Baghdad",
  "timezone_minsk":"(UTC+03:00) Minsk",
  "timezone_windhoek":"(UTC+02:00) Windhoek",
  "timezone_nairobi":"(UTC+03:00) Nairobi",
  "timezone_moscow":"(UTC+03:00) Moscow+00 - Moscow",
  "timezone_qatar":"(UTC+03:00) Qatar",
  "timezone_beirut":"(UTC+02:00) Beirut",
  "timezone_riyadh":"(UTC+03:00) Riyadh",
  "timezone_syowa":"(UTC+03:00) Syowa",
  "timezone_tehran":"(UTC+03:30) Tehran",
  "timezone_baku":"(UTC+04:00) Baku",
  "timezone_dubai":"(UTC+04:00) Dubai",
  "timezone_mahe":"(UTC+04:00) Mahe",
  "timezone_mauritius":"(UTC+04:00) Mauritius",
  "timezone_tbilisi":"(UTC+04:00) Tbilisi",
  "timezone_samara":"(UTC+04:00) Moscow+01 - Samara",
  "timezone_reunion":"(UTC+04:00) Reunion",
  "timezone_yerevan":"(UTC+04:00) Yerevan",
  "timezone_kabul":"(UTC+04:30) Kabul",
  "timezone_aqtau":"(UTC+05:00) Aqtau",
  "timezone_aqtobe":"(UTC+05:00) Aqtobe",
  "timezone_ashgabat":"(UTC+05:00) Ashgabat",
  "timezone_dushanbe":"(UTC+05:00) Dushanbe",
  "timezone_maldives":"(UTC+05:00) Maldives",
  "timezone_yekaterinburg":"(UTC+05:00) Moscow+02 - Yekaterinburg",
  "timezone_kerguelen":"(UTC+05:00) Kerguelen",
  "timezone_mawson":"(UTC+05:00) Mawson",
  "timezone_tashkent":"(UTC+05:00) Tashkent",
  "timezone_colombo":"(UTC+05:30) Colombo",
  "timezone_kathmandu":"(UTC+05:45) Kathmandu",
  "timezone_omsk":"(UTC+06:00) Moscow+03 - Omsk",
  "timezone_india_standard_time":"(UTC+05:30) India Standard Time",
  "timezone_chagos":"(UTC+06:00) Chagos",
  "timezone_dhaka":"(UTC+06:00) Dhaka",
  "timezone_bishkek":"(UTC+06:00) Bishkek",
  "timezone_cocos":"(UTC+06:30) Cocos",
  "timezone_thimphu":"(UTC+06:00) Thimphu",
  "timezone_almaty":"(UTC+06:00) Almaty",
  "timezone_vostok":"(UTC+06:00) Vostok",
  "timezone_rangoon":"(UTC+06:30) Rangoon",
  "timezone_christmas":"(UTC+07:00) Christmas",
  "timezone_davis":"(UTC+07:00) Davis",
  "timezone_karachi":"(UTC+05:00) Karachi",
  "timezone_hanoi":"(UTC+07:00) Hanoi",
  "timezone_beijing":"(UTC+08:00) China Time - Beijing",
  "timezone_hongkong":"(UTC+08:00) Hong Kong",
  "timezone_hovd":"(UTC+07:00) Hovd",
  "timezone_jakarta":"(UTC+07:00) Jakarta",
  "timezone_kuala_lumpur":"(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok":"(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk":"(UTC+07:00) Moscow+04 - Krasnoyarsk",
  "timezone_brunei":"(UTC+08:00) Brunei",
  "timezone_macau":"(UTC+08:00) Macau",
  "timezone_makassar":"(UTC+08:00) Makassar",
  "timezone_choibalsan":"(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar":"(UTC+08:00) Ulaanbaatar",
  "timezone_manila":"(UTC+08:00) Manila",
  "timezone_pyongyang":"(UTC+08:30) Pyongyang",
  "timezone_singapore":"(UTC+08:00) Singapore",
  "timezone_irkutsk":"(UTC+08:00) Moscow+05 - Irkutsk",
  "timezone_taipei":"(UTC+08:00) Taipei",
  "timezone_jayapura":"(UTC+09:00) Jayapura",
  "timezone_dili":"(UTC+09:00) Dili",
  "timezone_perth":"(UTC+08:00) Western Time - Perth",
  "timezone_yakutsk":"(UTC+09:00) Moscow+06 - Yakutsk",
  "timezone_palau":"(UTC+09:00) Palau",
  "timezone_seoul":"(UTC+09:00) Seoul",
  "timezone_tokyo":"(UTC+09:00) Tokyo",
  "timezone_darwin":"(UTC+09:30) Central Time - Darwin",
  "timezone_dumont":"(UTC+10:00) Dumont D'Urville",
  "timezone_brisbane":"(UTC+10:00) Eastern Time - Brisbane",
  "timezone_guam":"(UTC+10:00) Guam",
  "timezone_vladivostok":"(UTC+10:00) Moscow+07 - Vladivostok",
  "timezone_port_moresby":"(UTC+10:00) Port Moresby",
  "timezone_truk":"(UTC+10:00) Truk",
  "timezone_adelaide":"(UTC+10:30) Central Time - Adelaide",
  "timezone_casey":"(UTC+11:00) Eastern Time - Hobart",
  "timezone_melbourne_sydney":"(UTC+11:00) Eastern Time - Melbourne, Sydney",
  "timezone_kosrae":"(UTC+11:00) Kosrae",
  "timezone_efate":"(UTC+11:00) Efate",
  "timezone_guadalcanal":"(UTC+11:00) Guadalcanal",
  "timezone_ponape":"(UTC+11:00) Ponape",
  "timezone_norfolk":"(UTC+11:00) Norfolk",
  "timezone_magadan":"(UTC+11:00) Moscow+08 - Magadan",
  "timezone_noumea":"(UTC+11:00) Noumea",
  "timezone_majuro":"(UTC+12:00) Majuro",
  "timezone_funafuti":"(UTC+12:00) Funafuti",
  "timezone_nauru":"(UTC+12:00) Nauru",
  "timezone_tarawa":"(UTC+12:00) Tarawa",
  "timezone_kwajalein":"(UTC+12:00) Kwajalein",
  "timezone_enderbury":"(UTC+13:00) Enderbury",
  "timezone_wake":"(UTC+12:00) Wake",
  "timezone_fakaofo":"(UTC+13:00) Fakaofo",
  "timezone_wallis":"(UTC+12:00) Wallis",
  "timezone_fiji":"(UTC+13:00) Fiji",
  "timezone_apia":"(UTC+14:00) Apia",
  "timezone_kiritimati":"(UTC+14:00) Kiritimati",
  "timezone_tongatapu":"(UTC+13:00) Tongatapu",
  "timezone_auckland":"(UTC+13:00) Auckland",
  "dateFormet":"日付形式",
  "Org_success":"組織への加入要求が正常に送信された",
  "Org_err":"すでに組織に参加しています。",
  "Request_Organization":"組織への加入を要求",
  "organization_requested":"加入を要求した組織",
  "join":"参加する",
  "your_email":"email@address.com",
  "medical_summary":"医療概要はまだ完成していません。",
  "medical_summary_msg":"私の医療概要",
  "medical_success":"医療概要は正常に提出されました",
  "patient_form_popup":"Do you wish to continue filling out the questionnaire you were working on earlier?",
  "Please_confirm_that_your_details_are_correct":"Please confirm that your details are correct",
  "Welcome_to_Akunah_your_Health_Passport":"Welcome to Akunah, your Health Passport.",
  "Your_helth_is_in_your_hands":"Your health is in your hands.",
  "Lets_begin_your_journey":"Let's begin your journey.",
  "Please_complete_your_profile":"Please complete your profile.",
  "Profile_complete":"Profile complete!",
  "Would_you_like_to_add_another_profile_for_a_family_member":"Would you like to add another profile for a family member?",
  "Alert_detected":"Alert: Multiple logins detected",
  "To_safeguard_your_data":"To safeguard your data privacy and security, the webpage has timed out.",
  "Kindly_close_all_other_Akunah":"Kindly close all other Akunah tabs and press “Back to home” to continue.",
  "Akunah_Team_is_dedicated":"Akunah Team is dedicated to protecting your data.",
  "Last_Created":"Last Created",
  "First_Created":"First Created",
  "Alphabetically":"Alphabetically (A-Z)",
  "Alphabetically1":"Alphabetically (Z-A)",
  "Your_form_submission_was_successful_Clinician":"Your form submission was successful. We are now automatically redirecting to the Clinician app",
  "Your_form_submission_was_successful_Patient":"Your form submission was successful. We are now automatically redirecting to the Patient app",
  "If_this_doesnt_happen_within":"If this doesn’t happen within 5 seconds, please",
  "click_here":"click here",
  "Save":"Save"
}