import React, { useState } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import sortDropStyles from "./SortDropdown.module.css";
import arrowSmall from "../../../images/arrowbluesm.svg";
import useTranslation from "../../customHooks/translations.js";


const SortDropdown = ({
  value,
  label,
  data,
  placeholder,
  styleClass,
  onChange,
}) => {
  const [Sortnote,setSortnote] = useState("")
  const handleChange = (event,value) => {
    setSortnote(value)
    onChange(value);
  };
  const translation = useTranslation();

  return (
    <>
      <div
        className={`text-end custom_sort_dropdown ${sortDropStyles.sort_dropdown_main}`}
      >
        <Dropdown className={`custom_dropdown ${sortDropStyles.drop_down}`}>
          <Dropdown.Toggle
            className={sortDropStyles.dropdown_button}
            variant="default"
            id="dropdown-basic"
          >
            Sort{" "}
            <span>
              <img className={`arrow_img`} src={arrowSmall} alt="arrow" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="" onClick={(e) => handleChange(e,2)} value={Sortnote}>{translation.Last_Created}</Dropdown.Item>
            <Dropdown.Item href="" onClick={(e) => handleChange(e,1)} value={Sortnote}>{translation.First_Created}</Dropdown.Item>
            <Dropdown.Item href="" onClick={(e) => handleChange(e,3)} value={Sortnote}>{translation.Alphabetically}</Dropdown.Item>
            <Dropdown.Item href="" onClick={(e) => handleChange(e,4)} value={Sortnote}>{translation.Alphabetically1}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

SortDropdown.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  styleClass: PropTypes.string,
  onChange: PropTypes.func,
};

SortDropdown.defaultProps = {
  value: "",
  label: "",
  styleClass: "",
  placeholder: "",
};

export default SortDropdown;
