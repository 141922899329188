import React, { useEffect, useState } from "react";
import notification from "../../images/notification.png";
import document from "../../images/Icon/document_icon.svg";
import arrowRight from "../../images/arrow-bright.svg";
import contact from "../../images/contact.svg";
import tick from "../../images/tick.png";
import useAxios from "../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import { getTimeDifference } from "../../utilities/DateFormate";
import useTranslation from "../customHooks/translations";
import Message from "../custom/toster/Message";
function SchedulerNotification() {
  const translation = useTranslation();
  let axiosInstance = useAxios();
  const [markAllRead, setMarkAllRead] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  let akToken = localStorage.getItem("AkTOKENI");
  const login = useSelector((state) => state.loginPatient.arr[0]);

  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  const handleMarkAllRead = () => {
    // Update the state when the button is clicked
    setMarkAllRead(true);
  };

  // const dateFuture = (e) => {
  //   let isFutureDate;
  //   const givenDate = new Date(e);
  //   const currentDate = new Date();
  //   if (givenDate > currentDate) {
  //     isFutureDate = "Upcoming";
  //   } else {
  //     isFutureDate = "Expired";
  //   }

  //   return isFutureDate;
  // };
  const getNotification = async () => {
    try {
      const response = await axiosInstance.current.get(
        // `extapp/doctors/DocNotification?ak_pt=${login.patientInfo.ak_id}`
        `extapp/doctors/patient_resource?akpt_id=${akToken}&limit=50&offset=0`
      );

      // http://akdev.developer.akunah.com:56201/api/v1/extapp/doctors/patient_resource?akpt_id=AKPT000740&limit=5&offset=0
      setNotificationData(response.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      console.log("api response finally");
    }
  };

  useEffect(() => {
    getNotification();
  }, [akToken]);

  const handleOpen = (ak_dc, ak_pt, ak_ou, type, resource_id, status) => {
    ReadtoMark(resource_id);
    if (status === "-1") {
      setMessage("This form has Expired");
      setMessageType("error");
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    } else {
      setTimeout(() => {
        window.location.href =
          process.env.REACT_APP_FORM_URL +
          "patient-personal-data" +
          `/${window.btoa(ak_pt)}` +
          `/${window.btoa(type)}?r=${window.btoa(resource_id)}`;
      }, 1000);
    }
  };

  const ReadtoMark = async (res_id) => {
    try {
      const response = await axiosInstance.current.put(
        `/extapp/doctors/patient_resource?resource_id=${res_id}&ak_id=${akToken}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="notification_container_inner">
        {message && <Message message={message} type={messageType} />}
        <div className="notification_header d-flex align-items-center">
          <div className="d-inline-block notification_title ">
            {translation.Notifications}
          </div>
          {/* mark all read */}
          {/* <button className="notify_btn" onClick={handleMarkAllRead}>
            Mark all as read
          </button> */}
        </div>
        <div className="mt-4">
          {notificationData &&
            notificationData.map((ele) => (
              <>
                <div
                  className={`notify_item d-flex align-items-center ${
                    markAllRead ? "notify_msg_read" : "notify_msg_unread"
                  }`}
                  onClick={() =>
                    handleOpen(
                      ele.ak_dc,
                      ele.ak_pt,
                      ele.ak_ou,
                      ele.type,
                      ele.resource_id,
                      ele.status
                    )
                  }
                >
                  <div className="d-flex align-items-start">
                    <img
                      src={document}
                      alt="img"
                      className="me-4 notify_type_icon"
                    />
                    <div className="notification_type">
                      <div className="notify_msg">
                        <p className="d-inline">
                          <strong>
                            Dr. {ele.doctor_name} {translation.shared_a}{" "}
                            {(() => {
                              if (ele.type === 4) {
                                return "Shoulder";
                              } else if (ele.type === 6) {
                                return "Hip";
                              } else if (ele.type === 21) {
                                return "Foot and Ankle";
                              } else if (ele.type === 22) {
                                return "Elbow";
                              } else if (ele.type === 5) {
                                return "Knee";
                              } else if (ele.type === 23) {
                                return "Hand and Wrist";
                              } else {
                                return "General";
                              }
                            })()}{" "}
                            {translation.form}
                            {/* - {dateFuture(ele.date)}  */}
                          </strong>
                        </p>
                        <p className="f_500">{getTimeDifference(ele.date)}</p>
                      </div>
                    </div>
                  </div>

                  <div className="right_icon d-flex">
                    <img
                      src={arrowRight}
                      alt="img"
                      className="d-flex notify_item_right"
                    />
                  </div>
                </div>
              </>
            ))}
          {/* <div
                  className={`notify_item d-flex align-items-center ${
                    markAllRead ? "notify_msg_read" : "notify_msg_unread"
                  }`}
                >
                  <div className="d-flex align-items-start">
                    <img src={document} alt="img" className="me-4 notify_type_icon" />
                    <div className="notification_type">
                      <div className="notify_msg">
                        <p className="d-inline">
                          <strong>John</strong> case has been
                        </p>
                      </div>
                      <div className="mt-2 notify_msg">2 hours ago</div>
                    </div>
                  </div>
                  <div className="right_icon d-flex">
                    <img
                      src={arrowRight}
                      alt="img"
                      className="d-flex notify_item_right"
                    />
                  </div>
                </div>
                <div
                  className={`notify_item d-flex align-items-center ${
                    markAllRead ? "notify_msg_read" : "notify_msg_unread"
                  }`}
                >
                  <div className="d-flex align-items-start">
                    <img src={document} alt="img" className="me-4 notify_type_icon" />
                    <div className="notification_type">
                      <div className="notify_msg">
                        <p className="d-inline">
                          <strong>John</strong> case has been
                        </p>
                      </div>
                      <div className="mt-2 notify_msg">2 hours ago</div>
                    </div>
                  </div>
                  <div className="right_icon d-flex">
                    <img
                      src={arrowRight}
                      alt="img"
                      className="d-flex notify_item_right"
                    />
                  </div>
                </div>
                <div
                  className={`notify_item d-flex align-items-center ${
                    markAllRead ? "notify_msg_read" : "notify_msg_unread"
                  }`}
                >
                  <div className="d-flex align-items-start">
                    <img src={tick} alt="img" className="me-4 notify_type_icon" />
                    <div className="notification_type">
                      <div className="notify_msg">
                        <p className="d-inline">
                          <strong>John</strong> case has been
                        </p>
                      </div>
                      <div className="mt-2 notify_msg">2 hours ago</div>
                    </div>
                  </div>
                  <div className="right_icon d-flex">
                    <img
                      src={arrowRight}
                      alt="img"
                      className="d-flex notify_item_right"
                    />
                  </div>
                </div>
                <div
                  className={`notify_item d-flex align-items-center ${
                    markAllRead ? "notify_msg_read" : "notify_msg_unread"
                  }`}
                >
                  <div className="d-flex align-items-start">
                    <img src={contact} alt="img" className="me-4 notify_type_icon" />
                    <div className="notification_type">
                      <div className="notify_msg">
                        <p className="d-inline">
                          <strong>John</strong> case has been
                        </p>
                      </div>
                      <div className="mt-2 notify_msg">2 hours ago</div>
                    </div>
                  </div>
                  <div className="right_icon d-flex">
                    <img
                      src={arrowRight}
                      alt="img"
                      className="d-flex notify_item_right"
                    />
                  </div>
                </div> */}
        </div>
        {/* <div className="notification_footer mt-5">
                <button className="notify_btn " >
                  Delete all
                </button>
              </div> */}
      </div>
    </div>
  );
}

export default SchedulerNotification;
