import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import LeftSidebar from "./components/header/LeftSidebar";
import TopHeader from "./components/header/TopHeader";
import MyProfile from "./components/my-profile/MyProfile";
import MyProfileStep2 from "./components/my-profile/MyProfileStep2";
import MyProfileStep3 from "./components/my-profile/MyProfileStep3";
import MyProfileStep4 from "./components/my-profile/MyProfileStep4";
import VisitClinic from "./components/visit-clinic/VisitClinic";
import MyVisits from "./components/my-visits/MyVisits";
import Messages from "./components/messages/Messages";
import NotFoundPage from "./components/custom/notfoundpage/NotFoundPage";
// import AddDocument from "./components/document/AddDocument";
// import NewDocument from "./components/document/NewDocument";
// import EditDocument from "./components/document/EditDocument";
// import AddDocument from "./components/document/AddDocument"
// import Pain from "./components/scoring-forms/shoulder-forms/pain/Pain";
// import Function from "./components/scoring-forms/shoulder-forms/function/Function";
// import Sacs from "./components/scoring-forms/shoulder-forms/sacs/Sacs";
// import Wosi from "./components/scoring-forms/shoulder-forms/wosi/Wosi";
// import Satisfaction from "./components/scoring-forms/shoulder-forms/satisfaction/Satisfaction";
// import Complete from "./components/scoring-forms/shoulder-forms/complete/Complete";
// import Result from "./components/scoring-forms/shoulder-forms/result/Result";
// import FormsMap from "./components/scoring-forms/FormsMap";
// import AdultKneePain from "./components/scoring-forms/knee-forms/adult-knee-form/pain/AdultKneePain";
// import AdultKneeSymptoms from "./components/scoring-forms/knee-forms/adult-knee-form/symptoms/AdultKneeSymptoms";
// import AdultKneeFunction from "./components/scoring-forms/knee-forms/adult-knee-form/function/AdultKneeFunction";
// import AdultKneeQualityOfLife from "./components/scoring-forms/knee-forms/adult-knee-form/quality-of-life/AdultKneeQualityOfLife";
// import AdultKneeSatisfaction from "./components/scoring-forms/knee-forms/adult-knee-form/satisfaction/AdultKneeSatisfaction";
// import NorwichPatellarInstability from "./components/scoring-forms/knee-forms/adult-knee-form/norwich-patellar/NorwichPatellarInstability";
// import AdultKneeComplete from "./components/scoring-forms/knee-forms/adult-knee-form/complete/AdultKneeComplete";
// import AdultKneeResult from "./components/scoring-forms/knee-forms/adult-knee-form/result/AdultKneeResult";
// import PediaKneePain from "./components/scoring-forms/knee-forms/pedia-knee-form/pain/PediaKneePain";
// import PediaKneeSymptoms from "./components/scoring-forms/knee-forms/pedia-knee-form/symptoms/PediaKneeSymptoms";
// import PediaKneeFunction from "./components/scoring-forms/knee-forms/pedia-knee-form/function/PediaKneeFunction";
// import PediaKneeQualityOfLife from "./components/scoring-forms/knee-forms/pedia-knee-form/quality-of-life/PediaKneeQualityOfLife";
// import PediaKneeSatisfaction from "./components/scoring-forms/knee-forms/pedia-knee-form/satisfaction/PediaKneeSatisfaction";
// import PediaKneeComplete from "./components/scoring-forms/knee-forms/pedia-knee-form/complete/PediaKneeComplete";
// import PediaKneeResult from "./components/scoring-forms/knee-forms/pedia-knee-form/result/PediaKneeResult";
import { useDispatch } from "react-redux";
import { fetchUserIdSuccess } from "./redux/actions/index";
import "./App.css";
import "./css/style.css";
import useAxios from "./axiosinstance";
import { loginData, loginVisitsData } from "./redux/actions/index";
import DoctorMap from "./components/doctor-map/DoctorMap";
import { useSelector } from "react-redux";
import DoctorRegistration from "./components/doctor-registration/DoctorRegistration";
import UpcomingVisit from "./components/upcoming-visit/UpcomingVisit";
import Document from "./components/document/Document";
import Reminder from "./components/remiender/Reminder";
import Past from "./components/medical-form/past/Past";
import History from "./components/medical-form/history/History";
import Social from "./components/medical-form/social/Social";
import Unauthorized from "./components/unauthorizedpage/Unauthorized";
import CheckIn from "./components/home/CheckIn";
import PatientComplete from "./components/doctor-registration/PatientComplete";
import Constant from "./components/constant-form/Constant";
import Journey from "./components/my-journey/Journey";
import ScoreWidgets from "./components/patient-score-widgets/ScoreWidgets";
import DoctorCode from "./components/Doctor-code/DoctorCode";
import SchedulerNotification from "./components/header/SchedulerNotification";
function AppRouter() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const login_a = useSelector((state) => state.loginPatient.arr[0]);
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lang, setlang] = useState("en");
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  // let akToken = sessionStorage.getItem("AkTOKENI");
  let akToken = localStorage.getItem("AkTOKENI");
  // let akLang = sessionStorage.getItem("lang_");
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.current.post(
          "extapp/patients/pat_signin"
        );
        // sessionStorage.setItem("isConsent","true");
        if (response.status === 201) {
          // console.log(response.data.isEmail);
          dispatch(fetchUserIdSuccess(response.data));
          if (!response.data.isEmail) {
            localStorage.removeItem("AkTOKENI");
          }
          if (!response.data.isEmail && response.data.Data.length === 0) {
            sessionStorage.setItem("isConsent", "register");
          }
        }
        if (response.status === 200) {
          dispatch(fetchUserIdSuccess(response.data));
        }
        // console.log(response.data)
      } catch (error) {
        // setMessage(error.message);
        // setMessageType("error");
        // console.log(error.message);
      }
    };
    getData();
  }, []);
  const login = useSelector((state) => state.userDetails.user);
  const login2 = useSelector((state) => state.loginPatient.arr[0]);
  let isConsentform = sessionStorage.getItem("isConsent");
  useEffect(() => {
    if (login_a !== undefined) {
      setlang(login_a.patient.user_language);
    }
  }, [login_a]);
  console.log(login, "login", login2, "login2");
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await axiosInstance.current.get(
          `extapp/patients/getDetailsOfPatient?patient_id=${akToken}&use_for=patient`
        );
        if (result.status === 200) {
          if (result.data.status) {
            dispatch(loginData(result.data.data));
            dispatch(loginVisitsData(result.data));
            sessionStorage.setItem(
              "lang_",
              result.data.data[0].patient.user_language
            );
            sessionStorage.setItem(
              "isConsent",
              result.data.data[0].patientInfo.isConsent
            );
          }
        }
      } catch (err) {
        // setMessage(err.message || "Unexpected Error!");
        // console.log(err.message, 'error');
        // setMessageType("error");
        localStorage.removeItem("AkTOKENI");
      } finally {
        console.log("api response finally");
      }
    };
    if (akToken !== null) {
      getData();
    }
  }, []);
  // useEffect(() => {
  //   if (login) {
  //     alert(1)
  //   }
  // }, [login]);
  // console.log(akToken, login.isEmail, login, "lang=============");
  console.log(login, "login.isEmaillogin.isEmail", login.count);
  console.log(isConsentform, typeof isConsentform, "isConsentform");
  // useEffect(()=>{
  //   if(isConsentform==="false"){
  //     navigate('/patient-consent-form')
  //   }
  // },[isConsentform])
  useEffect(() => {
    if (login.count !== 0) {
      sessionStorage.setItem("isConsent", "true");
    }
  }, [login.count]);



  return (
    <>
      <>
        {isConsentform === "false" ? (
          <>
            <TopHeader />
            <Routes>
              <Route path="/" element={<Constant />} />
              <Route path="/patient-consent-form" element={<Constant />} />
              {splitLocation[1] === "patient-consent-form" ||
              splitLocation[1] === "" ? (
                ""
              ) : (
                <Route path="/*" element={<Unauthorized />} />
              )}
            </Routes>
          </>
        ) : (
          ""
        )}
      </>

      {login.isEmail === true &&
        akToken !== null &&
        isConsentform === "true" && (
          <div
            className=""
            dir={lang === "ara" ? "rtl" : "ltr"}
            style={{ height: "100%" }}
          >
            <div
              className={
                splitLocation[1] === "medical-history" ||
                splitLocation[1] === "medical-past" ||
                splitLocation[1] === "medical-social"
                  ? ""
                  : lang === "ara"
                  ? "App"
                  : "App" + (isOpen ? " sidebar-collapsed" : "")
              } style={splitLocation[1] === "patient-scores"? {flexWrap:"wrap"}:{}}
            >
              {splitLocation[1] === "shoulder-treatment-form" ? (
                " "
              ) : (
                <>
                  <TopHeader />
                  <LeftSidebar onClick={() => setIsOpen(!isOpen)} />
                </>
              )}
              <div
                className={
                  lang === "ara"
                    ? "content__main_arabic "
                    : "content__main" +
                      (splitLocation[1] === "shoulder-treatment-form"
                        ? "hide_menus"
                        : "")
                }
                style={
                  splitLocation[1] === "medical-history" ||
                  splitLocation[1] === "medical-past" ||
                  splitLocation[1] === "medical-social"
                    ? { marginTop: "0px" }
                    : splitLocation[1] === "patient-scores"? {flexWrap:"wrap-reverse"}:{}
                }
              >
                <Routes>
                  <Route path="/my-profile" element={<MyProfile />} />
                  <Route path="/my-profile2" element={<MyProfileStep2 />} />
                  <Route path="/my-profile3" element={<MyProfileStep3 />} />
                  <Route path="/my-profile4" element={<MyProfileStep4 />} />
                  <Route path="/visit-clinic" element={<VisitClinic />} />
                  <Route path="/my-visits" element={<MyVisits />} />
                  <Route path="/upcoming-visits" element={<UpcomingVisit />} />
                  <Route path="/messages" element={<Messages />} />
                  <Route path="/document" element={<Document />} />
                  <Route path="/reminder" element={<Reminder />} />
                  <Route path="/medical-history" element={<History />} />
                  <Route path="/medical-past" element={<Past />} />
                  <Route path="/medical-social" element={<Social />} />
                  <Route path="/scheduler-notification" element={<SchedulerNotification />} />
                  <Route
                    path="/un-authorized-access"
                    element={<Unauthorized />}
                  />
                  <Route
                    path="/patient-complete/:akPt"
                    element={<PatientComplete />}
                  />
                  <Route path="/" element={<CheckIn />} />
                  {/* <Route path="/*" element={<NotFoundPage />} /> */}
                  <Route path="/home" element={<CheckIn />} />
                  <Route path="/my-journey" element={<Journey />} />
                  <Route path="/patient-scores" element={<ScoreWidgets />} />
                  <Route path="/doctor-code" element={<DoctorCode />} />
                  {/* <Route path="/add-document/:akId" element={<AddDocument />} />
                <Route path="/new-document/:akId" element={<NewDocument />} />
                <Route path="/edit-document" element={<EditDocument />} /> */}
                  {/* <Route path="/Add-document/:akId" element={<AddDocument />} /> */}
                </Routes>
              </div>
            </div>
          </div>
        )}
      {login.isEmail === true && akToken === null && (
        <div className="">
          <div className="">
            <TopHeader c={true} />
            <div className="">
              <div className="content__main_reg ">
                <Routes>
                  <Route path="/" element={<DoctorMap />} />
                  <Route
                    path="/patient-registration"
                    element={<DoctorRegistration />}
                  />
                  <Route path="/*" element={<Unauthorized />} />
                  <Route
                    path="/patient-complete/:akPt"
                    element={<PatientComplete />}
                  />
                  <Route path="/my-journey" element={<Journey />} />
                  <Route
                    path="/un-authorized-access"
                    element={<Unauthorized />}
                  />
                  {/* <Route path="/doctor-code" element={<DoctorCode />} /> */}
                </Routes>
              </div>
            </div>
          </div>
        </div>
      )}
      {login.isEmail === false &&
        akToken === null &&
        isConsentform === "register" && (
          <div className="" style={{ height: "100%" }}>
            <div className={"App " + (isOpen ? "sidebar-collapsed" : "")}>
              <TopHeader />
              <div className="tab-content">
                <div className="content__main_reg ">
                  <Routes>
                    <Route
                      path="/patient-registration"
                      element={<DoctorRegistration />}
                    />
                    <Route
                      path="/"
                      element={<Navigate to="/patient-registration" />}
                    />
                    <Route path="/*" element={<Unauthorized />} />
                    <Route
                      path="/patient-complete/:akPt"
                      element={<PatientComplete />}
                    />
                    <Route
                      path="/un-authorized-access"
                      element={<Unauthorized />}
                    />
                    {/* <Route path="/my-journey" element={<Journey />} />
                <Route path="/doctor-code" element={<DoctorCode/>}/> */}
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        )}
      {login.isEmail === false && akToken && isConsentform === "register" && (
        <div className="" style={{ height: "100%" }}>
          <div className={"App " + (isOpen ? "sidebar-collapsed" : "")}>
            <TopHeader />
            <div className="tab-content">
              <div className="content__main_reg ">
                <Routes>
                  <Route
                    path="/patient-registration"
                    element={<DoctorRegistration />}
                  />
                  <Route
                    path="/"
                    element={<Navigate to="/patient-registration" />}
                  />
                  <Route path="/*" element={<Unauthorized />} />
                  <Route
                    path="/patient-complete/:akPt"
                    element={<PatientComplete />}
                  />
                  <Route path="/my-journey" element={<Journey />} />
                  {/* <Route path="/doctor-code" element={<DoctorCode />} /> */}
                </Routes>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default AppRouter;
