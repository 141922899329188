import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import { DateFormate, UserDateFormat } from "../../utilities/DateFormate";
import viewIcon from "../../images/icon-view.svg";
import deleteIcon from "../../images/delete-icon.svg";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import InputField from "../custom/inputfield/InputField";
import Button from "../custom/button/Button";
import Modal from "react-bootstrap/Modal";
import useTranslation from "../customHooks/translations";
import { Hours_Minutes_Format } from "../../utilities/DateFormate";
import CheckboxNew from "../custom/checkbox/CheckboxNew";
import editIcon from "../../images/edit-icon.svg";
import DeleteConfirmation from "../custom/delete-confirmation/DeleteConfirmation";
import ConfirmPopup from "../custom/confirmation-popup/ConfirmPopup";
import { WordCutter, WordCutter2 } from "../../utilities/DocumentType";
import SortDropdown from "../custom/sortdropdown/SortDropdown";
// let akToken = localStorage.getItem("AkTOKENI");
function ReminderTable({
  data,
  active,
  updatePagination,
  DataIdget,
  timezoneD,
  timeFormat,
  setChecked,
  hideicon,
  setRefresh,
  refresh
}) {
  const translation = useTranslation();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const axiosInstance = useAxios();
  const [message, setMessage] = useState("");
  const [dddd, setDDD] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [resource, setResource] = useState("");
  const [status, setStatus] = useState("");
  const [selectTask, setSelectTask] = useState(false);
  const [call, setCall] = useState(0);

  const [
    displayConfirmationModalDelete,
    setDisplayConfirmationModalDelete,
  ] = useState(false);
  const [
    displayConfirmationModalConfirm,
    setdisplayConfirmationModalConfirm,
  ] = useState(false);
  const [displaytable, setDisplaytable] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [hide, setHide] = useState(false);
  const login = useSelector((state) => state.loginPatient.arr[0]);
  useEffect(() => {
    if (active === 1) {
      setDDD(data);
    } else {
      setDDD(data);
    }
  }, [active, data]);

  const columns = React.useMemo(
    () => [
      {
        Header: translation.Organization_ID,
        accessor: "msg_type",
      },
      {
        Header: translation.Organization_Name,
        accessor: "message",
      },
    ],
    []
  );

  const { rows } = useTable({
    columns,
    data,
  });

  const handleView = (title, description) => {
    setShow(true);
    setTitle(title);
    setMessage(description);
  };

  const handleCloseShow = () => {
    setShow(false);
    setShow1(false);
  };

  const [select, setSelect] = useState(false);
  const [Id, setId] = useState("");
  const handleStatus = async (e, id) => {
    setdisplayConfirmationModalConfirm(true);
    setId(id);
  };
  const submitConfirm = async () => {
    setDisplaytable(true);
    setSelect(true);
    setdisplayConfirmationModalConfirm(false);
    let res = await axiosInstance.current.put(
      `extapp/patients/updatePatientNote/${login.patient.ak_id}/${Id}`,
      JSON.stringify({
        status: "1",
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status === 200) {
      setRefresh(!refresh);
    }
  };


  const taskDelete = async (resource_id) => {
    const response = await axiosInstance.current.delete(
      `extapp/patients/deletePatientNote/${login.patient.ak_id}/${resource_id}?type=36`
    );

    if (response.status === true) {
      setDisplayConfirmationModalDelete(false);
      alert("Delete Succesfull");
      setShow(!show);
    }

    if (response.status === 200) {
      setRefresh(!refresh);
    }
  };

  const submitDelete = (ids) => {
    setDisplayConfirmationModalDelete(false);
    taskDelete(deleteId);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };

  const hideConfirmation1Modal = () => {
    setdisplayConfirmationModalConfirm(false);
    setId('')
  };

  const handleDelete = (e) => {
    setDeleteId(e);
    setDisplayConfirmationModalDelete(true);
    setShow(false);
    //  handleCloseShow(Modal);
  };

  const handleEdit = (title, decription, redId) => {
    setTitle(title);
    setDescription(decription);
    setResource(redId);
    setStatus(0);
    setShow1(true);
  };

  const handleNote = (e) => {
    setTitle(e);
  };
  const handleNote1 = (e) => {
    setDescription(e);
  };

  const handleSubmit = async () => {
    const updateData = {
      title: title,
      description: description,
      status: status,
    };
    try {
      const response = await axiosInstance.current.put(
        `extapp/patients/updatePatientNote/${login.patient.ak_id}/${resource}`,
        updateData
      );

      if (response.status === 200) {
        handleCloseShow();
        setRefresh(!refresh);
      }
    } catch (error) {
      console.log("update failed");
    }
  };
  // const handleUpdate = async(id) => {
  //   // let json = {
  //   //   title: title,
  //   //   description: description,
  //   //   status:status
  //   // }
  //   // let res =  axiosInstance.current.put(`extapp/patients/updatePatientNote/${login.patient.ak_id}/${resource}`,json,
  //   //   {
  //   //     headers: {
  //   //       "Content-Type": "application/json",
  //   //     }
  //   //   })
  //   let res = await axiosInstance.current.put(`extapp/patients/updatePatientNote/${login.patient.ak_id}/${id}`, JSON.stringify({
  //     status: "1"
  //   }),
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })

  // }
  const [profile, setProfile] = useState("");
  useEffect(() => {
    if (login) {
      setProfile(login.patient);
    }
  }, [login]);

  const handleSort = (e) => {
    setCall(1);
    setChecked(e)
  };
  return (
    <>
      <div
        className="tableContainer bg-white scrollbar_vert "
        id={"rrpScrollableDivs" + active}
        style={{ height: "500px" }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <SortDropdown onChange={handleSort} alp={true} />
          </div>
        </div>
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget={"rrpScrollableDivs" + active}
          loader={""}
        >
          <table role="table" className="custom-tbl mt-3">
            <thead>
              <tr role="row">

                <th
                  colSpan={1}
                  role="columnheader"
                  className="col-orgid"
                  style={{ width: "2%" }}
                >
                  {/* {translation.Information} */}

                  {/* Document Name */}
                </th>
                <th
                  colSpan={1}
                  role="columnheader"
                  className="col-orgid"
                  style={{ width: "15%" }}
                >
                  {translation.title}
                  {/* Document Name */}
                </th>

                <th colSpan={1} role="columnheader" className="col-status">
                  {translation.description}

                  {/* type */}
                </th>
                <th colSpan={1} role="columnheader" className="col-status">
                  {translation.Date_Time}
                </th>

                <th colSpan={1} role="columnheader" className="col-status">
                  {translation.Action}
                </th>

              </tr>
            </thead>
            <tbody>
              {dddd &&
                dddd.map((row, index) => (
                  <tr role="row">
                    <td role="cell" className="col-orgid">
                      {/* <Checkbox onChange={handleStatus}/> */}
                      {active === 1 ? (
                        <div className="">
                          {row.resource_id === Id ? (
                            <CheckboxNew
                              onChange={(e) => handleStatus(e, row.resource_id)}
                              styleClass="custom_check_box tick f_400 large mt_10"
                              id={row.resource_id}
                              checked={true}
                            />
                          ) : (
                            <CheckboxNew
                              onChange={(e) => handleStatus(e, row.resource_id)}
                              styleClass="custom_check_box tick f_400 large mt_10"
                              id={row.resource_id}
                              checked={false}
                            />
                          )}
                        </div>
                      ) : null}
                    </td>
                    <td role="cell" className="col-orgid">
                      {WordCutter2(row.title, 1,10)}

                    </td>
                    <td className="col">
                      {WordCutter2(row.description, 2,10)}
                      {/* {DateFormate(row.date)}{" "}
                    {HoursMinutesFormat(
                      row.Description,
                    //   timeFormat === "1" ? true : false,
                    //   timezoneD
                    )} */}
                    </td>
                    <td className="col">
                      {row.created_at}

                      {" "}  {Hours_Minutes_Format(
                        row.time,
                        timeFormat === "1" ? true : false,
                        timezoneD
                      )} </td>
                    {/* <td className="col">
                    {row.date}
                  </td> */}

                    <td role="cell" className="col-orgname ">
                      {row.file_extension === "hevc" ||
                        row.file_extension == "mov" ||
                        row.file_extension == "doc" ||
                        row.file_extension == "xlsx" ? (
                        ""
                      ) : (
                        <img
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={translation.View}
                          className={`action_icons c_pointer `}
                          src={viewIcon}
                          alt="Icon"
                          onClick={() => handleView(row.title, row.description)}
                        />
                      )}

                      {!hideicon && (
                        <>
                          <img
                            src={editIcon}
                            onClick={() =>
                              handleEdit(
                                row.title,
                                row.description,
                                row.resource_id
                              )
                            }
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={translation.Edit}
                            className={`action_icons c_pointer ms-2`}
                          ></img>
                          <img
                            src={deleteIcon}
                            onClick={() => handleDelete(row.resource_id)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={translation.delete}
                            className={`action_icons c_pointer ms-2`}
                          ></img>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModalDelete}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
      />
      <ConfirmPopup
        showModal={displayConfirmationModalConfirm}
        confirmModal={submitConfirm}
        hideModal={hideConfirmation1Modal}
        message={translation.Are_you_sure_want_to_done_the_remainder}
      />
      {/* <DeleteConfirmation
        showModal={displaytable}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        
      /> */}

      {/* <Modal
        show={true}
        // onHide={handleCloseShow}
        dialogClassName="modal-lg patient_notes_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{"View"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <h1>Are you want to work in Done</h1>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-start" }}>

          <Button
            // onClick={handleContinue}
            value="Continue"
            buttonStyle="btn_fill mr_20 "
          />
        </Modal.Footer>
      </Modal> */}

      <Modal
        show={show}
        onHide={handleCloseShow}
        dialogClassName="modal-lg patient_notes_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{translation.View}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="msg-box">
            <span className=""> {title.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}{" "}
                <br />

              </React.Fragment>
            ))}</span>
          </div>
          <div className="msg-box">

            <span className=""> {message.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}{" "}
                <br />
              </React.Fragment>
            ))}</span>

          </div>
        </Modal.Body>
        {/* <Modal.Footer style={{ justifyContent: "flex-start" }}>
                <Button
                  onClick={handleContinue}
                  value="Continue"
                  buttonStyle="btn_fill mr_20 "
                />
              </Modal.Footer> */}
      </Modal>

      <Modal
        show={show1}
        onHide={handleCloseShow}
        dialogClassName="modal-lg patient_notes_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{translation.Edit}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div>
            <InputField
              label={translation.title}
              value={title}
              type="text"
              placeholder={translation.enter_your_title_here}
              onChange={handleNote}
              // rows={drop === "text" ? 10 : 5}
              styleClass="mw-100"
            // mic={true}
            />
          </div>
          <div className="col-sm-12 col-md-12">
            <InputField
              label={translation.description}
              value={description}
              type="textarea"
              placeholder={translation.enter_your_description_here}
              onChange={handleNote1}
              rows="5"
              // rows={drop === "text" ? 10 : 5}
              styleClass="mw-100 scrollbar_vert reminder_box form-group"
            // mic={true}
            />
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-end" }}>
          <Button
            onClick={() => handleSubmit()}
            value={translation.Update}
            buttonStyle="btn_fill mr_20 "
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReminderTable;
