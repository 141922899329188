import React, { useState, useEffect } from "react";
import useTranslation from "../customHooks/translations";
import "./upcoming.css";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import moment from "moment-timezone";

export default function UpcomingVisit() {
  const translation = useTranslation();
  let akToken = localStorage.getItem("AkTOKENI");
  const axiosInstance = useAxios();
  const [profile, setProfile] = useState("");
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const [timezoneD, setTimeZone] = useState("");
  const [datas, setDatas] = useState([]);

  // const [message, setMessage] = useState(false);
  // const [messageType, setMessageType] = useState();

  useEffect(() => {
    const getData = async () => {
      const response = await axiosInstance.current.get(
        `extapp/patients/get_patient_scheduler?ak_pt=${akToken}&limit=100&offset=0`
      );
      setDatas(response.data.data);
    };

    getData();
  }, []);

  useEffect(() => {
    if (login) {
      setTimeZone(login.patient.timezone);
      setProfile(login.patient);
    }
  }, [login]);

  // const timezone = "Europe/Istanbul";

  const dateChange = (i, formatType) => {
    let formatString;

    if (formatType === "1") {
      formatString = "DD-MM-YYYY";
    } else if (formatType === "2") {
      formatString = "MM-DD-YYYY";
    } else {
      formatString = "YYYY-MM-DD";
    }
    return moment(i).tz(timezoneD).format(formatString);
  };
  // console.log(datas);
  return (
    <>
      <div className="my_visit_screen mb-3">
        <div className="row page_header">
          <div className="col-sm-6 col-md-6">
            <h4 className="ph_title">{translation.upcoming_visits}</h4>
          </div>
        </div>
      </div>

      {datas.length > 0 ? (
        datas.map((row) => (
          <>
            <div className="whitebox pt_30 w-50">
              <div className="col-12">
                <div className="chec_lb_before">
                  <h6>
                    {translation.Doctor_Name} :
                    <span className="text_visit text-capitalize ms-1">
                      {row.Doctor_Name}
                    </span>
                  </h6>
                  <h6>
                    {translation.Appointment_Date_Time} :
                    <span className="text_visit ms-1">
                      {/* {dateChange(row.Appointement_Date)}  */}
                      {dateChange(row.Appointement_Date, profile.dateFormat)}
                      {moment(row.Appointement_Date)
                        .tz(timezoneD)
                        .format(" HH:mm:ss")}
                      -
                      {moment(row.Appointment_Time)
                        .tz(timezoneD)
                        .format(" HH:mm:ss")}
                    </span>
                  </h6>
                  <h6>
                    {translation.Clinic_Name} :
                    <span className="text_visit"> {row.Clinic_Name}</span>
                  </h6>
                  <h6>
                    {translation.Clinic_Address} :
                    <span className="text_visit"> {row.Clinic_Address}</span>
                  </h6>
                </div>
              </div>
            </div>
          </>
        ))
      ) : (
        // <UpcomingVisitTable data={datas} />
        <div className="panel-body p_25">
          <div className="no_data">{translation.error_upcoming_appointments}</div>
        </div>
      )}

    </>
  );
}
