import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useTranslation from "../../customHooks/translations";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import Dropdown from "../../custom/dropdown/Dropdown";
import RadioSwitch from "../../custom/radio-switch/RadioSwitch";
import InputField from "../../custom/inputfield/InputField";
import {
  useSearchParams,
  useParams,
  useNavigate,
  Link,
} from "react-router-dom";
import backIcon from "../../../images/back.png";
import nextWhiteIcon from "../../../images/next-white.png";
import nextIcon from "../../../images/next.png";
import {
  updateHistoryComplaintFieldsScore,
  updateHistoryComplaintScore,
  updateSocialComplaintScore,
  updatePastComplaintScore,
  updateSocialComplaintFieldsScore,
  updateHandWristScores
} from "../../../redux/actions/GeneralAction";
import useAxios from "../../../axiosinstance";
import Message from "../../custom/toster/Message";

export default function Social() {
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  // const [show, setShow] = useState(0);
  const [live, setLive] = useState("0");
  const [lives, setLives] = useState("");
  const [smoke, setSmoke] = useState("0");
  const [smokes1, setSmokes1] = useState("");

  const [smokes, setSmokes] = useState("");
  const [smokePast, setSmokePast] = useState("0");
  const [smokePastText, setSmokePastText] = useState("");
  const [alcohol, setAlcohol] = useState("0");
  const [alcoholText, setAlcoholText] = useState("");
  const [drugs, setDrugs] = useState("0");
  const [chk, setChk] = useState(false);
  const [backD, setbackD] = useState("");
  const axiosinstance = useAxios();
  const navigate = useNavigate();

  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState("");
  const [Show, setShow] = useState(false);

  const Social = useSelector((state) => state.GeneralFormReduces.Social[0]);
  const json = useSelector((state) => state.GeneralFormReduces);
  let akToken = localStorage.getItem("AkTOKENI");
  const save = () => {
    axiosinstance.current
      .get(`extapp/doctors/getMedicalSummaryList?ak_id=${akToken}&doctor_id=&is_patient=${1}`)
      .then((res) => {
        dispatch(updateHistoryComplaintScore(res.data.data.History));
        dispatch(updateSocialComplaintScore(res.data.data.Social));
        dispatch(updateHandWristScores(res.data.data.status))
        dispatch(updatePastComplaintScore(res.data.data.Past));

        setChk(true);
      })
      .catch((err) => {
        if(err.response!==undefined ?err.response.status===401:null){
          navigate("/un-authorized-access")
         }
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setLive(Social.live);
    setLives(Social.lives);
    setSmoke(Social.smoke);
    setSmokes1(Social.smokes1);
    setSmokes(Social.smokes);
    setSmokePast(Social.smokePast);
    setSmokePastText(Social.smokePastText);
    setAlcohol(Social.alcohol);
    setAlcoholText(Social.alcoholText);
    setDrugs(Social.drugs);
    setDrop1(Social.drop1);
  }, [Social]);

  // const updateDate = () => {
  //   axiosinstance.current
  //     .patch(
  //       `extapp/forms/update_all_form_type?resource_id=${rId}&action=0`,
  //       json,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res);
  //     });
  // };
  // useEffect(() => {
  //   if (chk) {
  //       updateDate();
  //   }
  // }, [Social]);

  // const backData = () => {
  //   axiosinstance.current
  //     .get(`extapp/forms/urgent_data?resource_id=${rId}`)
  //     .then((res) => {
  //       setbackD(res.data.data);
  //       sessionStorage.setItem("ptid",res.data.data.ak_pt)
  //     });
  // };
  // useEffect(() => {

  //   backData();
  // }, []);

  const handleLive = (e) => {
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "live",
        value: e,
      })
    );

    // console.log(e)
    setLive(e);
  };

  const handleLives = (e) => {
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "lives",
        value: e,
      })
    );
    setLives(e);
  };
  const handleSmoke = (e) => {
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "smoke",
        value: e,
      })
    );
    setSmoke(e);
  };
  const handleSmokes = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "smokes",
        value: newValue,
      })
    );
    setSmokes(newValue);
  };
  const handleSmokes1 = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "smokes1",
        value: newValue,
      })
    );
    setSmokes1(newValue);
  };
  const handleSmokePast = (e) => {
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "smokePast",
        value: e,
      })
    );
    setSmokePast(e);
  };
  const handleSmokePastText = (e) => {
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "smokePastText",
        value: e,
      })
    );
    setSmokePastText(e);
  };
  const handleAlcohol = (e) => {
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "alcohol",
        value: e,
      })
    );
    setAlcohol(e);
  };
  const handleAlcoholText = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "alcoholText",
        value: newValue,
      })
    );
    setAlcoholText(newValue);
  };
  const handleDrugs = (e) => {
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "drugs",
        value: e,
      })
    );
    setDrugs(e);
  };

  const [drop1, setDrop1] = useState("");
  const handle_switch1 = (e) => {
    dispatch(
      updateSocialComplaintFieldsScore({
        key: "drop1",
        value: e,
      })
    );
    setDrop1(e);
  };

  useEffect(() => {
    dispatch(updateHandWristScores("1"));
  }, [Social])

  const continueRedirect = (e) => {
    try{

    axiosinstance.current
      .put(
        `extapp/doctors/updateSummary?ak_id=${akToken}&doctor_id=&is_patient=${1}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status) {
          setMessage(translation.medical_success)
          setMessageType("success");
          setShow(true)
          setTimeout(()=>{
            navigate("/my-profile");
          },5000)
          // setTimeout(alert("h"),60000)
          // navigate("/my-profile");
        }
      })
    }catch(err){
      if(err.response.status===401){
        navigate("/un-authorized-access")
       }
    }
  };

  useEffect(() => {
    setShow(false)
  }, [2000])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100" style={{marginTop:"72px"}}>
          <div className="panel">
          {Show && <Message message={message} type={messageType} />}
            <div className="panel-body p_25">

              <h4 className="ph_title f_40">{translation.patient_general_social_history}</h4>
              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  <div className="mb_10">
                    <RadioSwitch
                      labelLeft={translation.patient_general_social_history_q1_a1}
                      labelRight={translation.patient_general_social_history_q1_a2}
                      idRight="affectLiveYes"
                      idLeft="affectsLiveNo"
                      name="affectsSleepR"
                      title={translation.patient_general_social_history_q1}
                      styleClass=""
                      value={live}
                      onChange={handleLive}
                      placeholder={translation.Select}
                    />
                    {live == "0" ? (
                      <>
                        {/* <label className="label_title">if no who lives with you ?</label> */}
                        <InputField
                          label={translation.patient_general_social_history_q1_a1_q1}
                          // value={note}
                          type="textarea"
                          placeholder={translation.patient_general_social_history_q1_a1_q1_placeholder}
                          rows="4"
                          cols="20"
                          styleClass="h_100"
                          value={lives}
                          onChange={handleLives}
                          maxLength={500}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_general_social_history_q2_a1}
                        labelRight={translation.patient_general_social_history_q2_a2}
                        idRight="affectSmokeYes"
                        idLeft="affectSmokeNo"
                        name="affectsSmoke"
                        title={translation.patient_general_social_history_q2}
                        styleClass=""
                        value={smoke}
                        onChange={handleSmoke}
                        placeholder={translation.Select}
                      />

                      {smoke == "1" ? (
                        <>
                          <InputField
                            label={translation.patient_general_social_history_q2_a2_q1}
                            // value={note}
                            type="text"
                            placeholder={translation.patient_general_social_history_q2_a2_q1_placeholder}
                            rows="4"
                            cols="20"
                            styleClass="h_100 w-25"
                            value={smokes}
                            onChange={handleSmokes}
                            maxLength={500}
                          />
                          <InputField
                            label={translation.patient_general_social_history_q2_a2_q2}
                            // value={note}
                            type="text"
                            placeholder={translation.patient_general_social_history_q2_a2_q2_placeholder}
                            rows="4"
                            cols="20"
                            styleClass="h_100 w-25"
                            value={smokes1}
                            onChange={handleSmokes1}
                            maxLength={500}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      {smoke == "0" ? (
                        <>
                          <div className="mb_10">
                            <RadioSwitch
                              labelLeft={translation.patient_general_social_history_q3_a1}
                              labelRight={translation.patient_general_social_history_q3_a2}
                              idRight="affectSmokePastYes"
                              idLeft="affectSmokePastNo"
                              name="affectsSmokePast"
                              title={translation.patient_general_social_history_q3}
                              styleClass=""
                              value={smokePast}
                              onChange={handleSmokePast}
                              placeholder={translation.Select}
                            />
                            {smokePast === "1" ? (
                              <InputField
                                label={translation.patient_general_social_history_q3_a2_q1}
                                // value={note}
                                type="text"
                                placeholder={translation.patient_general_social_history_q3_a2_q1_placeholder}
                                rows="4"
                                cols="20"
                                styleClass="h_100 w-25"
                                value={smokePastText}
                                onChange={handleSmokePastText}
                                maxLength={500}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>



                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_general_social_history_q4_a1}
                        labelRight={translation.patient_general_social_history_q4_a2}
                        idRight="affectAlcoholYes"
                        idLeft="affectAlcoholNo"
                        name="affectsAlcohol"
                        title={translation.patient_general_social_history_q4}
                        styleClass=""
                        value={alcohol}
                        onChange={handleAlcohol}
                        placeholder={translation.patient_general_social_history_q4_a2_q1_placeholder}
                      />
                      {alcohol === "1" ? (
                        <InputField
                          label={translation.socail9}
                          // value={note}
                          type="text"
                          placeholder={translation.patient_general_social_history_q4_a2_q1_placeholder}
                          rows="4"
                          cols="20"
                          styleClass="h_100 w-25"
                          value={alcoholText}
                          onChange={handleAlcoholText}
                          maxLength={500}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="mb_10">
                      <RadioSwitch
                        labelLeft={translation.patient_general_social_history_q5_a1}
                        labelRight={translation.patient_general_social_history_q5_a2}
                        idRight="affectDrugsYes"
                        idLeft="affectDrugsNo"
                        name="affectsDrugs"
                        title={translation.patient_general_social_history_q5}
                        styleClass=""
                        value={drugs}
                        onChange={handleDrugs}
                        placeholder={translation.patient_general_social_history_q5_a2_placeholder}
                      />
                      {drugs === "1" && (
                        <InputField
                          value={drop1}
                          type="textarea"
                          placeholder={translation.patient_general_social_history_q5_a2_placeholder}
                          rows="4"
                          cols="20"
                          onChange={handle_switch1}
                        />
                      )}

                    </div>

                  </div>
                </div>
                {/* {doctorEnable !== "true" && ( */}
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link to={"/medical-past"} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
