import React, {useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../images/back.png";
import nextWhiteIcon from "../../images/next-white.png";
import nextIcon from "../../images/next.png";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
import { Validators } from "../../utilities/Validator";
import Checkbox from "../custom/checkbox/Checkbox";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import useTranslation from "../customHooks/translations"



function MyProfileStep3() {
  const translation = useTranslation()
  let axiosInstance = useAxios();
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [cardNumber, setCardNumber] = useState("");
  const [ref, setRef] = useState("");
  const [expiry, setExpiry] = useState("");
  const [healthFund, setHealthFund] = useState("");
  const [membershipNumber, setMembershipNumber] = useState("");
  const [membershipNumberErr, setMembershipNumberErr] = useState(false);
  const [claimNumber, setClaimNumber] = useState(null);
  const [workCover, setWorkCover] = useState("");
  const [employer, setEmployer] = useState("");
  const [dvaCard, setDvaCard] = useState("");
  const [dvaNumber, setDvaNumber] = useState("");
  const [dvaNumberErr, setDvaNumberErr] = useState(false);
  const [defencePersonnel, setDefencePersonnel] = useState("");
  const [acceptance, setAcceptance] = useState(false);
  const [norefErr, setNorefErr] = useState(false)
  const [cardNumberErr, setCardNumberErr] = useState(false)
  const acceptanceRef = useRef();
  const navigate = useNavigate();
  const login = useSelector((state) => state.loginPatient.arr[0]);

  const handleAcceptance = (e) => {
    setAcceptance(e);
  };
  useEffect(() => {
    if (login) {
      setProfile(login.patient);
      setCardNumber(login.patientInfo.card_number);
      setRef(login.patientInfo.ref);
      setExpiry(login.patientInfo.expiry);
      setHealthFund(login.patientInfo.health_fund);
      setMembershipNumber(login.patientInfo.membership_number);
      setClaimNumber(login.patientInfo.claim_number);
      setWorkCover(login.patientInfo.work_cover);
      setEmployer(login.patientInfo.employer);
      setDvaCard(login.patientInfo.dva_card);
      setDvaNumber(login.patientInfo.number);
      setDefencePersonnel(login.patientInfo.defence_personnel);
    }
  }, [login]);
  const handleCardNumber = (e, err) => {
    setCardNumber(e);
    setCardNumberErr(err)
  };
  const handleRef = (e, err) => {
    setRef(e);
    setNorefErr(err)
  };


  const handleExpiry = (e) => {
    setExpiry(e)

  }

  let expdate;
  const expriy_format = (value) => {
  
    if (value === undefined) {
      expdate = ""
    } else {
      expdate = value
    }
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);

    return expDateFormatter;
  };

  const [experr, setHomeerr] = useState(false)
  
  let x = expiry === undefined ? expiry === undefined : expiry.length === 0
  const onlyNumbersAndParentheses = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;
  useEffect(() => {
    if (onlyNumbersAndParentheses.test(expiry) || x === true) {
      setHomeerr(false)
    } else {
      setHomeerr(true)
    }
  }, [expiry])


let exp=expiry===undefined?"04/23":expiry
let expiry2=exp.slice(0,5)
const [expiryMonth, expiryYear] = expiry2.split("/");
const expiryYearNumber = parseInt(`20${expiryYear}`);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();


const earliestExpiryYear = currentYear + (currentDate.getMonth() > expiryMonth - 1 ? 1 : 0);

useEffect(()=>{
if(expiry !=="" && expiry !==undefined){

  if (expiryYearNumber < earliestExpiryYear) {
    setHomeerr(true)

  } else {
    setHomeerr(false)
 
  }
}
},[expiry])

  const handleHealthFund = (e) => {
    setHealthFund(e);
  };
  const handleMembershipNumber = (e, err) => {
    setMembershipNumber(e);
    setMembershipNumberErr(err);
  };
  const handleClaimNumber = (e) => {
    setClaimNumber(e);
  };
  const handleWorkCover = (e) => {
    setWorkCover(e);
  };
  const handleEmployer = (e) => {
    setEmployer(e);
  };
  const handleDvaCard = (e) => {
    setDvaCard(e);
  };
  const handleDvaNumber = (e, err) => {
    setDvaNumber(e);
    setDvaNumberErr(err);
  };
  const handleDefencePersonnel = (e) => {
    setDefencePersonnel(e);
  };
  const continueRedirect = (e) => { 


    if (!acceptance) {
      acceptanceRef.current.errUpdate();
    }
    if (norefErr) {
      return;
    }
    if (experr) {
      return
    }
    if (cardNumberErr) {
      return;
    }
    if (membershipNumberErr) {
      return;
    }
    if (dvaNumberErr) {
      return;
    }

    if (!acceptance) {
      console.log("error");
    } else {
      const json = JSON.stringify({
        card_number: cardNumber,
        expiry: expiry,
        ref: ref,
        health_fund: healthFund,
        membership_number: membershipNumber,
        claim_number: claimNumber,
        work_cover: workCover,
        employer: employer,
        dva_card: dvaCard,
        number: dvaNumber,
        defence_personnel: defencePersonnel,
      });
      axiosInstance.current
        .post(
          `extapp/patients/create_Profile?ak_id=${profile.ak_id}&type=2`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          navigate("/home");
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="myPprofile_screen mb_100">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4 className="ph_title">{translation.myProfile}</h4>
            </div>
          </div>

          <div className="panel">
            <div className="panel-body p_25">
              <div className="row">
                <div className="col-sm-6 col-md-5 mb_20">
                  <div className=" d-flex">
                    <InputField
                      label={translation.Medicare_Card_Number}
                      value={cardNumber}
                      type="text"
                      placeholder="_ _ _ _ _ _ _ _ _ "
                      onChange={handleCardNumber}
                      formGroupClass=" mr_20"
                      styleClass="br_10"

                      validators={[
                        {
                          check: Validators.number,
                          message: translation.Only_I,
                        },
                        {
                          check: Validators.maxmobile,
                          message: translation.maximum_10_Numbers_are_allowed,
                        },
                        {
                          check: Validators.spacial,
                          message: translation.special_characters_are_not_allowed,
                        },
                      ]}
                    />
                    <InputField
                      label={translation.Ref}
                      value={ref}
                      type="text"
                      placeholder="_"
                      onChange={handleRef}
                      formGroupClass="w-108"
                      styleClass="br_10"
                      validators={[
                        {
                          check: Validators.number,
                          message: translation.Only_I,
                        },
                        {
                          check: Validators.max_refnumber,
                          message: translation.maximum_digit_are_allowed,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-5 mb_20">
                  <InputField
                    label={translation.Expiry}
                    type="text"
                    placeholder={translation.mmyy}
                    onChange={handleExpiry}
                    value={expriy_format(expiry)}
                    formGroupClass="w-108"
                    styleClass="br_10"
                  />
                  {experr && (<span className="text-danger">{translation.invalid_date}</span>)}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Private_Health_Fund}
                    value={healthFund}
                    type="text"
                    placeholder={translation.health_found}
                    onChange={handleHealthFund}
                    styleClass="br_10 "
                  />
                </div>
                <div className="col-sm-5 col-md-5 mb_20">
                  <InputField
                    label={translation.Membership_Number}
                    value={membershipNumber}
                    type="text"
                    placeholder="_ _ _ _ _ _ _ _ _ _"
                    onChange={handleMembershipNumber}
                    styleClass="br_10 "
                    validators={[
                      {
                        check: Validators.number,
                        message: translation.Only_I,
                      },
                      {
                        check: Validators.maxmobile,
                        message: translation.maximum_10_digits_are_allowed,
                      },
                      {
                        check: Validators.spacial,
                        message: translation.special_characters_are_not_allowed,
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <Dropdown
                    data={[
                      { value: "1", label: translation.Work_Cover },
                      {
                        value: "2",
                        label: translation.Third_Party_Claim_Number,
                      },
                    ]}
                    label={translation.Claim_Type}
                    styleClass=" br_10"
                    value={claimNumber}
                    placeholder={translation.Not_Applicable}
                    onChange={handleClaimNumber}
                  />
                </div>
              </div>
              {claimNumber === "1" && (
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label={translation.Work_Cover}
                      value={workCover}
                      type="text"
                      placeholder="_ _ _ _ _ _ _ _ _ _"
                      onChange={handleWorkCover}
                      styleClass="br_10 "
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label={translation.Employer}
                      value={employer}
                      type="text"
                      placeholder={translation.employer_name}
                      onChange={handleEmployer}
                      styleClass="br_10 "
                    />
                  </div>
                </div>
              )}
              {claimNumber === "2" && (
                <div className="row">
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label={translation.Third_Party_Claim_Number}
                      value={workCover}
                      type="text"
                      placeholder="_ _ _ _ _ _ _ _ _ _"
                      onChange={handleWorkCover}
                      styleClass="br_10 "
                    />
                  </div>
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label={translation.Third_Party_Name}
                      value={employer}
                      type="text"
                      placeholder={translation.Third_Party_Name}
                      onChange={handleEmployer}
                      styleClass="br_10 "
                    />
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <Dropdown
                    data={[
                      { value: 0, label: translation.not_available },
                      { value: 1, label: translation.available },
                    ]}
                    label={translation.DVA_Concession_Card}
                    styleClass=" br_10"
                    value={dvaCard}
                    placeholder={translation.Select}
                    onChange={handleDvaCard}
                  />
                </div>
                {dvaCard === "1" && (
                  <div className="col-sm-5 col-md-5 mb_20">
                    <InputField
                      label={translation.Number}
                      value={dvaNumber}
                      type="text"
                      placeholder="_ _ _ _ _ _ _ _ _ _"
                      onChange={handleDvaNumber}
                      styleClass="br_10 "
                    />


                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-sm-5 col-md-5 mb_20">
                  <Dropdown
                    data={[
                      { value: "0", label: translation.No },
                      { value: "1", label: translation.Yes },
                    ]}
                    label={translation.Defence_Personnel}
                    styleClass=" br_10"
                    value={defencePersonnel}
                    placeholder={translation.Select}
                    onChange={handleDefencePersonnel}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-10 col-md-10 mt_20 mb_20">
                  <div
                    className={`form-group custom_check_box tick f_400 large`}
                  >
                    <Checkbox
                      label={translation.I_agree}
                      selected={acceptance}
                      ref={acceptanceRef}
                      validators={[
                        {
                          check: Validators.required,
                          message: translation.This_field_is_required,
                        },
                      ]}
                      onChange={handleAcceptance}
                      styleClass="custom_check_box"
                      id="agree_details"
                      link={
                        <a
                          target="_blank"
                          href="https://akunah.com/privacy-policy"
                          rel="noopener noreferrer"
                          className="pt_privaa_btn"
                        >
                          {" "}{translation.Privacy_Policy}
                        </a>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6">
                <Link to="/my-profile2" className="assm_back_btn">
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </Link>
              </div>
              <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MyProfileStep3;
