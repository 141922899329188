
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import calenderIconFill from "../../images/Calendar.svg";
import dataExportIconFill from "../../images/icon7-fill.svg";
import settingsIconFill from "../../images/Settings.svg";
import documenticon from "../../images/Documents.svg";
import Modal from "react-bootstrap/Modal";
import chatIcon from '../../images/chat-icon.svg'
import reminder from '../../images/Timeline.svg'
import icon_info from '../../images/info-icon_white.svg';
import rightArrow from "../../images/arrow-bright.svg"

// import reminder2 from '../../images/remin.png'
// import reminder3 from '../../images/re.png'
// import reminder3 from '../../images/re.png'

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import { useSelector } from "react-redux";

import "react-pro-sidebar/dist/css/styles.css";
import "./LeftSidebar.css";
import arrowCollapse from "../../images/m-arrow-left.svg";
import homeIconFill from "../../images/Account.svg";
import med from "../../images/med.svg";

import mgmtPlanIconFill from "../../images/icon3-fill.svg";

import Home from "../../images/Home.svg"
import useTranslation from "../customHooks/translations";
import Settings from "../settings/Settings";

const LeftSidebar = (props) => {
  const translation = useTranslation();
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const loginData = useSelector((state) => state.loginPatient.login);
  const [checkVisit, setCheckVisit] = useState(false);

  const [name, setName] = useState('');
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (loginData !== undefined) {
      setCheckVisit(loginData.myVisit);
    }
  }, [loginData]);
  useEffect(() => {
    if (login !== undefined) {
      setName(login.patient.firstname);
      setlastName(login.patient.lastname);
      setEmail(login.patient.email);
    }
  }, [login]);

  // const { keycloak, initialized } = useKeycloak();

  const location = useLocation();


  const { pathname } = location;


  const splitLocation = pathname.split("/");


  const [menuCollapse, setMenuCollapse] = useState(false);


  const menuIconClick = () => {

    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };



  const [show, setShow] = useState(false);
  let akToken = localStorage.getItem("AkTOKENI");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

      <ProSidebar collapsed={menuCollapse} id="sidebar_main">
        <SidebarHeader>
          <br />
          <div
            className="closemenu"
            onClick={() => {
              menuIconClick();
              props.onClick();
            }}
          >
            {/* changing menu collapse icon on click */}
            {<img src={arrowCollapse} className="left-logo" alt="logo" />}
          </div>
        </SidebarHeader>
        <SidebarContent className="left_sidebar_body">

          <Menu iconShape="square">
          {name && (
              <MenuItem
                className={
                  splitLocation[1] === "" || splitLocation[1] === "home"
                    ? "active"
                    : ""
                }
                icon={
                  <img
                     src={Home}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={translation.Check_in}
              >
                {translation.Home}
                {/* <img className="c_pointer ms-2 me-2" src={icon_info} data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={translation.info_icon} alt='icon' /> */}
                <Link to="/home" />
              </MenuItem>
            )}
            <MenuItem
              className={

                splitLocation[1] === "my-profile" ||
                  splitLocation[1] === "my-profile2" ||
                  splitLocation[1] === "my-profile3" ||
                  splitLocation[1] === "my-profile4"
                  ? "active"
                  : ""
              }
              icon={
                <img src={homeIconFill} alt="menu-icon" className="nav_icon" />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.myProfile}
            >
              {translation.myProfile}
              <Link to="/my-profile" />
            </MenuItem>

            <MenuItem
              className={splitLocation[1] === "document" ? "active" : ""}
              icon={
                <img
                  src={documenticon}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.document}
            >
              {translation.document}
              <Link to="/document" />
            </MenuItem>



           

            <MenuItem
              className={splitLocation[1] === "upcoming-visits" ? "active" : ""}
              icon={
                <img
                  src={calenderIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.upcoming_visits}
            >
              {translation.upcoming_visits}
              <Link to="/upcoming-visits" />
            </MenuItem>

            {/* {checkVisit && ( */}
              <MenuItem
                className={splitLocation[1] === "my-visits" ||splitLocation[1] === "my-journey" || splitLocation[1] === "patient-scores"  ? "active" : ""}
                icon={
                  <img
                    src={dataExportIconFill}
                    alt="menu-icon"
                    className="nav_icon"
                  />
                }
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={translation.myJourney}
              >
                {translation.myJourney}
                <Link to="/my-journey" />
              </MenuItem>
            {/* )} */}
            {/* <MenuItem
              className={splitLocation[1] === "forms-map" ? "active" : ""}
              icon={
                <img
                  src={patientsIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              Demographics
              <Link to="/forms-map" />
            </MenuItem> */}

            {/* <MenuItem
              className={
                splitLocation[1] === "radiology" ? "active" : ""
              }
              icon={
                <img
                  src={opNotesIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              {translation.Radiology}
              <a href={'https://pacs.akunah.com/sign-in?ReturnUrl=%2f'} target="_blank" rel="noreferrer"></a>
            </MenuItem>

            <MenuItem
              className={
                splitLocation[1] === "pathology" ? "active" : ""
              }
              icon={
                <img
                  src={pathologyPlanIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              {translation.Pathology}
              <Link to="/pathology" />
            </MenuItem> */}

            {/* <MenuItem
              className={splitLocation[1] === "prescription" ? "active" : ""}
              icon={
                <img
                  src={mgmtPlanIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
            >
              Prescription
              <Link to="/prescription" />
            </MenuItem> */}
            <MenuItem
              className={splitLocation[1] === "akmed" ? "active" : ""}
              icon={<img src={med} alt="menu-icon" className="nav_icon" />}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.akmed}
            >
              {translation.akmed}

              <a
                href={"https://med.akunah.com/"}
                target="_blank"
                rel="noreferrer"
              ></a>
            </MenuItem>

            <MenuItem
              className={splitLocation[1] === "reminder" ? "active" : ""}
              icon={
                <img
                  src={reminder}
                  alt="menu-icon"
                  className="nav_icon"
                  // height="25px"

                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.reminder}
            >
              {translation.reminder}
              <Link to="/reminder" />
            </MenuItem>
            <MenuItem
              className={splitLocation[1] === "messages" ? "active" : ""}
              icon={
                <img
                  src={chatIcon}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.Message}
            >
              {translation.Message}
              <Link to="/messages" />
            </MenuItem>



            <MenuItem
              onClick={handleShow}
              icon={
                <img
                  src={settingsIconFill}
                  alt="menu-icon"
                  className="nav_icon"
                />
              }
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translation.settings}
            >
              {translation.settings}
            </MenuItem>
            <SidebarFooter>
              <span
                className="user_letter"
              >
                {
                  name !== '' && (

                    name.slice(0, 1) +
                    lastName.slice(0, 1)
                  )
                }

              </span>
              <div className="username-info">
                <h5>
                  {name} {lastName}
                </h5>
                <p>({akToken})</p>
                <span>{email}</span>
              </div>
              <p className="app_ver_txt_menu_responsive">
                {translation.AppVersion}: {process.env.REACT_APP_VERSION}
              </p>
            </SidebarFooter>
          </Menu>
        </SidebarContent>

      </ProSidebar>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        // keyboard={false}
        dialogClassName="modal-lg"
        className="settings_ppup_main"
      >
        <Modal.Header closeButton>
          <Modal.Title>{translation.settings}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="settings_ppup_bdy">
          <Settings />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LeftSidebar;
