import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import useTranslation from "../customHooks/translations";
import Button from "../custom/button/Button";
import { useSelector } from "react-redux";
import useAxios from "../../axiosinstance";
import { DateFormate, UserDateFormat, DateFormatWithTimeZone } from "../../utilities/DateFormate";

export default function FormVisible({ doctor_name, org_name, type, openModal, formattedDate, setModalTrue, delerteResourceId, typeForm,
  setRefs,
  refs,
  setdelerteResourceId,
  setType
}) {
  const translation = useTranslation();
  let axiosInstance = useAxios();
  const login = useSelector((state) => state.loginPatient.arr[0]);
  const [akptId, setAkptId] = useState("");
  const [modelShow1, setModelShow1] = useState(false);
  const [datefor, setDatefor] = useState("")



  useEffect(() => {
    setModelShow1(openModal)
  }, [openModal])

  useEffect(() => {
    if (login) {
      setAkptId(login.patient.ak_id);
      setDatefor(login.patient.dateFormat)
    }
  }, [login]);
  let url =
    process.env.REACT_APP_FORM_URL +
    "patient-personal-data" +
    `/${window.btoa(akptId)}` +
    `/${window.btoa(typeForm)}`;
  const handleCloseShow1 = async () => {
    console.log("typeForm", typeForm, akptId)


    try {
      const response = await axiosInstance.current.delete(
        `extapp/forms/delete_froms?ak_id=${akptId}&res_id=${delerteResourceId}`
      );
      setRefs(new Date().getMilliseconds());
      window.location.href = url
      // window.open(
      //   process.env.REACT_APP_FORM_URL +
      //   "patient-personal-data" +
      //   `/${window.btoa(akptId)}` +
      //   `/${window.btoa(typeForm)}`,
      //   "_blank"
      // );

      setModelShow1(false)
      setModalTrue(false)
    } catch (error) {
      console.log(error)
    }

  };

  const handleFirst = () => {
    setModelShow1(false)
    setModalTrue(false)
    console.log("typeOfFormmm", typeForm, delerteResourceId)
    if (typeForm) {
      if (typeForm === 5) {
        window.location.href =
          process.env.REACT_APP_FORM_URL +
          "adult-knee-pain" +
          `/${delerteResourceId}?is_doctor_form=null&edit=true`;
      }
      if (typeForm === 4) {
        window.location.href =
          process.env.REACT_APP_FORM_URL +
          "pain" +
          `/${delerteResourceId}?is_doctor_form=null&edit=true`;
      }
      if (typeForm === 20) {
        window.location.href =
          process.env.REACT_APP_FORM_URL +
          "pedia-knee-pain" +
          `/${delerteResourceId}?is_doctor_form=null&edit=true`;
      }
      if (typeForm === 6) {
        window.location.href =
          process.env.REACT_APP_FORM_URL +
          "hip-pain" +
          `/${delerteResourceId}?is_doctor_form=null&edit=true`;
      }
      if (typeForm === 21) {
        window.location.href =
          process.env.REACT_APP_FORM_URL +
          "foot-ankle-pain" +
          `/${delerteResourceId}?is_doctor_form=null&edit=true`;
      }
      if (typeForm === 22) {
        window.location.href =
          process.env.REACT_APP_FORM_URL +
          "elbow-pain" +
          `/${delerteResourceId}?is_doctor_form=null&edit=true`;
      }
      if (typeForm === 23) {
        window.location.href =
          process.env.REACT_APP_FORM_URL +
          "hand-and-wrist-pain" +
          `/${delerteResourceId}?is_doctor_form=null&edit=true`;
      }
      if (typeForm === 33) {
        window.location.href =
          process.env.REACT_APP_FORM_URL +
          "general-history" +
          `/${delerteResourceId}?is_doctor_form=null&edit=true`;
      }
    }

    // if (typeForm) {
    //   if (typeForm === 5) {
    //     window.open(
    //       process.env.REACT_APP_FORM_URL +
    //         "adult-knee-pain" +
    //         `/${delerteResourceId}?is_doctor_form=null&edit=true`,
    //       "_blank"
    //     );

    //   }
    //   if (typeForm === 4) {
    //     window.open(
    //       process.env.REACT_APP_FORM_URL +
    //         "pain" +
    //         `/${delerteResourceId}?is_doctor_form=null&edit=true`,
    //       "_blank"
    //     );
    //   }
    //   if (typeForm === 20) {
    //     window.open(
    //       process.env.REACT_APP_FORM_URL +
    //         "pedia-knee-pain" +
    //         `/${delerteResourceId}?is_doctor_form=null&edit=true`,
    //       "_blank"
    //     );
    //   }
    //   if (typeForm === 6) {
    //     window.open(
    //       process.env.REACT_APP_FORM_URL +
    //         "hip-pain" +
    //         `/${delerteResourceId}?is_doctor_form=null&edit=true`,
    //       "_blank"
    //     );
    //   }
    //   if (typeForm === 21) {
    //     window.open(
    //       process.env.REACT_APP_FORM_URL +
    //         "foot-ankle-pain" +
    //         `/${delerteResourceId}?is_doctor_form=null&edit=true`,
    //       "_blank"
    //     );
    //   }
    //   if (typeForm === 22) {
    //     window.open(
    //       process.env.REACT_APP_FORM_URL +
    //         "elbow-pain" +
    //         `/${delerteResourceId}?is_doctor_form=null&edit=true`,
    //       "_blank"
    //     );
    //   }
    //   if (typeForm === 23) {
    //     window.open(
    //       process.env.REACT_APP_FORM_URL +
    //         "hand-and-wrist-pain" +
    //         `/${delerteResourceId}?is_doctor_form=null&edit=true`,
    //       "_blank"
    //     );
    //   }
    //   if (typeForm === 33) {
    //     window.open(
    //       process.env.REACT_APP_FORM_URL +
    //         "general-history" +
    //         `/${delerteResourceId}?is_doctor_form=null&edit=true`,
    //       "_blank"
    //     );
    //   }
    // }


  }


  const handleCross = () => {
    setModelShow1(false)
    setModalTrue(false)
    setdelerteResourceId("")
    setType("")
  }





  return (
    <div>
      <Modal
        show={modelShow1}
        onHide={handleCross}
        dialogClassName="modal-md patient_notes_popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            <div className="col-12 text-center fw-bold" style={{ fontSize: "18px" }}>{translation.Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit}</div>
            {/* <div className="col-12 text-center fw-bold" style={{ fontSize: "18px" }}>
              {translation.Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit}
            </div> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="row">
            <div className="col-5">


              <p className="fw-bold">
                {"Specilaity"}
              </p>
              <p className="fw-bold mb-2">
                {"Date"}
              </p>{" "}
            </div>
            <div className="col-5">
              <p>
                {typeForm == 4
                  ? "Shoulder"
                  : typeForm == 6
                    ? "Hip "
                    : typeForm == 21
                      ? "Foot & Ankle "
                      : typeForm == 22
                        ? "Elbow "
                        : typeForm == 23
                          ? "Hand & Wrist "
                          : typeForm == 5
                            ? "Knee"
                            : "General"}
                {/* {formattedDate} */}
              </p>
              <p>{UserDateFormat(formattedDate, datefor)}</p>
            </div>
          </div>
          <div className="col-12 text-center fw-bold" style={{ fontSize: "16px" }}>
            {translation.Would_you_like_to_continue_this_form}
          </div>
        </Modal.Body>

        <Modal.Footer style={{ flexDirection: 'row', justifyContent: "space-between" }}>
          <div style={{ display: "inline-block", width: "38%", fontSize: 11 }}>

            <b>{translation.Note}</b>  {translation.If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted}

          </div>
          <div style={{ display: "inline-block", width: "50%" }} className='justify-content-between d-flex'>

            <Button
              // show={modelShow}
              onClick={handleCloseShow1}
              // disabled={button2Disabled}
              value={translation.No}
              buttonStyle="btn_fill"
            />
            <Button
              show={modelShow1}
              onClick={handleFirst}
              // disabled={button1Disabled}
              value={
                translation.yes // {translation.Continue}
              }
              buttonStyle="btn_success"
            />

          </div>


          {/* <p style={{ textAlign: "left", marginInlineEnd: "auto" }}>
    {"Note: if you select No a new patient PROMs will be created and the incomplete PROMs will be deleted."}
  </p> */}
        </Modal.Footer>
      </Modal>
    </div>
  )
}
